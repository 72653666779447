import {useEffect, useState} from "react";


const PanelTablePagination = ({items, setCurrentChunk, perPage}) => {
    // pagination for table in panel

    // create some states for pagination
    const [chunks, setChunks] = useState([]),
        [pagesCount, setPagesCount] = useState(1),
        [currentPage, setCurrentPage] = useState(1),
        [previous, setPrevious] = useState(null),
        [next, setNext] = useState(null);

    // change count of pages and set chunks in state
    useEffect(() => {
        const itemsCount = items.length;

        setPagesCount(() => {
            const quotient = Math.floor(itemsCount / perPage);
            const remainder = itemsCount % perPage;
            return remainder ? quotient + 1 : quotient;
        });
    }, [items]);

    // change chunks
    useEffect(() => {
        setChunks(() => {
            const newChunks = [];

            for (let i = 1; i <= pagesCount; i++) {
                const start = (i - 1) * perPage;
                const end = i * perPage;
                newChunks.push(items.slice(start, end));
            }
            return newChunks;
        })
        setCurrentPage(1)
    }, [pagesCount, items]);

    // set next and previous page
    useEffect(() => {
        setPrevious(() => currentPage > 1 ? currentPage - 1 : null);
        setNext(() => currentPage < pagesCount ? currentPage + 1 : null);
    }, [pagesCount, currentPage]);

    // set current items
    useEffect(() => {
        setCurrentChunk(() => {
            const item = chunks[currentPage - 1];
            return item ? item : [];
        });
    }, [chunks, currentPage]);

    const onPaginate = (num) => {
        setCurrentPage(num)
    };

    const createNums = () => {
        // generate paginating nums
        let nums = [];

        const def = "history-content__pagination-block history-content__pagination-block-number";
        const activeClass = "history-content__pagination-block-active"

        for (let i = 1; i <= pagesCount; i++) {

            const className = i === currentPage ? `${def} ${activeClass}` : def;

            nums.push(
                <div key={i}
                     className={className}
                     onClick={() => {
                         onPaginate(i)
                     }}>
                    <span>{i}</span>
                </div>
            )
        }

        return nums;
    }

    const renderPrevious = () => (
        // render previous btn
        <div
            className="history-content__pagination-block history-content__pagination-block-prev"
            onClick={() => onPaginate(previous)}>
            <svg width="8"
                 height="8"
                 viewBox="0 0 8 8"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_41_1023)">
                    <path
                        d="M1.93075 4.21953L5.63099 7.90953C5.75222 8.03036 5.9485 8.03016 6.06954 7.90891C6.19047 7.78767 6.19016 7.59128 6.06891 7.47036L2.58892 3.99999L6.06904 0.529626C6.19027 0.408689 6.19058 0.212424 6.06966 0.0911741C6.00899 0.0303931 5.92951 2.36402e-06 5.85002 2.35707e-06C5.77074 2.35014e-06 5.69157 0.03019 5.63101 0.0905489L1.93076 3.78046C1.87236 3.83855 1.8396 3.91761 1.8396 3.99999C1.8396 4.08236 1.87246 4.16133 1.93075 4.21953Z"
                        fill="#5D5E75"/>
                </g>
                <defs>
                    <clipPath
                        id="clip0_41_1023">
                        <rect
                            width="8"
                            height="8"
                            fill="white"
                            transform="translate(8 8) rotate(-180)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    )

    const renderNext = () => (
        // render next btn
        <div
            className="history-content__pagination-block history-content__pagination-block-next"
            onClick={() => onPaginate(next)}>
            <svg width="8"
                 height="8"
                 viewBox="0 0 8 8"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_41_1023)">
                    <path
                        d="M1.93075 4.21953L5.63099 7.90953C5.75222 8.03036 5.9485 8.03016 6.06954 7.90891C6.19047 7.78767 6.19016 7.59128 6.06891 7.47036L2.58892 3.99999L6.06904 0.529626C6.19027 0.408689 6.19058 0.212424 6.06966 0.0911741C6.00899 0.0303931 5.92951 2.36402e-06 5.85002 2.35707e-06C5.77074 2.35014e-06 5.69157 0.03019 5.63101 0.0905489L1.93076 3.78046C1.87236 3.83855 1.8396 3.91761 1.8396 3.99999C1.8396 4.08236 1.87246 4.16133 1.93075 4.21953Z"
                        fill="#5D5E75"/>
                </g>
                <defs>
                    <clipPath
                        id="clip0_41_1023">
                        <rect
                            width="8"
                            height="8"
                            fill="white"
                            transform="translate(8 8) rotate(-180)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    )

    const renderNums = () => {
        // get array of all number links
        let nums = createNums();

        // check if currentPage page is 1
        if (currentPage === 1) {
            return nums.map((item, index) => {
                // if currentPage is 1 or link is 2 or link is last
                if (index + 1 === currentPage || index + 1 === 2 || index + 1 === pagesCount) {
                    // return link
                    return item;
                }
            });
        }
        // check if currentPage page is last
        else if (currentPage === pagesCount) {
            // check if number of page greater than 3
            if (pagesCount > 3) {
                return nums.map((item, index) => {
                    // if currentPage is last or item is 1 or item is pre last
                    if (index + 1 === 1 || index + 1 === pagesCount - 1 || index + 1 === pagesCount) {
                        // return link
                        return item;
                    }
                });
            }
            // return all links
            else {
                return nums;
            }
        }
        // if not first and last page
        else {
            return nums.map((item, index) => {
                // if currentPage or currentPage-1 or currentPage+1
                if (index + 1 === currentPage || index + 1 === currentPage - 1 || index + 1 === currentPage + 1) {
                    // return link
                    return item;
                }
            });
        }
    }

    return pagesCount > 1 ? (
        <div className="history-content__pagination">
            <div className="history-content__pagination-wrapper">
                <div className="history-content__pagination-info">
                </div>
                <div className="history-content__pagination-blocks">
                    <div className="history-content__pagination-blocks-wrap">
                        {previous && renderPrevious()}
                        {renderNums()}
                        {next && renderNext()}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}

export default PanelTablePagination;