import Breadcrumbs from "../../../parts/breadcrumbs/Breadcrumbs";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import useBlogServices from "../../../../services/BlogServices";
import parse from "html-react-parser";
import Preloader from "../../../parts/preloader/Preloader";

const PostDetailPage = () => {

    const {slug} = useParams();
    const navigate = useNavigate();

    const [post, setPost] = useState(null),
        [loading, setLoading] = useState(true),
        [error, setError] = useState(false);

    const {getPost} = useBlogServices();

    useEffect(() => {
        getPost(slug)
            .then(response => onLoaded(response.data))
            .catch(error => onError(error))
    }, []);

    const onLoaded = (data) => {
        setLoading(false);
        setPost(data);
    }

    const onError = (error) => {
        setError(true);
        navigate("/not-found/")
    }


    const renderContent = () => {

        const paths = [
            {
                name: "Home",
                path: "/"
            },
            {
                name: "Blog",
                path: "/blog/"
            },
            {
                name: `${post.title}`,
                path: `/blog/${slug}/`
            }
        ];

        return (
            <div className="main-bg">
                <div className="wrapper-content">
                    <Breadcrumbs paths={paths}/>
                    <section className="blog-detail-header">
                        <h2 className="smm-title blog-header__title">{post.title}</h2>
                    </section>
                    <section className="blog-detail-content">
                        <div className="blog-detail-content__img-wrap">
                            <img src={post.image} alt="title"/>
                        </div>
                        <div className="blog-detail-content__wrapper">
                            {parse(post.content)}
                        </div>
                    </section>
                </div>
            </div>
        )
    }

    return loading || error ? <Preloader/> : renderContent();
}

export default PostDetailPage;
