import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import useOrdersServices from "../../../services/OrdersServices";

const initialState = {
    orders: [],
    ordersLoading: "idle",
    searchFilter: "",
    statusFilter: "All",
    startDateFilter: "",
    endDateFilter: ""
}

export const getOrders = createAsyncThunk(
    "history/getOrders", async (data, {rejectWithValue}) => {
        const {getOrders} = useOrdersServices();
        try {
            return await getOrders();
        } catch (e) {
            return rejectWithValue(e.response);
        }
    });


export const historySlice = createSlice({
    name: "history",
    initialState,
    reducers: {
        filterBySearch: (state, action) => {
            state.searchFilter = action.payload;
        },
        filterByStatus: (state, action) => {
            state.statusFilter = action.payload;
        },
        filterByDate: (state, action) => {
            const [start, end] = action.payload;
            state.startDateFilter = start;
            state.endDateFilter = end;
        }
    },
    extraReducers: builder => {
        builder.addCase(getOrders.pending, state => {
            state.ordersLoading = "loading";
        })
        builder.addCase(getOrders.fulfilled, (state, action) => {
            const {orders} = action.payload.data;
            state.ordersLoading = "idle";
            state.orders = orders;
            state.ordersForFilter = orders;
        })
        builder.addCase(getOrders.rejected, (state, action) => {
            state.ordersLoading = "error";
        })
    }
})

const {reducer, actions} = historySlice;

export const ordersSelect = createSelector([
    state => state.history.orders,
    state => state.history.searchFilter,
    state => state.history.statusFilter,
    state => state.history.startDateFilter,
    state => state.history.endDateFilter,
], (orders, searchFilter, statusFilter, startDateFilter, endDateFilter) => {

    let filteredOrders = [...orders];

    if (!searchFilter && statusFilter === "All" && !startDateFilter && !endDateFilter){
        return filteredOrders;
    }

    if (searchFilter){
        filteredOrders = filteredOrders.filter(item => item.service.name.toLowerCase().includes(searchFilter.toLowerCase()));
    }

    if (statusFilter !== "All"){
        filteredOrders = filteredOrders.filter(item => item.status === statusFilter);
    }

    if (startDateFilter && endDateFilter){
        const start = new Date(startDateFilter);
        const end = new Date(endDateFilter);

        filteredOrders = filteredOrders.filter(item => {
            const createdAt = new Date(item.created_at);
            return createdAt >= start && createdAt <= end;
        })
    } else if (startDateFilter){
        const start = new Date(startDateFilter);

        filteredOrders = filteredOrders.filter(item => {
            const createdAt = new Date(item.created_at);
            return createdAt >= start;
        })
    } else if (endDateFilter){
        const end = new Date(endDateFilter);
        filteredOrders = filteredOrders.filter(item => {
            const createdAt = new Date(item.created_at);
            return createdAt <= end;
        })
    }

    return filteredOrders;
})

export const {
    filterBySearch,
    filterByStatus,
    filterByDate
} = actions;

export default reducer;


