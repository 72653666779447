import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import request from "../../../services";
import Cookie from "../../../utils/cookie";

const cookie = new Cookie();

// get access token from Cookie or refresh it
const refreshToken = cookie.getCookie("refresh");

// create initial Auth State
const initialState = {
    requestLoginLoading: false,
    isAuthenticated: !!refreshToken, // if token in Cookie = true else false
}


// Request with form data to api login
export const requestLogin = createAsyncThunk(
    "auth/login",
    async (data, {rejectWithValue}) => {
        try {
            let url = 'auth/login/';
            return await request({url, data, method: "post"});
        }
        catch(error){
            return rejectWithValue(error.response);
   }
    });

export const socialLogin = createAsyncThunk(
    "auth/socialLogin",
    async (data, {rejectWithValue}) => {
        try {
            let url = 'auth/social/convert-token/';
            return await request({url, data, method: "post"})
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
)


const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            state.isAuthenticated = false;
            cookie.removeCookie("access");
            cookie.removeCookie("refresh");
            cookie.removeCookie("social");
        }
    },
    extraReducers: builder => {
        // Login by credentials
        builder.addCase(requestLogin.pending, state => {
            state.requestLoginLoading = true;
        })
        builder.addCase(requestLogin.fulfilled, (state, action) => {
            // get access and refresh tokens
            const refresh = action.payload.data.auth.refresh;
            const access = action.payload.data.auth.access;
            // change state to login
            state.isAuthenticated = true;
            state.requestLoginLoading = false;
            // set new cookies of access and refresh tokens
            cookie.setCookie("refresh", refresh.token, refresh.exp, true);
            cookie.setCookie("access", access.token, access.exp, true);
        });
        builder.addCase(requestLogin.rejected, (state, action) => {
            state.requestLoginLoading = false;
        })
        // Login by Social
        builder.addCase(socialLogin.pending, state => {
            state.requestLoginLoading = true;
        })
        builder.addCase(socialLogin.fulfilled, (state, action) => {
            // get access token and one's expiration date and refresh token
            const refresh = action.payload.data.refresh_token;
            const access = action.payload.data.access_token;
            const expiresIn = action.payload.data.expires_in;
            // change state to login
            state.isAuthenticated = true;
            state.requestLoginLoading = false;
            // set new cookies of access and refresh tokens
            cookie.setCookie("social", 1, 86400, true);
            cookie.setCookie("refresh", refresh, 86400, true);
            cookie.setCookie("access", access, expiresIn, true);
        });
        builder.addCase(socialLogin.rejected, (state, action) => {
            state.requestLoginLoading = false;
        })
    }
});

const {actions, reducer} = authSlice; // get actions and reducer from authSlice

export default reducer;

export const authSelector = state => state.auth;

export const {
    logout
} = actions;