const NotFoundPage = () => {

    return (
        <div className={"panel-wrapper"}>
            <div className={"page-not-found"}>
                <p className={"page-not-found__code"}>404</p>
                <p className={"page-not-found__text"}>Not found</p>
            </div>
        </div>
    )
}

export default NotFoundPage;