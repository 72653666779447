import {configureStore} from "@reduxjs/toolkit";
import authReducer from "../components/pages/authPages/AuthSlice";
import notificationReducer
    from "../components/parts/notification/NotificationSlice";
import userReducer from "../components/pages/settingsPage/UserSlice";
import paymentsReducer from "../components/pages/paymentsPage/PaymentsSlice";
import historyReducer from "../components/pages/historyPage/HistorySlice";
import ticketsReducer from "../components/pages/ticketsPage/TicketsSlice";

/*
 * The `store` variable stores the Redux store that is created by invoking the `configureStore` function.
 * The Redux store is a central container that holds the complete state tree of your application.
 * It allows you to dispatch actions, which in turn trigger updates to the state and trigger re-rendering of your application.
 */
const store = configureStore({
    reducer: {
        auth: authReducer,
        notification: notificationReducer,
        user: userReducer,
        payments: paymentsReducer,
        history: historyReducer,
        tickets: ticketsReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
    // only for debug
    devTools: process.env.REACT_APP_IS_DEBUG === "true",
})


export default store;
