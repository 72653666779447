/**
 * Toggles the visibility of a password input field.
 * @param {object} ref - The reference to the password input field.
 * @return {void}
 */
export default function setVisiblePassword(ref){
    ref.current.classList.toggle("auth-form__input-view-active");
     if (ref.current.classList.contains('auth-form__input-view-active')) {
         ref.current.previousElementSibling.type = "text";
     } else {
         ref.current.previousElementSibling.type = "password";
     }
}