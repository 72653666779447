

const PanelFooter = () => {

    return (
        <div className="copyright">
            © EpromoSMM All rights reserved 2023
        </div>
    )
}

export default PanelFooter;