import {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {removeNotification} from "../NotificationSlice";


const NotificationItem = ({id, text, type}) => {

    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(true);
    const itemRef = useRef(null);

    useEffect(() => {
        // set timer on 5 seconds to hide notification
        setTimeout(hide, 5000);
    }, []);

    function hide() {
        // Hides the item by setting it as inactive and adding a CSS class to make it visually hidden.
        setIsActive(false);
        // setTimeout(() => itemRef.current.classList.add('hidden'), 500);
        setTimeout(() => dispatch(removeNotification(id)), 300);
    }

    const getClassNameByType = () => {
        // Returns the CSS class name based on the given type.
        switch (type) {
            case 'success':
                return 'success-notification';
            case 'error':
                return 'error-notification';
            default:
                return 'info-notification';
        }
    }

    const getItemClassName = () => {
        // Retrieves the class name for a notification item.
        return `notification-item ${getClassNameByType()} ${isActive ? 'show' : ''}`;
    }

    return (
        <div className={`${getItemClassName()}`} ref={itemRef}>
            <p className="notification-text">{text}</p>
            <svg className="notification-close" width="8" height="8"
                 viewBox="0 0 8 8"
                 onClick={hide}
                 fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_749_2198)">
                    <path
                        d="M0.39021 8.00005C0.312941 8.00007 0.237403 7.97716 0.173152 7.93424C0.108901 7.89132 0.058822 7.83031 0.0292505 7.75892C-0.000320951 7.68754 -0.00805675 7.60898 0.00702163 7.5332C0.0221 7.45742 0.0593152 7.38781 0.11396 7.33318L7.33271 0.114427C7.40598 0.0411604 7.50535 0 7.60896 0C7.71257 0 7.81194 0.0411604 7.88521 0.114427C7.95848 0.187693 7.99964 0.287063 7.99964 0.390676C7.99964 0.49429 7.95848 0.59366 7.88521 0.666927L0.66646 7.88568C0.630215 7.922 0.587153 7.9508 0.539745 7.97042C0.492338 7.99005 0.44152 8.00012 0.39021 8.00005Z"
                        fill="#789384"/>
                    <path
                        d="M7.60894 8.00005C7.55763 8.00012 7.50681 7.99005 7.4594 7.97042C7.41199 7.9508 7.36893 7.922 7.33269 7.88568L0.113938 0.666927C0.0406722 0.59366 -0.000488281 0.49429 -0.000488281 0.390676C-0.000488281 0.287063 0.0406722 0.187693 0.113938 0.114427C0.187204 0.0411604 0.286574 0 0.390188 0C0.493802 0 0.593172 0.0411604 0.666438 0.114427L7.88519 7.33318C7.93983 7.38781 7.97705 7.45742 7.99213 7.5332C8.00721 7.60898 7.99947 7.68754 7.9699 7.75892C7.94033 7.83031 7.89025 7.89132 7.826 7.93424C7.76174 7.97716 7.68621 8.00007 7.60894 8.00005Z"
                        fill="#789384"/>
                </g>
                <defs>
                    <clipPath id="clip0_749_2198">
                        <rect width="8" height="8" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}

export default NotificationItem;