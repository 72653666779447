import PanelWrapper from "../../../parts/panelWrapper/PanelWrapper";
import {Navigate, Route, Routes} from "react-router-dom";
import DashboardPage from "../../dashboardPage/DashboardPage";
import HistoryPage from "../../historyPage/HistoryPage";
import PaymentsPage from "../../paymentsPage/PaymentsPage";
import TicketsPage from "../../ticketsPage/TicketsPage";
import SettingsPage from "../../settingsPage/SettingsPage";
import AuthFaq from "../../faqPage/authFaq/AuthFaq";


const AuthPages = () => (
    <PanelWrapper>
        <Routes>
            <Route path={""} element={<DashboardPage/>}/>
            <Route path={"history/"}
                   element={<HistoryPage/>}/>
            <Route path={"payments/"}
                   element={<PaymentsPage/>}/>
            <Route path={"faq/"} element={<AuthFaq/>}/>
            <Route path={"tickets/"}
                   element={<TicketsPage/>}/>
            <Route path={"settings/"}
                   element={<SettingsPage/>}/>
            <Route path={"*"} element={<Navigate to={"not-found/"}/>} />
        </Routes>
    </PanelWrapper>
)

export default AuthPages;