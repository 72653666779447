import {useState} from "react";
import AccountStats from "./accountStats/AccountStats";
import OrderTypes from "./orderTypes/OrderTypes";
import OrderCreate from "./orderCreate/OrderCreate";


const DashboardPage = () => {

    const [filterBy, setFilterBy] = useState("Everything");

    return (
        <>
            <AccountStats/>
            <OrderTypes filterBy={filterBy}
                        filter={(filterBy) => setFilterBy(filterBy)}/>
            <div className="new-order">
                <div className="smm-panel-content__wrapper">
                    <div
                        className="smm-panel-content-block__wrapper order__wrapper">
                        <OrderCreate filterBy={filterBy}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardPage;