import cheapest from "../../../../assets/media/cheap_benefits/cheapest_smm_benefit.svg";
import fatest from "../../../../assets/media/cheap_benefits/fatest_smm_benefit.svg";
import easy from "../../../../assets/media/cheap_benefits/easy_smm_benefit.svg";
import data from "../../../../assets/media/cheap_benefits/data_smm_benefit.svg";
import reseller from "../../../../assets/media/cheap_benefits/reseller_smm_benefit.svg";
import quality from "../../../../assets/media/cheap_benefits/quality_smm_benefit.svg";
import security from "../../../../assets/media/cheap_benefits/security_smm_benefit.svg";
import services from "../../../../assets/media/cheap_benefits/services_smm_benefit.svg";
import support from "../../../../assets/media/cheap_benefits/support_smm_benefit.svg";


const BenefitCheapServices = () => {

    return (
        <section className="benefit-cheap-services">
            <div className="wrapper-content">
                <div className="benefit-cheap-services__header">
                    <h2 className="benefit-cheap-services__header-title">Benefit
                        of
                        using our Cheap SMM Services</h2>
                    <p className="benefit-cheap-services__header-text">We offer
                        several advantages that make us the best SMM panel. Our
                        SMM services are diversified which makes us the
                        cheapest panel on the market. Many agencies and
                        freelancers around the world trust us. We are also
                        available to answer all your questions 24/7.</p>
                </div>
                <div className="benefit-cheap-services__inner">
                    <div className="benefit-cheap-services__block">
                        <div className="benefit-cheap-services__block-wrap">
                            <div
                                className="benefit-cheap-services__block-img-wrap">
                                <img
                                    className="benefit-cheap-services__block-img"
                                    src={cheapest}
                                    alt="Cheapest Smm Panel" />
                            </div>
                            <h6 className="benefit-cheap-services__block-title">
                                Cheapest Smm Panel</h6>
                            <p className="benefit-cheap-services__block-text">Our
                                prices are the cheapest in the market, starting
                                at 0.01$.</p>
                        </div>
                    </div>
                    <div className="benefit-cheap-services__block">
                        <div className="benefit-cheap-services__block-wrap">
                            <div
                                className="benefit-cheap-services__block-img-wrap">
                                <img className="benefit-cheap-services__block-img"
                                     src={fatest}
                                     alt="Fastest Smm Panel"/>
                            </div>
                            <h6 className="benefit-cheap-services__block-title">
                                Fastest Smm Panel</h6>
                            <p className="benefit-cheap-services__block-text">We
                                guarantee a fast delivery of your order
                                24/7.</p>
                        </div>
                    </div>
                    <div className="benefit-cheap-services__block">
                        <div className="benefit-cheap-services__block-wrap">
                            <div
                                className="benefit-cheap-services__block-img-wrap">
                                <img
                                    className="benefit-cheap-services__block-img"
                                    src={easy}
                                    alt="Easy Smm Panel" />
                            </div>
                            <h6 className="benefit-cheap-services__block-title">
                                Easy Smm Panel</h6>
                            <p className="benefit-cheap-services__block-text">We
                                offer a user-friendly and regularly updated SMM
                                panel dashboard.</p>
                        </div>
                    </div>
                    <div className="benefit-cheap-services__block">
                        <div className="benefit-cheap-services__block-wrap">
                            <div
                                className="benefit-cheap-services__block-img-wrap">
                                <img
                                    className="benefit-cheap-services__block-img"
                                    src={data}
                                    alt="SMM Panel with real time and data"/>
                            </div>
                            <h6 className="benefit-cheap-services__block-title">SMM
                                Panel with real time and data</h6>
                            <p className="benefit-cheap-services__block-text">Our
                                data is updated instantly, so you'll always
                                find the best deals on SmmFollows.</p>
                        </div>
                    </div>
                    <div className="benefit-cheap-services__block">
                        <div className="benefit-cheap-services__block-wrap">
                            <div
                                className="benefit-cheap-services__block-img-wrap">
                                <img
                                    className="benefit-cheap-services__block-img"
                                    src={reseller}
                                    alt="SMM Reseller Panel"/>
                            </div>
                            <h6 className="benefit-cheap-services__block-title">SMM
                                Reseller Panel</h6>
                            <p className="benefit-cheap-services__block-text">We
                                are also a provider. Our goal is to enable you
                                to resell our SMM services worldwide at
                                affordable prices.</p>
                        </div>
                    </div>
                    <div className="benefit-cheap-services__block">
                        <div className="benefit-cheap-services__block-wrap">
                            <div
                                className="benefit-cheap-services__block-img-wrap">
                                <img
                                    className="benefit-cheap-services__block-img"
                                    src={quality}
                                    alt="Highest Quality SmmPanel In The Market" />
                            </div>
                            <h6 className="benefit-cheap-services__block-title">
                                Highest Quality SmmPanel In The Market!</h6>
                            <p className="benefit-cheap-services__block-text">Our
                                main goal is to provide quality SMM Reseller
                                Panel all over the world at affordable
                                prices.</p>
                        </div>
                    </div>
                    <div className="benefit-cheap-services__block">
                        <div className="benefit-cheap-services__block-wrap">
                            <div
                                className="benefit-cheap-services__block-img-wrap">
                                <img className="benefit-cheap-services__block-img"
                                     src={security}
                                     alt="SMM Panel with Secure Payment"/>
                            </div>
                            <h6 className="benefit-cheap-services__block-title">SMM
                                Panel with Secure Payment</h6>
                            <p className="benefit-cheap-services__block-text">
                                Credit card / Debit card, PayOp, Advcash,
                                Gcash, Perfect Money, Payeer, WebMoney,
                                EasyPaisa, Paypal SMM Panel, Enot</p>
                        </div>
                    </div>
                    <div className="benefit-cheap-services__block">
                        <div className="benefit-cheap-services__block-wrap">
                            <div className="benefit-cheap-services__block-img-wrap">
                                <img className="benefit-cheap-services__block-img"
                                     src={services}
                                     alt="SMM Panel Services"/>
                            </div>
                            <h6 className="benefit-cheap-services__block-title">SMM
                                Panel Services</h6>
                            <p className="benefit-cheap-services__block-text">We
                                offer a multitude of services for social
                                networks and SEO. You will find an SMM panel
                                for each social network namely: Instagram,
                                TikTok, YouTube, Snapchat, Facebook as well as
                                other sites.</p>
                        </div>
                    </div>
                    <div className="benefit-cheap-services__block">
                        <div className="benefit-cheap-services__block-wrap">
                            <div className="benefit-cheap-services__block-img-wrap">
                                <img className="benefit-cheap-services__block-img"
                                     src={support}
                                     alt="Support 24/7" />
                            </div>
                            <h6 className="benefit-cheap-services__block-title">
                                Support 24/7</h6>
                            <p className="benefit-cheap-services__block-text">We
                                are available 24/7 to answer all your questions
                                about our SMM panel services.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BenefitCheapServices;