import request from "./index";

/*
 * A set of authentication services for handling login, registration, password reset, etc.
 */
const useAuthServices = () => {

    const _baseUrl = "auth";

    const login = async (data) => {
        let url = `${_baseUrl}/login/`;
        return await request({url, data, method: "post"});
    }

    const register = async (data) => {
        let url = `${_baseUrl}/register/`;
        return await request({url, data, method: "post"})
    }

    const registerConfirm = async (uidb64, token) => {
        let url = `${_baseUrl}/register/confirm/${uidb64}/${token}/`;
        return await request({url});
    }

    const passwordResetSendMail = async (data) => {
        let url = `${_baseUrl}/password-reset/send-email/`;
        return await request({url, data, method: "post"});
    }

    const passwordResetCheckToken = async (uidb64, token) => {
        let url = `${_baseUrl}/register/confirm/${uidb64}/${token}/`;
        return await request({url});
    }

    const passwordResetChange = async (uidb64, token, data) => {
        let url = `${_baseUrl}/password-reset/${uidb64}/${token}/`;
        return await request({url, data, method: "post"})
    }

    return {
        login,
        register,
        registerConfirm,
        passwordResetSendMail,
        passwordResetCheckToken,
        passwordResetChange
    };
}

export default useAuthServices;