const AuthWrapper = (props) => {

    return (
        <div className="auth-wrapper">
            <div className="auth-bg">
                <div className="auth-form__wrapper">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default AuthWrapper;
