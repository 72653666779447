import HistoryFilters from "./historyFilters/HistoryFilters";
import HistoryTable from "./historyTable/HistoryTable";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {getOrders} from "./HistorySlice";
import {setNotification} from "../../parts/notification/NotificationSlice";


const HistoryPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOrders())
            .then(error => {
                const status = error?.payload?.status;
                if (!status || status >= 400) {
                    dispatch(setNotification({
                        'type': 'error',
                        'text': 'Something went wrong'
                    }))
                }
            });
    }, []);

    return (
        <section className="history">
            <div className="smm-panel-content__wrapper">
                <div className="smm-panel-content-block__wrapper">
                    <h4 className="smm-panel-content-block__title">History</h4>
                    <HistoryFilters/>
                    <HistoryTable/>
                </div>
            </div>
        </section>
    )
}

export default HistoryPage;