import AuthWrapper from "../../../parts/authWrapper/AuthWrapper";
import SignUpForm from "./signUpForm/SignUpForm";
import AuthSocialNetworks
    from "../../../parts/authSocialNetworks/AuthSocialNetworks";

const SignUpPage = () => {

    return (
        <AuthWrapper>
            <h2 className="auth-form__title">Sign up</h2>
            <SignUpForm/>
            <AuthSocialNetworks/>
        </AuthWrapper>
    )
}

export default SignUpPage;