import {
    Navigate,
    Route,
    Routes
} from "react-router-dom";
import ErrorBoundary from "../parts/errorBoundary/ErrorBoundary";
import NotFoundPage from "./notFoundPage/404Page";
import {useSelector} from "react-redux";
import SignInPage from "./authPages/signInPage/SignInPage";
import SignUpPage from "./authPages/signUpPage/SignUpPage";
import SignUpConfirmPage
    from "./authPages/signUpConfirmPage/SignUpConfirmPage";
import ForgotPasswordPage
    from "./authPages/forgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage
    from "./authPages/ResetPasswordPage/ResetPasswordPage";
import SettingsChangeEmailPage
    from "./settingsPage/SettingsChangeEmailPage/SettingsChangeEmailPage";
import MainPages from "./mainPages/MainPages";


// const HomePage = lazy(() => import("./homePage/HomePage"));

const Pages = () => {

    const {isAuthenticated} = useSelector(state => state.auth);

    const authRedirect = (element) => isAuthenticated ?
        <Navigate to="/"/> : element;
    const noAuthRedirect = (element) => isAuthenticated ? element :
        <Navigate to={"/"}/>;

    return (
        <ErrorBoundary>
            <Routes>
                <Route path="/*" element={<MainPages/>}/>
                <Route path="/sign-in/" element={authRedirect(<SignInPage/>)}/>
                <Route path="/sign-up/" element={authRedirect(<SignUpPage/>)}/>
                <Route path="/sign-up-confirm/:uidb64/:token/"
                       element={authRedirect(<SignUpConfirmPage/>)}/>
                <Route path="/forgot-password/"
                       element={authRedirect(<ForgotPasswordPage/>)}/>
                <Route path="/forgot-password/:uidb64/:token/"
                       element={authRedirect(<ResetPasswordPage/>)}/>
                <Route path={"/settings/change-email/:uidb64/:token/:email/"}
                       element={noAuthRedirect(<SettingsChangeEmailPage/>)}/>
                <Route path="/not-found/" element={<NotFoundPage/>}/>
            </Routes>
        </ErrorBoundary>
    )
}

{/*<Route path={":lang?/"}>*/}
{/*</Route>*/}

export default Pages;