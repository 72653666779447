import AuthPages from "./authPages/AuthPages";
import UnAuthPages from "./unAuthPages/unAuthPages";
import {useSelector} from "react-redux";


const MainPages = () => {

    const {isAuthenticated} = useSelector(state => state.auth);

    return isAuthenticated ? <AuthPages/> : <UnAuthPages/>;
}

export default MainPages;