import {useEffect, useMemo, useState} from "react";
import useOrdersServices from "../../../../services/OrdersServices";
import Spinner from "../../../parts/spinner/Spinner";
import Select from "../../../parts/select/Select";
import InputError from "../../../parts/inputError/InputError";
import {useNavigate} from "react-router-dom";

import {useDispatch} from "react-redux";
import {
    setNotification
} from "../../../parts/notification/NotificationSlice";
import PanelInput from "../../../parts/panelInput/PanelInput";
import getButtonSubmitClass from "../../../../utils/getButtonSubmitClass";
import isObjectEmpty from "../../../../utils/checkObjectEmpty";
import {isNaN} from "formik";
import validateDate from "../../../../utils/validateDate";
import {updateBalance} from "../../settingsPage/UserSlice";


const OrderCreate = ({filterBy}) => {

    // fetching to smm follows
    const dispatch = useDispatch();
    const {getServices, createOrder} = useOrdersServices();
    const navigate = useNavigate()
    const [serverData, setServerData] = useState({});
    const [fetchingServices, setFetchingServices] = useState(true);

    const delayValues = [
        0,
        5, 10,
        15, 30, 60,
        90, 120, 150, 180,
        210, 240, 270, 300,
        360, 420, 480, 540, 600
    ]

    const delayOptions = useMemo(() => {
        return delayValues.map((item,id) => {
           return id !== 0 ? {value: item, label: `${item} minutes`} : {value: item, label: "No delay"};
        });
    },[])

    // form state
    const [values, setValues] = useState({
        link: "",
        quantity: 0,
        charge: 0,
        default: {
            runs: 1,
            interval: 0
        },
        seo: {
            items: ""
        },
        mentions: {
            username: ""
        },
        commentLikes: {
            username: ""
        },
        poll: {
            answer_number: 1
        },
        commentReplies: {
            username: ""
        },
        subscriptions: {
            username: "",
            posts: 0,
            old_posts: 0,
            min: 0,
            max: 1,
            delay: delayOptions[0],
            expired_date: null
        },
        items: ""
    });

    // category select state
    const [categoryValue, setCategoryValue] = useState({});
    const [categories, setCategories] = useState([]);

    // service select state
    const [serviceValue, setServiceValue] = useState({});
    const [services, setServices] = useState([]);

    // errors state
    const [errors, setErrors] = useState({});
    const [isError, setIsError] = useState();

    // assistive variables
    const typesWithoutQuantity = ["Package", "Custom Comments Package"];
    const typeOfCustomList = ["Custom Comments", "Mentions Custom List", "Comment Replies"]

    useEffect(() => {
        getServices()
            .then(response => {

                const {data} = response;

                setFetchingServices(false);
                setServerData(data);

                // set category state
                const allCategories = Object.keys(data).map((item) => (
                    {value: item, label: item}
                ));
                const firstCategory = allCategories[0];

                setCategoryValue(firstCategory);
                setCategories(allCategories)

                // set service state

                const allServices = getFormattingServices(
                    data[firstCategory.value]
                );

                const firstService = allServices[0];

                setServiceValue(firstService);
                setServices(allServices);

                changeQuantityAndCharge(firstService);
            })
            .catch(error => {
                dispatch((setNotification({
                    "type": "error",
                    "text": "Server error"
                })))
            })
    }, [])

    useEffect(() => {

        // get all categories
        let categories = Object.keys(serverData).map((item) => (
            {value: item, label: item}
        ));

        // filter categories
        if (filterBy !== "Everything") {
            categories = categories.filter(item => item.value.includes(filterBy));
        }

        // change state by types of social networks
        try {
            // get array of services and category value
            const firstCategory = categories[0];
            const serverServices = serverData[firstCategory.value];
            const services = getFormattingServices(serverServices)

            // set categories state
            setCategories(categories);
            setCategoryValue(firstCategory);

            // get service value and set services state
            const firstService = services[0];
            setServices(services);
            setServiceValue(firstService);

            changeQuantityAndCharge(firstService);

        } catch (e) {
        }

    }, [filterBy]);

    useEffect(() => {
        setIsError(!isObjectEmpty(errors));
    }, [errors])

    // change quantity and charge after changing service or category
    const changeQuantityAndCharge = (firstService) => {
        const {type, rate, min, max} = firstService;

        setValues(state => {
            const newState = {...state};

            let quantity = min;

            if (typeOfCustomList.includes(type)) {
                quantity = 0;
                setIsError(true);
            }

            if (type !== "Subscriptions") {
                newState.quantity = parseInt(quantity);
            }else{
                newState.subscriptions.min = min;
                newState.subscriptions.max = max;
            }

            newState.charge = countCharge(type, rate, quantity, min, max);

            return newState;
        });
    }

    // Transforms an array of objects representing formatting services into a new array with a specific format.
    const getFormattingServices = (arr) => arr.map(item => (
        {
            type: item.type,
            min: parseInt(item.min),
            max: parseInt(item.max),
            rate: Number(item.rate),
            value: item.service_id,
            label: item.name,
        }
    ));

    // Sets the readonly quantity of a given type in a custom list.
    const setReadonlyQuantity = (type) => typeOfCustomList.includes(type);

    // Validates a username value.
    const validateUsername = (value) => {

        setErrors(state => {
            const newErrors = {...state};

            if (!value.length) {
                newErrors.username = "Username is required";
            } else if (value.length > 100) {
                newErrors.username = "Username must be less than 100 symbols";
            } else {
                delete newErrors.username;
            }

            return newErrors
        });
    }

    // Validates the items array which separated by \n
    const validateItems = (items) => {
        setErrors(state => {
            const newError = {...state};

            if (items.length <= 1) {
                newError.items = "Items must be greater than 1";
            } else {
                delete newError.items;
            }

            return newError
        })
    }

    // Validates if a given value is a positive number and updates the error state accordingly.
    const validatePositiveNum = (value, errorKey) => {
        setErrors(state => {
            const newErrors = {...state}

            const valueNumber = Number(value);

            if (value !== "" && valueNumber < 0 || isNaN(valueNumber)) {
                newErrors[errorKey] = "This field shouldn't be less than 0"
            } else {
                delete newErrors[errorKey];
            }

            return newErrors
        })
    }

    // Validates the given value based on the minimum and maximum values.
    const validateMinMaxValue = (value, errorKey) => {

        const {min, max} = serviceValue;

        setErrors(state => {
           const newErrors = {...state};

           const valueNumber = Number(value);

           if (value === "" || valueNumber < min || valueNumber > max){
               newErrors[errorKey] = `Value must be greater than ${min} and less than ${max}`;
           } else {
               delete newErrors[errorKey];
           }

           return newErrors
        });
    }

    const validateDelay = (value, errorKey) => {
        setErrors(state => {
            const newErrors = {...state};

            if (!delayValues.includes(value["value"])) {
                newErrors[errorKey] = "Invalid value";
            } else {
                delete newErrors[errorKey];
            }

            return newErrors;
        })
    }

    // Validates the expiration date and updates the errors state accordingly.
    const validateExpiredDate = (value, errorKey) => {

        setErrors(state => {
            const newErrors = {...state}

            if (!validateDate(new Date(value))){
                newErrors[errorKey] = "Expired date is not valid";
            } else{
                delete newErrors[errorKey];
            }

            return newErrors;
        });
    }

    // work with category select
    const onChangeCategory = (value) => {

        setCategoryValue(value);

        const serverServices = serverData[value.value];

        const services = getFormattingServices(serverServices);

        const firstService = services[0];

        setServices(services);
        setServiceValue(firstService);

        changeQuantityAndCharge(firstService);
    }

    // work with service select
    const onChangeService = (value) => {

        setServiceValue(value);

        changeQuantityAndCharge(value);
    }

    // work with link input
    const onChangeLink = (e) => {

        const linkValue = e.target.value;

        const urlPattern = /^(http[s]?:\/\/)(www\.)?[^\s$.?#].[^\s]*$/;

        setValues(state => ({...state, link: linkValue}));

        setErrors(state => {
            const newErrors = {...state};

            if (!linkValue) {
                newErrors.link = "Field is required";
            } else if (!urlPattern.test(linkValue)) {
                newErrors.link = "Link is not valid";
            } else {
                delete newErrors.link;
            }

            return newErrors;
        });
    }

    // work with quantity
    const onChangeQuantity = (e) => {

        const {type, rate, min, max} = serviceValue;

        let quantityValue = min;

        try {
            quantityValue = parseInt(e.target.value)
        } catch (e) {
        }

        if (isNaN(quantityValue)) quantityValue = 0;

        setValues(state => ({
            ...state,
            quantity: quantityValue,
            charge: countCharge(type, rate, quantityValue, min, max)
        }));

        setErrors(state => {
            const newError = {...state};

            // validate quantity
            if (quantityValue < min || isNaN(quantityValue)) {
                newError.quantity = `Quantity must be greater than ${min}`;
            } else if (quantityValue > max) {
                newError.quantity = `Quantity must be less than ${max}`;
            } else {
                delete newError.quantity;
            }

            return newError;
        });
    }

    // Addition inputs

    // TYPE: DEFAULT TYPE
    const onChangeRuns = (e) => {
        // event on change runs input

        const {type, rate, min, max} = serviceValue;

        let runsValue = 1;

        try {
            runsValue = parseInt(e.target.value)
        } catch (e) {
        }

        if (isNaN(runsValue)) runsValue = 0;

        setValues(state => ({
            ...state,
            default: {
                ...state.default, runs: runsValue,
            },
            charge: countCharge(type, rate, state.quantity, min, max, runsValue),
        }));

        setErrors(state => {
            const newError = {...state};

            // validate runs
            if (runsValue <= 0 || isNaN(runsValue)) {
                newError.runs = `Runs must be greater than 0`;
            } else {
                delete newError.runs;
            }
            return newError;
        });

    }

    // event on change interval input
    const onChangeInterval = (e) => {

        let intervalValue = 0;

        try {
            intervalValue = parseInt(e.target.value)
        } catch (e) {
        }

        if (isNaN(intervalValue)) intervalValue = 0;

        setValues(state => ({
            ...state,
            default: {
                ...state.default,
                interval: intervalValue
            }
        }));

        setErrors(state => {
            const newError = {...state};

            // validate interval
            if (intervalValue < 0) {
                newError.interval = `Interval must be equal or greater than 0`;
            } else {
                delete newError.interval;
            }

            return newError;
        });
    }

    // TYPE: CUSTOM LIST

    const onChangeCustomItems = (e) => {
        // event on text area where quantity is depended by written items
        const items = e.target.value;
        const itemsArray = items.split('\n');
        const {type, rate, min, max} = serviceValue;

        setValues(state => ({
            ...state,
            items: items,
            quantity: itemsArray.length,
            charge: countCharge(type, rate, itemsArray.length, min, max)
        }))

        setErrors(state => {
            const newError = {...state};

            // validate items
            if (itemsArray.length < min) {
                newError.quantity = `Items must be greater than ${min}`;
            } else if (itemsArray.length > max) {
                newError.quantity = `Items must be less than ${max}`;
            } else {
                delete newError.quantity;
            }

            return newError;
        });
    }

    const onRenderCustomTextArea = (label) => (
            <div className="order-additional__inputs order-additional__custom-comments-package new-order__input-wrap">
                <label className="new-order__label">{label}</label>
                <textarea
                    className={"smm-input order-input order-textarea"}
                    onChange={onChangeCustomItems}
                    cols="30" rows="10"></textarea>
            </div>
    )

    // TYPE: SEO

    const onChangeKeywords = (e) => {
        const keywords = e.target.value;
        const keywordsList = keywords.split('\n');

        setValues(state => ({
            ...state,
            seo: {
                items: keywords
            },
        }))

        setErrors(state => {
            const newError = {...state};

            if (keywordsList.length <= 1) {
                newError.keywords = "Keywords must be greater than 1 tag"
            } else {
                delete newError.keywords;
            }

            return newError
        })
    }

    // TYPE: MENTIONS USER FOLLOWERS

    const onChangeUsernameLink = (e) => {
        const usernameLink = e.target.value;

        const urlPattern = /^(http[s]?:\/\/)(www\.)?[^\s$.?#].[^\s]*$/;

        setValues(state => ({
            ...state,
            mentions: {
                username_link: usernameLink
            }
        }));

        setErrors(state => {
            const newErrors = {...state,};

            if (!usernameLink) {
                newErrors.usernameLink = "Field is required";
            } else if (!urlPattern.test(usernameLink)) {
                newErrors.usernameLink = "Link is not valid";
            } else {
                delete newErrors.usernameLink;
            }

            return newErrors;
        });
    }

    // change items which separated by \n and is not depended by quantity.

    const onChangeSeparatedItems = (e) => {
        const items = e.target.value;
        const itemsList = items.split("\n");

        setValues(state => ({
            ...state,
            items: items
        }));

        validateItems(itemsList);
    }

    // TYPE: COMMENT LIKES

    const onChangeCommentLikesOwner = (e) => {
        const owner = e.target.value;

        setValues(state => ({
            ...state,
            commentLikes: {
                username: owner
            }
        }));

        validateUsername(owner);
    }

    const onChangeUsernameCommentReplies = (e) => {
        const username = e.target.value;

        setValues(state => ({
            ...state,
            commentReplies: {
                username: username
            }
        }));

        validateUsername(username);
    }

    // TYPE: POLL

    const onChangePollAnswerNumber = (e) => {
        const answerNumber = e.target.value;

        setValues(state => ({
            ...state,
            poll: {
                answer_number: answerNumber
            }
        }));

        setErrors(state => {
            const newErrors = {...state};

            if (!answerNumber) {
                newErrors.pollAnswerNumber = "Answer number is required";
            } else if (answerNumber <= 0) {
                newErrors.pollAnswerNumber = "Answer number must be greater than zero";
            } else {
                delete newErrors.pollAnswerNumber;
            }

            return newErrors
        });
    }

    // TYPE: SUBSCRIPTIONS

    // Updates the subscription values in state and triggers a validation callback.
    const onChangeSubscription = (value, key, validate, isRequireCharge=false) => {

        setValues(prevState => {
            const newState = {...prevState};
            newState.subscriptions[key] = value;
            // if value is min or max quantity - count charge
            if (isRequireCharge){
                newState.charge = countChargeBySubscriptions(
                    newState.subscriptions.min, newState.subscriptions.max
                );
            }
            return newState;
        });

        validate(value, key);
    }

    // Calculates the charge based on Subscription type's min value and max value
    const countChargeBySubscriptions = (subsMin, subsMax) => {
         const {type, rate, min, max} = serviceValue;
         let minValue = Number(subsMin),
             maxValue = Number(subsMax);

         // validate minvalue
         if (isNaN(minValue) || minValue < min){
             minValue = min;
         }

         if (isNaN(maxValue) || maxValue > max){
             maxValue = max
         }

         return countCharge(type, rate, 0, minValue, maxValue);
    }

    // method for counting charge
    const countCharge = (type, rate, quantity, min = 1, max = 1, runs = 1) => {

        let countedRate = rate * 0.001;

        // check type of service and count charge
        if (type === 'Default') {
            return (quantity * runs * countedRate).toFixed(4);
        } else if (type === 'Subscriptions') {
            return (((min + max) / 2) * countedRate).toFixed(4);
        } else {
            return (quantity * countedRate).toFixed(4);
        }
    }

    // methods for submitting form

    const getSubmitData = () => {
        const {type, value} = serviceValue;
        const {link, quantity, charge, ...restValues} = values;

        let data = {service_id: value, charge: Number(charge)};

        // if server need link else create subscription type data
        if (type !== "Subscriptions"){
            data.link = link;
        } else {
            const {delay, ...rest} = restValues.subscriptions;
            data = {...data, ...rest, delay: delay.value}
            return data
        }

        // if server need quantity
        if (![...typesWithoutQuantity].includes(type)){
            data.quantity = quantity;
        }

        switch (type) {
            case "Default":
                return {...data, ...restValues.default}
            case "SEO":
                return {...data, ...restValues.seo}
            case "Custom Comments":
                return {...data, items: restValues.items}
            case "Mentions Custom List":
                return {...data, items: restValues.items}
            case "Mentions User Followers":
                return {...data, ...restValues.mentions}
            case "Custom Comments Package":
                return {...data, items: restValues.items}
            case "Comment Likes":
                return {...data, ...restValues.commentLikes}
            case "Poll":
                return {...data, ...restValues.poll}
            case "Comment Replies":
                return {items: restValues.items, ...data,
                        ...restValues.commentReplies}
            case "Invites from Groups":
                return {...data, items: restValues.items}
            default:
                return data;
        }
    }

    const onSubmitForm = (e) => {
        e.preventDefault();

        const data = getSubmitData();

        createOrder(data)
            .then(processResponse)
            .catch(error => {
                dispatch(setNotification(error.response.data.notification));
                if (error.response.data["to"]) navigate("payments/");
            })

    }

    const processResponse = (response) => {
        const {notification, balance} = response.data

        dispatch(setNotification(notification))
        dispatch(updateBalance(balance))

        navigate("history/");
    }

    return fetchingServices ? <Spinner/> : (
        <>
            <h4 className="smm-panel-content-block__title">New Order</h4>
            <form className="new-order__form" onSubmit={onSubmitForm}>
                <div
                    className="smm-panel-content__form-wrap new-order__form-wrap">
                    <div className="new-order__group new-order__group-selects">
                        <div
                            className="new-order__input-wrap new-order__categories">
                            <p className="new-order__label">Category</p>
                            <Select className="smm-panel-select"
                                    name={"category"}
                                    defaultValue={categoryValue}
                                    options={categories}
                                    onChange={onChangeCategory}
                                    isSearch={true}
                            />
                            {errors["category"] &&
                                <InputError message={errors["category"]}/>}
                        </div>
                        <div className="new-order__input-wrap">
                            <p className="new-order__label">Services</p>
                            <Select className="smm-panel-select"
                                    name={"select"}
                                    defaultValue={serviceValue}
                                    options={services}
                                    onChange={onChangeService}
                                    isSearch={true}
                            />
                        </div>
                    </div>
                    {serviceValue.type !== "Subscriptions" &&
                        <div className="new-order__group">
                            <div
                                className="new-order__input-wrap new-order__input-link">
                                <PanelInput type={"url"}
                                            classInput={"order-link"}
                                            label={"Link"}
                                            name={"link"}
                                            onChange={onChangeLink}
                                            value={values.link}
                                            error={errors["link"]}
                                />
                            </div>
                            {!typesWithoutQuantity.includes(serviceValue.type) &&
                                <div
                                    className="new-order__input-wrap new-order__input-quantity">
                                    <PanelInput type={"number"}
                                                classInput={"order-quantity"}
                                                label={"Quantity"}
                                                name={"quantity"}
                                                onChange={onChangeQuantity}
                                                readonly={setReadonlyQuantity(serviceValue.type)}
                                                value={values.quantity}
                                                error={errors["quantity"]}
                                    />
                                    <div className="order-input__add-info">
                                        <p className="order-quantity__min"
                                           data-minval="">Min: {serviceValue.min}</p>
                                        <p>-</p>
                                        <p className="order-quantity__max"
                                           data-maxval="">Max: {serviceValue.max}</p>
                                    </div>
                                </div>}
                        </div>
                    }
                    <div
                        className="order-form__additional order-form__additional-active">
                        {serviceValue.type === "Default" &&
                            <div
                                className="order-additional__inputs order-additional__default new-order__group">
                                <div className="new-order__input-wrap">
                                    <PanelInput type={"number"}
                                                classInput={"order__runs"}
                                                label={"Runs"}
                                                name={"runs"}
                                                onChange={onChangeRuns}
                                                value={values.default.runs}
                                                error={errors["runs"]}/>
                                </div>
                                <div className="new-order__input-wrap">
                                    <PanelInput
                                        type={"number"}
                                        classInput={"order__interval"}
                                        name={"interval"}
                                        label={"Interval in minutes (optional)"}
                                        onChange={onChangeInterval}
                                        value={values.default.interval}
                                        error={errors["interval"]}
                                    />
                                </div>
                            </div>
                        }
                        {typeOfCustomList.includes(serviceValue.type) && onRenderCustomTextArea(serviceValue.type)}
                        {serviceValue.type === "SEO" &&
                            <div
                                className="order-additional__inputs order-additional__custom-comments-package new-order__input-wrap">
                                <label
                                    className="new-order__label">Keywords</label>
                                <textarea
                                    className={`smm-input order-input order-textarea ${errors.keywords && "error-input"}`}
                                    onChange={onChangeKeywords}
                                    cols="30" rows="10"></textarea>
                                {errors.keywords &&
                                    <InputError message={errors.keywords}/>}
                            </div>
                        }
                        {serviceValue.type === "Mentions User Followers" &&
                            <div
                                className="order-additional__inputs order-additional__mentions-user new-order__input-wrap">
                                <PanelInput
                                    type={"url"}
                                    classInput={"order__username__link"}
                                    name={"username-link"}
                                    label={"Username"}
                                    onChange={onChangeUsernameLink}
                                    value={values.mentions.username_link}
                                    error={errors["usernameLink"]}
                                />
                            </div>
                        }
                        {serviceValue.type === "Custom Comments Package" &&
                            <div
                                className="order-additional__inputs order-additional__custom-comments-package new-order__input-wrap">
                                <label
                                    className="new-order__label">Comments</label>
                                <textarea
                                    className={`smm-input order-input order-textarea ${errors["items"] && "error-input"}`}
                                    onChange={onChangeSeparatedItems}
                                    cols="30" rows="10"></textarea>
                                {errors["items"] &&
                                    <InputError
                                        message={errors["items"]}/>}
                            </div>

                        }
                        {serviceValue.type === "Comment Likes" &&
                            <div
                                className="order-additional__inputs order-additional__comment-likes new-order__input-wrap">
                                <PanelInput type={"text"}
                                            name={"username-owner"}
                                            classInput={"order__username-owner"}
                                            label={"Username of the comment owner"}
                                            value={values.commentLikes.username}
                                            onChange={onChangeCommentLikesOwner}
                                            error={errors["username"]}/>
                            </div>
                        }
                        {serviceValue.type === "Poll" &&
                            <div
                                className="order-additional__inputs order-additional__poll new-order__input-wrap">
                                <PanelInput type={"number"}
                                            name={"answer_number"}
                                            classInput={"order__answer-number"}
                                            label={"Answer Number"}
                                            value={values.poll.answer_number}
                                            onChange={onChangePollAnswerNumber}
                                            error={errors["pollAnswerNumber"]}
                                />
                            </div>
                        }
                        {serviceValue.type === "Comment Replies" &&
                            <div
                                className="order-additional__inputs order-additional__comment-replies new-order__input-wrap">
                                <PanelInput type={"text"}
                                            name={"username-replies"}
                                            classInput={"order__username-replies"}
                                            label={"Username"}
                                            value={values.commentReplies.username}
                                            onChange={onChangeUsernameCommentReplies}
                                            error={errors["username"]}/>
                            </div>
                        }
                        {serviceValue.type === "Invites from Groups" &&
                            <div
                                className="order-additional__inputs order-additional__invites-from-groups new-order__input-wrap">
                                <label
                                    className="new-order__label">Groups</label>
                                <textarea
                                    className={`smm-input order-input order-textarea ${errors["items"] && "error-input"}`}
                                    onChange={onChangeSeparatedItems}
                                    cols="30" rows="10"></textarea>
                                {errors["items"] &&
                                    <InputError
                                        message={errors["items"]}/>}
                            </div>
                        }
                        {serviceValue.type === "Subscriptions" &&
                            <div
                                className="order-additional__inputs order-additional__subscriptions">
                                <div className="new-order__input-wrap">
                                    <PanelInput type={"text"}
                                                name={"subs-username"}
                                                classInput={"order__subs-username"}
                                                label={"Username"}
                                                value={values.subscriptions.username}
                                                onChange={(e) => onChangeSubscription(
                                                    e.target.value,
                                                    "username",
                                                    validateUsername
                                                )}
                                                error={errors["username"]}
                                    />
                                </div>
                                <div className="new-order__group">
                                    <div className="new-order__input-wrap">
                                        <PanelInput type={"number"}
                                                    name={"subs-posts"}
                                                    classInput={"order__subs-posts"}
                                                    label={"Posts (optional)"}
                                                    value={values.subscriptions.posts}
                                                    onChange={(e) => onChangeSubscription(
                                                        e.target.value,
                                                        "posts",
                                                        validatePositiveNum
                                                    )}
                                                    error={errors["posts"]}
                                        />
                                    </div>
                                    <div className="new-order__input-wrap">
                                        <PanelInput type={"number"}
                                                    name={"subs-old_posts"}
                                                    classInput={"order__old_posts"}
                                                    label={"Old Posts (optional)"}
                                                    value={values.subscriptions.old_posts}
                                                    onChange={(e) => onChangeSubscription(
                                                        e.target.value,
                                                        "old_posts",
                                                        validatePositiveNum
                                                    )}
                                                    error={errors["old_posts"]}
                                        />
                                    </div>
                                </div>
                                <div className="new-order__group">
                                    <div className="new-order__input-wrap">
                                        <PanelInput type={"number"}
                                                    name={"subs-min_quantity"}
                                                    classInput={"order__subs-min-quantity"}
                                                    label={"Min Quantity"}
                                                    value={values.subscriptions.min}
                                                    onChange={(e) => onChangeSubscription(e.target.value, "min", validateMinMaxValue, true)}
                                                    error={errors["min"]}
                                        />
                                    </div>
                                    <div className="new-order__input-wrap">
                                        <PanelInput type={"number"}
                                                    name={"subs-max_quantity"}
                                                    classInput={"order__subs-max-quantity"}
                                                    label={"Max Quantity"}
                                                    value={values.subscriptions.max}
                                                    onChange={(e) => onChangeSubscription(e.target.value, "max", validateMinMaxValue, true)}
                                                    error={errors["max"]}
                                        />
                                    </div>
                                </div>
                                <div className="new-order__group">
                                    <div className="new-order__input-wrap">
                                        <p className="new-order__label">
                                            Delay</p>
                                        <Select className={"smm-panel-select"}
                                                name={"delay"}
                                                defaultValue={values.subscriptions.delay}
                                                onChange={(value) => onChangeSubscription(
                                                    value,
                                                    "delay",
                                                    validateDelay
                                                )}
                                                options={delayOptions}/>
                                    </div>
                                    <div className="new-order__input-wrap">
                                        <PanelInput type={"date"}
                                                    name={"expiry"}
                                                    classInput={"order__subs-expr-date"}
                                                    value={values.subscriptions.expired_date}
                                                    onChange={(e) => onChangeSubscription(
                                                        e.target.value,
                                                        "expired_date",
                                                        validateExpiredDate
                                                    )}
                                                    label={"Expire Date (optional)"}
                                                    error={errors["expired_date"]}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="new-order__input-wrap">
                        <label className="new-order__label"
                               htmlFor="id_charge">Charge</label>
                        <input type="text" name="charge"
                               className="smm-input order-input order-charge"
                               value={values.charge}
                               readOnly={true} required
                               id="id_charge"/>
                    </div>
                </div>
                <button type={"submit"}
                        disabled={isError}
                        className={getButtonSubmitClass("smm-btn__green new-order__btn", isError)}>
                    Submit
                </button>
            </form>
        </>
    )
}

export default OrderCreate