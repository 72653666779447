import {ErrorMessage, Field} from "formik";
import setErrorInput from "../../../utils/setErrorInput";
import setVisiblePassword from "../../../utils/setVisiblePassword";
import {useRef} from "react";


const PasswordInput = (props) => {

    const passwordRef = useRef(null);

    return (
        <div className="auth-form__input-item">
            <label className="auth-form__label"
                   htmlFor="password">Password</label>
            <div className={
                props.errors[props.name] ?
                    "auth-form__input-wrap auth-form__input-password error-input" :
                    "auth-form__input-wrap auth-form__input-password"
            }>
                <Field type="password" required
                       className={setErrorInput(props.errors[props.name])}
                       placeholder="Password" id={props.name}
                       name={props.name}/>
                <svg className="auth-form__input-view"
                     ref={passwordRef}
                     onClick={() => setVisiblePassword(passwordRef)}
                     width="20"
                     height="20" viewBox="0 0 20 20"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_56_1131)">
                        <path
                            d="M19.8488 9.60712C19.8037 9.5572 18.7235 8.37036 16.9804 7.17325C14.6498 5.57262 12.236 4.72656 10 4.72656C7.76402 4.72656 5.35023 5.57262 3.0196 7.17325C1.27655 8.37036 0.196348 9.5572 0.151231 9.60712C-0.0504103 9.83024 -0.0504103 10.1698 0.151231 10.3929C0.196348 10.4428 1.27658 11.6296 3.01964 12.8267C5.35023 14.4274 7.76406 15.2735 10 15.2735C12.236 15.2735 14.6498 14.4274 16.9804 12.8268C18.7235 11.6297 19.8037 10.4428 19.8488 10.3929C20.0504 10.1698 20.0504 9.83024 19.8488 9.60712ZM5.683 13.025C4.10472 12.2675 2.59952 11.1574 1.40764 10.0002C2.09807 9.33106 3.66093 7.94829 5.68386 6.97375C5.08105 7.83094 4.72659 8.87481 4.72659 10C4.72655 11.1247 5.08065 12.1681 5.683 13.025ZM10 14.1016C7.74031 14.1016 5.89843 12.2633 5.89843 10C5.89843 7.7384 7.73839 5.89844 10 5.89844C12.2599 5.89844 14.1016 7.73696 14.1016 10C14.1016 12.2616 12.2616 14.1016 10 14.1016ZM14.3162 13.0263C14.919 12.1691 15.2735 11.1252 15.2735 10C15.2735 8.87536 14.9194 7.83196 14.3171 6.975C15.8954 7.73251 17.4006 8.84259 18.5924 9.99978C17.902 10.669 16.3392 12.0517 14.3162 13.0263Z"
                            fill="#D8D8D8"/>
                        <path
                            d="M10 7.07031C8.38457 7.07031 7.07031 8.38457 7.07031 10C7.07031 11.6154 8.38457 12.9297 10 12.9297C11.6154 12.9297 12.9297 11.6154 12.9297 10C12.9297 8.38457 11.6155 7.07031 10 7.07031ZM10 11.7578C9.03075 11.7578 8.24219 10.9693 8.24219 10C8.24219 9.03075 9.03075 8.24219 10 8.24219C10.9693 8.24219 11.7578 9.03075 11.7578 10C11.7578 10.9693 10.9693 11.7578 10 11.7578Z"
                            fill="#D8D8D8"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_56_1131">
                            <rect width="20" height="20"
                                  fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <ErrorMessage className="error-label" name={props.name}
                          component="p"/>
        </div>
    )
}

export default PasswordInput;