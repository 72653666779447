import {useSelector} from "react-redux";
import {balanceSelector, statsSelector} from "../../settingsPage/UserSlice";

const AccountStats = () => {

    const userBalance = useSelector(balanceSelector);
    const {spent_sum, spent_avg, quantity_avg} = useSelector(statsSelector);

    return (
        <div className="smm-panel-content__stats">
            <div className="smm-panel-content__wrapper">
                <ul className="smm-panel-content__stats-list">
                    <li className="smm-panel-content__stats-item">
                        <div
                            className="smm-panel-content__stats-item-wrap">
                            <h4 className="smm-panel-content__stats-item-title">
                                Account Status</h4>
                            <p className="smm-panel-content__stats-item-value">STANDARD</p>
                        </div>
                    </li>
                    <li className="smm-panel-content__stats-item">
                        <div
                            className="smm-panel-content__stats-item-wrap">
                            <h4 className="smm-panel-content__stats-item-title">
                                Account Points</h4>
                            <p className="smm-panel-content__stats-item-value">{quantity_avg} ≈ ${spent_avg}</p>
                        </div>
                    </li>
                    <li className="smm-panel-content__stats-item">
                        <div
                            className="smm-panel-content__stats-item-wrap">
                            <h4 className="smm-panel-content__stats-item-title">
                                Account Spending</h4>
                            <p className="smm-panel-content__stats-item-value">${spent_sum}</p>
                        </div>
                    </li>
                    <li className="smm-panel-content__stats-item">
                        <div
                            className="smm-panel-content__stats-item-wrap">
                            <h4 className="smm-panel-content__stats-item-title">
                                Account Balance</h4>
                            <p className="smm-panel-content__stats-item-value">${userBalance}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default AccountStats;
