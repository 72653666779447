import {useRef} from "react";
import checkViewport from "../../../../../utils/checkViewport";


const CheapestResellerBlock = ({header, content}) => {

    const block = useRef(null);

    const onClick = () => block.current.classList.toggle("cheapest-reseller__content-block-active");

    return (
        <div className="cheapest-reseller__content-block" ref={block}
             onClick={() => checkViewport(600, onClick)}>
            <div className="cheapest-reseller__content-block-wrap">
                <div
                    className="cheapest-reseller__content-block-header">
                    <div
                        className="cheapest-reseller__content-block-header__wrap">
                        <h6 className="cheapest-reseller__content-block-header__title">{header}</h6>
                        <svg width="20" height="20"
                             viewBox="0 0 20 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.125 10.6248L15.5934 10.6248L10.8191 15.1723C10.5691 15.4105 10.5594 15.8061 10.7975 16.0561C11.0353 16.3058 11.4309 16.3158 11.6812 16.0777L17.1337 10.8839C17.3697 10.6477 17.5 10.3339 17.5 9.99985C17.5 9.6661 17.3697 9.35204 17.1228 9.10547L11.6809 3.92235C11.56 3.80704 11.405 3.74985 11.25 3.74985C11.085 3.74985 10.92 3.81485 10.7972 3.94391C10.5591 4.19391 10.5687 4.58922 10.8187 4.82735L15.6131 9.37485L3.125 9.37485C2.78 9.37485 2.5 9.65485 2.5 9.99985C2.5 10.3448 2.78 10.6248 3.125 10.6248Z"
                                fill="#08D565"/>
                        </svg>
                    </div>
                </div>
                <div
                    className="cheapest-reseller__content-block-body">
                    <div
                        className="cheapest-reseller__content-block-body__wrapper">
                        {content}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheapestResellerBlock;