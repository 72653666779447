import {useParams, Navigate} from "react-router-dom";
import Preloader from "../../../parts/preloader/Preloader";
import {useEffect, useState} from "react";
import useAuthServices from "../../../../services/AuthServices";
import {useDispatch} from "react-redux";
import {setNotification} from "../../../parts/notification/NotificationSlice";


const SignUpConfirmPage = () => {

    const dispatch = useDispatch();
    const [isFetched, setIsFetched] = useState(false);

    const {uidb64, token} = useParams();
    const {registerConfirm} = useAuthServices();

    useEffect(() => {
        registerConfirm(uidb64, token)
            .then((response) => {
                setIsFetched(true);
                dispatch(setNotification(response.data.notification));
            })
            .catch((error) => {
                setIsFetched(true);
                dispatch(setNotification(error.response.data.notification));
            })
    }, [])

    return isFetched ? <Navigate to="/"/> : <Preloader/>;
}

export default SignUpConfirmPage;