import request from "./index";


const useTicketsServices = () => {
    // services for fetching to ticket's api

    const _baseUrl = "tickets";

    const getTickets = async () => {
        // get tickets of current user
        let url = `${_baseUrl}/`;

        return await request.get(url);
    }

    const postNewTicket = async (data) => {
        // create new ticket
        let url = `${_baseUrl}/`;
        return await request.post(url, data, {
            'headers': {
                'content-type': 'multipart/form-data',
            }
        });
    }

    const getTicketsStatuses = async () => {
        // get available statuses for ticket
        let url = `${_baseUrl}/get-statuses/`;

        return await request.get(url);
    }

    return {getTickets, postNewTicket, getTicketsStatuses}
}

export default useTicketsServices;