import request from "./index";


/*
 * Represents a set of blog services.
 */
const useBlogServices = () => {

    const _baseUrl = "posts";

    const getAllPosts = async (pageNumber=1) => {
        let url = `${_baseUrl}/?page=${pageNumber}`;
        return await request({url});
    }

    const getPost = async (slug) => {
        let url = `${_baseUrl}/${slug}/`
        return await request({url});
    }

    return {getAllPosts, getPost};
}

export default useBlogServices;