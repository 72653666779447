import {NavLink, useLocation} from "react-router-dom";
import logo from "../../../assets/media/header/smm_logo.svg";
import {useNavActive} from "../../../hooks/navActive.hook";
import {useRef} from "react";
import checkViewport from "../../../utils/checkViewport";


const Header = () => {

    const location = useLocation();
    const isMain = location.pathname === "/";

    const defaultClassLink = "smm-link header__link header__first-group-item-link";
    const activeClassLink = "active-link";

    const {setNavClass} = useNavActive(defaultClassLink, activeClassLink);
    const burgerRef = useRef(null);
    const mobileMenuRef = useRef(null);

    function getHeaderClass() {
        const defaultClass = "header";
        return isMain ? defaultClass + ' header__main' : defaultClass;
    }

    function getMobileMenu() {
        burgerRef.current.classList.toggle("header__burger-active");
        mobileMenuRef.current.classList.toggle("header__burger-mobile-active")
    }

    return (
        <header className={getHeaderClass()}>
            {/*{% include 'parts/smm_panel_messages.html' %}*/}
            <div className="wrapper-content">
                <nav className="header__navigation">
                    <div className="header__inner">
                        <div className="header__first-group">
                            <div className="header__first-group-wrap">
                                <div className="header__first-group-logo-wrap">
                                    <NavLink to="/"
                                             onClick={() => checkViewport(800, getMobileMenu)}
                                             className="smm-link header__first-group-link">
                                        <img src={logo} alt="logo"
                                             className="header__first-group-logo"/>
                                    </NavLink>
                                </div>
                                <ul className="header__first-group-list" ref={mobileMenuRef}>
                                    <li className="header__first-group-item">
                                        <NavLink to="/"
                                                 onClick={() => checkViewport(800, getMobileMenu)}
                                                 className={({isActive}) => setNavClass(isActive)}>
                                            <p className="header__first-group-item-text">Home</p>
                                        </NavLink>
                                    </li>
                                    <li className="header__first-group-item">
                                        <NavLink to="/blog/"
                                                 onClick={() => checkViewport(800, getMobileMenu)}
                                                 className={({isActive}) => setNavClass(isActive)}>
                                            <p className="header__first-group-item-text">Blog</p>
                                        </NavLink>
                                    </li>
                                    <li className="header__first-group-item">
                                        <NavLink to="/terms/"
                                                 onClick={() => checkViewport(800, getMobileMenu)}
                                                 className={({isActive}) => setNavClass(isActive)}>
                                            <p className="header__first-group-item-text">Terms</p>
                                        </NavLink>
                                    </li>
                                    <li className="header__first-group-item">
                                        <NavLink to="/faq/"
                                                 onClick={() => checkViewport(800, getMobileMenu)}
                                                 className={({isActive}) => setNavClass(isActive)}>
                                            <p className="header__first-group-item-text">FAQ</p>
                                        </NavLink>
                                    </li>
                                    <li className="header__first-group-item">
                                        <NavLink to="/contact-us/"
                                                 onClick={() => checkViewport(800, getMobileMenu)}
                                                 className={({isActive}) => setNavClass(isActive)}>
                                            <p className="header__first-group-item-text">Contact
                                                Us</p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="header__second-group">
                            <div className="header__second-group-wrap">
                                <NavLink to="/sign-in/"
                                         className="smm-link header__second-group-link">
                                    <div className="header__second-group-item">
                                        <svg width="12" height="12"
                                             viewBox="0 0 12 12"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_13_1912)">
                                                <path
                                                    d="M5.91449 5.78046C6.70862 5.78046 7.39627 5.49564 7.95813 4.93369C8.51999 4.37183 8.80481 3.68436 8.80481 2.89014C8.80481 2.09619 8.51999 1.40863 7.95804 0.846588C7.39609 0.284821 6.70853 0 5.91449 0C5.12027 0 4.4328 0.284821 3.87094 0.84668C3.30908 1.40854 3.02417 2.0961 3.02417 2.89014C3.02417 3.68436 3.30908 4.37192 3.87103 4.93378C4.43298 5.49554 5.12054 5.78046 5.91449 5.78046Z"
                                                    fill="#08D565"/>
                                                <path
                                                    d="M10.9718 9.22742C10.9556 8.99359 10.9228 8.73853 10.8745 8.46918C10.8258 8.19782 10.7631 7.94128 10.688 7.70682C10.6105 7.46448 10.505 7.22516 10.3747 6.99582C10.2393 6.75778 10.0804 6.55051 9.90207 6.37994C9.71558 6.20151 9.48724 6.05804 9.22321 5.9534C8.96008 5.8493 8.66849 5.79657 8.35657 5.79657C8.23407 5.79657 8.1156 5.84683 7.88681 5.99579C7.746 6.08762 7.5813 6.19382 7.39746 6.31128C7.24026 6.41144 7.02731 6.50528 6.76428 6.59024C6.50766 6.67328 6.2471 6.71539 5.98993 6.71539C5.73276 6.71539 5.47229 6.67328 5.21539 6.59024C4.95264 6.50537 4.73969 6.41153 4.58267 6.31137C4.40057 6.19501 4.23578 6.08881 4.09286 5.9957C3.86435 5.84674 3.74579 5.79648 3.62329 5.79648C3.31128 5.79648 3.01978 5.8493 2.75674 5.95349C2.49289 6.05795 2.26447 6.20142 2.07779 6.38004C1.89954 6.55069 1.74051 6.75787 1.60538 6.99582C1.4751 7.22516 1.36963 7.46439 1.29199 7.70691C1.21701 7.94138 1.1543 8.19782 1.10559 8.46918C1.05734 8.73816 1.02457 8.99332 1.00836 9.22769C0.992432 9.45731 0.984375 9.69562 0.984375 9.93631C0.984375 10.5627 1.1835 11.0698 1.57617 11.4438C1.96399 11.8129 2.47714 12.0001 3.10117 12.0001H8.87924C9.50327 12.0001 10.0162 11.813 10.4041 11.4438C10.7969 11.0701 10.996 10.5629 10.996 9.93622C10.9959 9.69443 10.9878 9.45593 10.9718 9.22742Z"
                                                    fill="#08D565"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_13_1912">
                                                    <rect width="12"
                                                          height="12"
                                                          fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <p className="header__second-group-item-text">Sign
                                            in</p>
                                    </div>
                                </NavLink>
                                <NavLink to="/sign-up/"
                                         className="smm-link header__second-group-link">
                                    <button type="button"
                                            className="smm-btn__green header__second-group-btn">
                                        <span>Get started</span>
                                        <svg width="23" height="22"
                                             viewBox="0 0 23 22"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.2566 14.5841L14.869 7.97169L14.7487 12.9154C14.7424 13.1742 14.947 13.3892 15.2059 13.3955C15.4644 13.4018 15.6796 13.1973 15.686 12.9382L15.8232 7.29221C15.8231 7.04179 15.7258 6.80629 15.5486 6.62913C15.3716 6.45213 15.136 6.35468 14.8743 6.35485L9.23953 6.49207C9.11424 6.49505 9.00171 6.54693 8.91951 6.62913C8.83201 6.71663 8.77897 6.83861 8.78229 6.97218C8.78859 7.23105 9.00337 7.43556 9.26224 7.42926L14.2165 7.29834L7.59369 13.9212C7.41072 14.1041 7.41072 14.4011 7.59369 14.5841C7.77665 14.767 8.07363 14.767 8.2566 14.5841Z"
                                                fill="white"/>
                                        </svg>
                                    </button>
                                </NavLink>
                                <div className="header__burger" ref={burgerRef}
                                     onClick={() => checkViewport(800, getMobileMenu)}>
                                    <span className="header__burger-bar"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header;