import ChangeEmailForm from "./changeEmailForm/ChangeEmailForm";
import ChangePasswordsForm from "./changePasswordsForm/ChangePasswordsForm";
import ChangePersonalInfoForm
    from "./changePersonalInfoForm/ChangePersonalInfoForm";
import {useSelector} from "react-redux";
import {userSelector} from "./UserSlice";


const SettingsPage = () => {

    const {userData} = useSelector(userSelector);

    return (
        <div className="change-settings">
            <div className="smm-panel-content__blocks">
                <div className="smm-panel-content__wrapper">
                    <div className="smm-panel-content__blocks-inner">
                        <div className="smm-panel-content__block-group">
                            <ChangeEmailForm email={userData.email}/>
                            <section
                                className="smm-panel-content__block change-password">
                                <div
                                    className="smm-panel-content-block__wrapper change-email__wrapper">
                                    <ChangePasswordsForm/>
                                </div>
                            </section>
                        </div>
                        <section
                            className="smm-panel-content__block change-personal-info">
                            <div
                                className="smm-panel-content-block__wrapper change-email__wrapper">
                                <ChangePersonalInfoForm
                                    firstName={userData.first_name}
                                    lastName={userData.last_name}/>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsPage;
