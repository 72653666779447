import request from "./index";

const usePaymentsServices = () => {
    // service for fetching to payment api

    const _baseUrl = "payments";

    const getPaymentMethods = async () => {
        // get payment methods
        let url = `${_baseUrl}/get-payment-methods/`;
        return await request.get(url);
    }

    const getPayments = async () => {
        // get array of payments with pagination
        let url = `${_baseUrl}/`;
        return await request.get(url);
    }

    const createPayment = async (data) => {
        // create payment
        let url = `${_baseUrl}/`;
        return await request.post(url, data);
    }

    return {getPaymentMethods, getPayments, createPayment}
}

export default usePaymentsServices;