import facebook from "../../../../assets/media/available_services/facebook.svg";
import twitter from "../../../../assets/media/available_services/twitter.svg";
import instagram from "../../../../assets/media/available_services/instagram.svg";
import youtube from "../../../../assets/media/available_services/youtube.svg";
import tiktok from "../../../../assets/media/available_services/tiktok.svg";
import telegram from "../../../../assets/media/available_services/telegram.svg";

const SmmAvailableServices = () => {

    return (
        <section className="smm-available-services">
            <div className="smm-available-services__inner">
                <div className="smm-available-services__item">
                    <div className="smm-available-services__item-wrap">
                        <div className="smm-available-services__item-img-wrap">
                            <img src={facebook}
                                alt="facebook"
                                className="smm-available-services__item-img" />
                        </div>
                        <p className="smm-available-services__item-text">Facebook</p>
                    </div>
                </div>
                <div className="smm-available-services__item">
                    <div className="smm-available-services__item-wrap">
                        <div className="smm-available-services__item-img-wrap">
                            <img src={twitter}
                                alt="twitter"
                                className="smm-available-services__item-img" />
                        </div>
                        <p className="smm-available-services__item-text">Twitter</p>
                    </div>
                </div>
                <div className="smm-available-services__item">
                    <div className="smm-available-services__item-wrap">
                        <div className="smm-available-services__item-img-wrap">
                            <img src={instagram}
                                alt="instagram"
                                className="smm-available-services__item-img" />
                        </div>
                        <p className="smm-available-services__item-text">
                            Instagram</p>
                    </div>
                </div>
                <div className="smm-available-services__item">
                    <div className="smm-available-services__item-wrap">
                        <div className="smm-available-services__item-img-wrap">
                            <img src={youtube}
                                alt="youtube"
                                className="smm-available-services__item-img" />
                        </div>
                        <p className="smm-available-services__item-text">
                            Youtube</p>
                    </div>
                </div>
                <div className="smm-available-services__item">
                    <div className="smm-available-services__item-wrap">
                        <div className="smm-available-services__item-img-wrap">
                            <img src={tiktok}
                                 alt="tiktok" className="smm-available-services__item-img"/>
                        </div>
                        <p className="smm-available-services__item-text">TikTok</p>
                    </div>
                </div>
                <div className="smm-available-services__item">
                    <div className="smm-available-services__item-wrap">
                        <div className="smm-available-services__item-img-wrap">
                            <img src={telegram} alt="telegram" className="smm-available-services__item-img" />
                        </div>
                        <p className="smm-available-services__item-text">Telegram</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SmmAvailableServices;