/**
 * Sets the class name for an input element based on the given value.
 * If the value is truthy, the class name will include "error-input",
 * otherwise only "smm-input" and "auth-form__input" will be included.
 *
 * @param {boolean} value - The value indicating if the error input class should be added.
 * @return {string} - The class name to be used for the input element.
 */
export default function setErrorInput (value) {
    return value ? "smm-input auth-form__input error-input" : "smm-input auth-form__input";
}
