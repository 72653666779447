import {NavLink} from "react-router-dom";
import quickResponse from "../../../../assets/media/home/quick_response_img.png"


const SmmQuickResponse = () => {

    return (
        <section className="smm-quick-response">
            <div className="wrapper-content">
                <h2 className="smm-quick-response__title">Quick Response From Clients</h2>
                <div className="smm-quick-response__inner">
                    <div className="smm-quick-response__info">
                        <p className="smm-quick-response__info-text">
                            All services will guarantee full delivery. If any problems do
                            occur, we have a LIVE customer support chat
                            available from Mon-Fri (0900 - 1400) CST. With over
                            150,000 clients from USA , Europe , India ,
                            pakistan and all over the word, we are the best and
                            top All-In-One Social Media Marketing tool, Our SMM
                            PANEL rendered millions of orders on a daily basis .
                        </p>
                        <NavLink to="sign-up/"
                           className="smm-link smm-quick-response__info-link">
                            <button type="button"
                                    className="smm-quick-response__info-btn">
                                <span>Sign up now</span>
                                <svg width="29" height="30" viewBox="0 0 29 30"
                                     fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.0806 20.3033L18.8971 11.4868L18.7367 18.0783C18.7283 18.4235 19.0012 18.7101 19.3463 18.7185C19.6911 18.7269 19.9779 18.4542 19.9865 18.1088L20.1695 10.5808C20.1692 10.2469 20.0395 9.9329 19.8033 9.69669C19.5673 9.46069 19.2531 9.33076 18.9042 9.33098L11.3912 9.51394C11.2241 9.51792 11.0741 9.58708 10.9645 9.69668C10.8478 9.81336 10.7771 9.97599 10.7815 10.1541C10.7899 10.4993 11.0763 10.7719 11.4214 10.7635L18.0272 10.589L9.19671 19.4194C8.95276 19.6634 8.95276 20.0593 9.19671 20.3033C9.44066 20.5472 9.83664 20.5472 10.0806 20.3033Z"
                                        fill="white"/>
                                </svg>
                            </button>
                        </NavLink>
                    </div>
                    <div className="smm-quick-response__img-wrap">
                        <img className="smm-quick-response__img"
                             src={quickResponse}
                             alt="quick-response"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SmmQuickResponse;