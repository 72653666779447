import * as Yup from "yup";


/**
 * Creates an object containing initial values and a validation schema for an email form.
 *
 * @param {string} value - The email value to be set as the initial value.
 * @returns {Object} - An object containing the initial values and the validation schema.
 */
const useEmailForm = (value) => {

    const initialValues =  {
        email: value,
    }

    const EmailValidationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Please, write valid email address")
            .trim()
            .required("Email is required")
    });

    return {initialValues, EmailValidationSchema};
}

export default useEmailForm;