import speed from "../../../../assets/media/home/stats/speed.svg";
import orders from "../../../../assets/media/home/stats/orders.svg";
import prices from "../../../../assets/media/home/stats/prices.svg";

const SmmStats = () => {

    return (
        <section className="smm-stats">
            <div className="wrapper-content">
                <div className="smm-stats__inner">
                    <div className="smm-stats__block">
                        <div className="smm-stats__block-wrap">
                            <div className="smm-stats__block-img-wrap">
                                <img src={speed} alt="speed smm"/>
                            </div>
                            <div className="smm-stats__block-info">
                                <h3 className="smm-stats__block-info-title">
                                    0.3Sec</h3>
                                <p className="smm-stats__block-info-text">An
                                    Order
                                    is made every</p>
                            </div>
                        </div>
                    </div>
                    <div className="smm-stats__block">
                        <div className="smm-stats__block-wrap">
                            <div className="smm-stats__block-img-wrap">
                                <img src={orders} alt="completed orders"/>
                            </div>
                            <div className="smm-stats__block-info">
                                <h3 className="smm-stats__block-info-title">
                                    28,002,098</h3>
                                <p className="smm-stats__block-info-text">Orders
                                    Completed</p>
                            </div>
                        </div>
                    </div>
                    <div className="smm-stats__block">
                        <div className="smm-stats__block-wrap">
                            <div className="smm-stats__block-img-wrap">
                                <img src={prices} alt="prices of services"/>
                            </div>
                            <div className="smm-stats__block-info">
                                <h3 className="smm-stats__block-info-title">
                                    $0.001/1K</h3>
                                <p className="smm-stats__block-info-text">Prices
                                    Starting From</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SmmStats;