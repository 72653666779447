import Spinner from "../../../parts/spinner/Spinner";
import {useEffect, useState} from "react";
import usePaymentsServices from "../../../../services/PaymentsServices";
import Select from "react-dropdown-select";
import getButtonSubmitClass from "../../../../utils/getButtonSubmitClass";
import InputError from "../../../parts/inputError/InputError";
import isObjectEmpty from "../../../../utils/checkObjectEmpty";
import PanelInput from "../../../parts/panelInput/PanelInput";
import {useDispatch, useSelector} from "react-redux";
import {setNotification} from "../../../parts/notification/NotificationSlice";
import {updateBalance} from "../../settingsPage/UserSlice";
import {createPayment} from "../PaymentsSlice";

const AddFundsForm = () => {
    /*
    Renders a form for adding funds.
    Retrieves available payment methods and allows the user to select a method and specify an amount.
    */

    // some utils
    const dispatch = useDispatch();
    const {paymentSubmitting} = useSelector(state => state.payments);
    const {getPaymentMethods} = usePaymentsServices();
    // get methods
    const [methods, setMethods] = useState([]);
    // create form data
    const [methodValue, setMethodValue] = useState("");
    const [amountValue, setAmountValue] = useState(100);
    const [errors, setErrors] = useState({});
    const [isError, setIsError] = useState(true);

    // request to the server
    const [fetchingMethods, setFetchingMethods] = useState(true);

    // get methods of payment

    useEffect(() => {
        getPaymentMethods()
            .then(response => {
                setFetchingMethods(false);
                setMethodValue(Object.keys(response.data.methods)[0]);
                setMethods(response.data.methods);
            })
            .catch(error => {
                setFetchingMethods(false);
                dispatch((setNotification({
                    "type": "error",
                    "text": "Server error"
                })))
            })
    }, []);

    useEffect(() => {
        setIsError(!isObjectEmpty(errors));
    }, [errors])

    if (fetchingMethods) {
        return <Spinner/>;
    }

    // render options for method's select

    let defaultOption = [];

    const renderOptions = Object.keys(methods).map((item, id) => {
        // create array of options for method's select
        if (id === 0) {
            defaultOption.push({value: item, label: methods[item]});
        }
        return {value: item, label: methods[item]}
    });

    // validate and submit form

    const onChangeMethod = (value) => {
        const method = value[0]["value"];
        setMethodValue(method);
        setErrors(errors => {
            const newError = {...errors};

            if (!Object.keys(methods).includes(method)) {
                newError.method = "Unknown method";
            } else {
                delete newError.method;
            }

            return newError;
        })
    }

    const onChangeAmount = (value) => {
        setAmountValue(value);
        setErrors(errors => {
            const newError = {...errors};

            if (value <= 4) {
                newError.amount = "Min amount is 5$ ";
            } else {
                delete newError.amount
            }

            return newError;
        })
    }

    const onSubmitForm = (e) => {
        e.preventDefault();
        const data = {
            method: methodValue,
            amount: amountValue
        }
        dispatch(createPayment(data))
            .then(response => {
                const notification = response?.payload?.data?.notification;
                const payment = response?.payload?.data?.payment;
                const balance = response?.payload?.data?.balance;
                if (notification) {
                    dispatch(setNotification(notification));
                    if (payment) {
                        dispatch(updateBalance(balance));
                    }
                } else{
                    dispatch(setNotification({
                        "type": "error",
                        "text": "Server error"
                    }))
                }
            })
    }

    return (
        <>
            <h4 className="smm-panel-content-block__title">
                Add funds</h4>
            <form className="add-funds__form"
                  onSubmit={onSubmitForm}>
                <div
                    className="smm-panel-content__form-wrap new-order__form-wrap">
                    <div className="smm-panel-content__input-wrap">
                        <p className="new-order__label">
                            Method</p>
                        <Select name="method"
                                className="order-select add-funds__method-select"
                                searchable={false}
                                closeOnClickInput={true}
                                onChange={(value) => onChangeMethod(value)}
                                options={renderOptions}
                                values={defaultOption}
                        />
                        {errors["method"] &&
                            <InputError message={errors["method"]}/>}
                    </div>
                    <PanelInput classInput="add-funds__amount"
                                type="number"
                                name="amount"
                                label={"Amount"}
                                value={amountValue}
                                error={errors["amount"]}
                                onChange={(e) => onChangeAmount(Number(e.target.value))}
                    />
                    <button type="submit"
                            disabled={isError || paymentSubmitting === "loading"}
                            className={getButtonSubmitClass("smm-btn__green add-funds__submit", isError)}
                    >
                        Add
                    </button>
                </div>
            </form>
        </>
    )
}

export default AddFundsForm;