import Breadcrumbs from "../../../parts/breadcrumbs/Breadcrumbs";
import useBlogServices from "../../../../services/BlogServices";
import {useEffect, useState} from "react";
import Preloader from "../../../parts/preloader/Preloader";
import PostItem from "./postItem/PostItem";
import Pagination from "../../../parts/pagination/Pagination";
import {useDispatch} from "react-redux";
import {setNotification} from "../../../parts/notification/NotificationSlice";


const PostsListPage = () => {

    const paths = [
        {
            name: "Home",
            path: "/"
        },
        {
            name: "Blog",
            path: "/blog/"
        }
    ];

    const dispatch = useDispatch();

    const [posts, setPosts] = useState([]),
        [pagination, setPagination] = useState({}),
        [loading, setLoading] = useState(true),
        [error, setError] = useState(false);
    const {getAllPosts} = useBlogServices();


    useEffect(() => {
        getAllPosts()
            .then(response => onLoaded(response.data))
            .catch(error => onError(error));
    }, []);

    const onPaginate = (pageNumber) => {
        setLoading(true)
        getAllPosts(pageNumber)
            .then(response => onLoaded(response.data))
            .catch(error => onError(error));
    }

    const onLoaded = (data) => {
        setLoading(false);
        setPosts(data.items);
        setPagination(data.pagination)
    }

    const onError = (error) => {
        setError(true);
        dispatch(setNotification({
            'type': 'error',
            'text': 'Something went wrong'
        }))
    }

    const renderItems = () => posts.map((item) => <PostItem key={item.id}
                                                            {...item} />)

    const renderContent = () => (
        <div className="main-bg">
            <div className="wrapper-content">
                <Breadcrumbs paths={paths}/>
                <section className="blog-header">
                    <div className="blog-header__wrap">
                        <h2 className="smm-title blog-header__title">Blog</h2>
                        <p className="blog-header__text">
                            Follow our blog and stay
                            informed about the latest
                            trends in social media marketing.
                        </p>
                    </div>
                </section>
                <section className="blog-list">
                    <div className="blog-list__inner">
                        {renderItems()}
                    </div>
                </section>
                <Pagination onPaginate={onPaginate} {...pagination} />
            </div>
        </div>
    )

    return loading ? <Preloader/> : renderContent();
}

export default PostsListPage;