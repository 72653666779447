
/**
 * Class representing a Cookie.
 */
class Cookie{

     /**
      * Retrieves the value of a cookie by its name.
      *
      * @param {string} name - The name of the cookie to retrieve.
      * @return {string | null} - The value of the cookie if found, null otherwise.
      */
     getCookie(name) {
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName === name) {
                return cookieValue;
            }
        }
        return null;
    }

    /**
     * Set a cookie with the given name, value, expiration time, and secure flag.
     *
     * @param {string} name - The name of the cookie.
     * @param {string} value - The value of the cookie.
     * @param {number} seconds - The number of seconds until the cookie expires.
     * @param {boolean} [isSecure=false] - Flag indicating if the cookie should be secure (HTTPS only).
     */
    setCookie(name, value, seconds, isSecure=false) {
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + seconds * 1000);

        let cookieStr = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;

        document.cookie = isSecure ? cookieStr.concat('; SameSite=None; secure;') : cookieStr;
    }

    /**
     * Remove a cookie with the given name.
     *
     * @param {string} name - The name of the cookie.
     */
    removeCookie(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

}

export default Cookie;