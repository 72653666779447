import useParseDate from "../../../../hooks/dateParse.hook";

const TicketItem = ({id, type, created_at, status}) => {

    const formattingCreatedAt = useParseDate(created_at, true)

    return (
        <li className="ticket-history__list-item">
            <div className="ticket-history__list-item-up">
                <svg width="12" height="12"
                     viewBox="0 0 12 12"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_792_2701)">
                        <path
                            d="M4.21875 7.875H7.78125"
                            stroke="#08D565"
                            strokeWidth="0.9375"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <mask
                            id="mask0_792_2701"
                            style={{maskType: "luminance"}}
                            maskUnits="userSpaceOnUse"
                            x="0" y="0"
                            width="12"
                            height="12">
                            <path
                                d="M0 9.53674e-07H12V12H0V9.53674e-07Z"
                                fill="white"/>
                        </mask>
                        <g mask="url(#mask0_792_2701)">
                            <path
                                d="M0.46875 4.12891L2.8125 1.78516"
                                stroke="#08D565"
                                strokeWidth="0.9375"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path
                                d="M9.1875 1.78516L11.5312 4.12891V11.5352H0.46875V4.12891L4.21875 7.87891"
                                stroke="#08D565"
                                strokeWidth="0.9375"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path
                                d="M2.34375 9.66016L0.46875 11.5352"
                                stroke="#08D565"
                                strokeWidth="0.9375"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path
                                d="M9.65625 9.66016L11.5312 11.5352"
                                stroke="#08D565"
                                strokeWidth="0.9375"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path
                                d="M11.5312 4.12891L7.78125 7.87891"
                                stroke="#08D565"
                                strokeWidth="0.9375"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path
                                d="M2.8125 6.47266V0.472657H9.1875V6.47266"
                                stroke="#08D565"
                                strokeWidth="0.9375"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"/>
                        </g>
                    </g>
                    <defs>
                        <clipPath
                            id="clip0_792_2701">
                            <rect width="12"
                                  height="12"
                                  fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <p>
                    <span className="ticket-id">{id}</span> - <span className="ticket-type">{type}</span>
                </p>
            </div>
            <div
                className="ticket-history__list-item-down">
                <p>
                    <span className="ticket-data">{formattingCreatedAt} </span>
                    <span className="ticket-status">{status}</span>
                </p>
            </div>
        </li>
    )
}

export default TicketItem;