import Select from "../../../parts/select/Select";
import {useDispatch, useSelector} from "react-redux";
import {filterByDate, filterBySearch, filterByStatus} from "../HistorySlice";
import validateDate from "../../../../utils/validateDate";
import {useState} from "react";

const HistoryFilters = () => {

    const dispatch = useDispatch();
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const {searchFilter, statusFilter} = useSelector(state => state.history)

    const statuses = [
        {label: 'All', value: 'All'},
        {label: 'Processing', value: 'Processing'},
        {label: 'Pending', value: 'Pending'},
        {label: 'Partial', value: 'Partial'},
        {label: 'In progress', value: 'In progress'},
        {label: 'Completed', value: 'Completed'},
        {label: 'Canceled', value: 'Canceled'}
    ]

    const onChangeSearch = (e) => {
        const {value} = e.target;
        dispatch(filterBySearch(value))
    }

    const onChangeStatus = ({value}) => {
        dispatch(filterByStatus(value))
    }

    const onChangeDate = (value, setter) => {
        if (validateDate(new Date(value)) || value === ""){
            setter(value);
        }
    }

    const onFilterByDate = (e) => {
        e.preventDefault();

        dispatch(filterByDate([dateFrom, dateTo]));
    }

    return (
        <div className="history-filters">
                <div className="history-filters__inner">
                    <div className="history-filters__search">
                        <div className="history-filters__search-inner">
                            <div className="history-filters__search-input-wrap">
                                <input name="search"
                                       className="smm-input order-input history-filters__search-input"
                                       placeholder="Search"
                                       onChange={onChangeSearch}
                                       value={searchFilter}/>
                            </div>
                            <button className="smm-btn__green history-filters__search-btn">
                                <svg width="12" height="12"
                                     viewBox="0 0 12 12"
                                     fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_42_1190)">
                                        <path
                                            d="M11.8866 11.3471L8.78842 8.29845C9.59974 7.41698 10.0982 6.25121 10.0982 4.96841C10.0979 2.22426 7.83758 0 5.04917 0C2.26077 0 0.000488281 2.22426 0.000488281 4.96841C0.000488281 7.71256 2.26077 9.93682 5.04917 9.93682C6.25395 9.93682 7.35896 9.5201 8.22692 8.82731L11.3371 11.888C11.4887 12.0373 11.7347 12.0373 11.8863 11.888C12.0382 11.7387 12.0382 11.4964 11.8866 11.3471ZM5.04917 9.1724C2.68988 9.1724 0.777299 7.29021 0.777299 4.96841C0.777299 2.6466 2.68988 0.764419 5.04917 0.764419C7.40849 0.764419 9.32104 2.6466 9.32104 4.96841C9.32104 7.29021 7.40849 9.1724 5.04917 9.1724Z"
                                            fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath
                                            id="clip0_42_1190">
                                            <rect width="12"
                                                  height="12"
                                                  fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>

                                <span>Search</span>
                            </button>
                        </div>
                    </div>
                    <div className="history-filters__filters">
                        <div className="history-filters__filters-inner">
                            <div className="history-filters__filters-date">
                                <form className="history-filters__form"
                                      method="get"
                                      onSubmit={onFilterByDate}
                                      name="filterForm" >
                                    <div className="history-filters__filters-date-inner">
                                        <p className="history-filters__label">Date</p>
                                        <div className="history-filters__filters-date-input-inner">
                                            <div className="history-filters__filters-input-wrap">
                                                <input type="date"
                                                       className="smm-input order-input history-filters__filters-date-input"
                                                       name="date_from"
                                                       onChange={(e) => onChangeDate(e.target.value, setDateFrom)}
                                                       value={dateFrom} />
                                            </div>
                                            <div className="history-filters__filters-input-wrap">
                                                <input type="date"
                                                       className="smm-input order-input history-filters__filters-date-input"
                                                       name="date_to"
                                                       onChange={(e) => onChangeDate(e.target.value, setDateTo)}
                                                       value={dateTo} />
                                            </div>
                                            <button className="smm-btn__green history-filters__filters-date-btn">
                                                <svg width="15"
                                                     height="15"
                                                     viewBox="0 0 15 15"
                                                     fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_39_897)">
                                                        <path
                                                            d="M14.4427 0H0.615235C0.291596 0 0.0292969 0.262299 0.0292969 0.585938C0.0292969 2.21764 0.72876 3.77541 1.94836 4.85951L4.51447 7.14031C4.95941 7.53582 5.21462 8.10414 5.21462 8.69957V14.4134C5.21462 14.8803 5.73647 15.1602 6.12545 14.9008L9.58237 12.5963C9.74533 12.4876 9.8433 12.3047 9.8433 12.1088V8.69957C9.8433 8.10414 10.0985 7.53582 10.5434 7.14031L13.1094 4.85951C14.329 3.77541 15.0285 2.21764 15.0285 0.585938C15.0285 0.262299 14.7662 0 14.4427 0ZM12.3309 3.98358L9.7649 6.2645C9.07002 6.88225 8.67142 7.76974 8.67142 8.69946V11.7952L6.38638 13.3185V8.69957C6.38638 7.76974 5.98778 6.88225 5.29289 6.2645L2.7269 3.98369C1.90453 3.25253 1.37959 2.25071 1.23905 1.17176H13.8187C13.6782 2.25071 13.1534 3.25253 12.3309 3.98358Z"
                                                            fill="white"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath
                                                            id="clip0_39_897">
                                                            <rect
                                                                width="15"
                                                                height="15"
                                                                fill="white"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="history-filters__filters-status">
                                <div className="history-filters__filters-status-inner">
                                    <p className="history-filters__label">Status</p>
                                    <Select className="smm-panel-select"
                                            name={"status"}
                                            defaultValue={{value: statusFilter, label: statusFilter}}
                                            options={statuses}
                                            onChange={onChangeStatus}
                                            isSearch={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default HistoryFilters;