import {useGoogleLogin} from '@react-oauth/google';
import {useDispatch} from "react-redux";
import {socialLogin} from "../../pages/authPages/AuthSlice";
import {setNotification} from "../notification/NotificationSlice";
import FacebookLogin
    from 'react-facebook-login/dist/facebook-login-render-props';
import capitalize from "../../../utils/capitalize";

const AuthSocialNetworks = () => {

    const dispatch = useDispatch()

    const loginGoogle = useGoogleLogin({
        onSuccess: (response) => onSuccessGoogleLogin(response),
        // onError: error => onErrorGoogleLogin(error),
        select_account: true,
        redirect_uri: "http://localhost:3000"
    })

    const fetchToSocialLogin = (sendData, type) => {
        dispatch(socialLogin(sendData)).then(res => {

            const notification = res?.payload?.data?.notification;
            const errorDesc = res?.payload?.data?.error_description;

            if (notification) {
                dispatch(setNotification(notification))
            } else if (errorDesc) {
                dispatch(setNotification({
                    'type': 'error',
                    'text': errorDesc
                }));
            } else {
                dispatch(setNotification({
                    'type': 'success',
                    'text': `${capitalize(type)} auth`
                }))
            }
        });
    }

    const onSuccessGoogleLogin = (response) => {
        const sendData = {
            token: response.access_token,
            backend: 'google-oauth2',
            grant_type: "convert_token",
            client_id: 'dvQiB8nv7HfaBacLxgm60WQJYlXi4yMShH6Gj5Gt',
            client_secret: 'gn6KZfMp92YMEEo7qj7rsjeHZZ2eE9TxxfdH0o81fOAoV4khcmJY44cRrAlPMzzT5BhedQSQ8G27Vw85MQ0CTtbYSEBPore6zjLyFnpfxf7Fv7nbIpDhqYSlZyG06rnA'
        }

        fetchToSocialLogin(sendData, 'google')
    }

    const onSuccessFacebookLogin = (response) => {
        const sendData = {
            token: response.accessToken,
            backend: 'facebook',
            grant_type: "convert_token",
            client_id: 'xiAOhiFqFrujjAORF0xxHEMYLRoR5RrpI5qnapOc',
            client_secret: 'm0nQtBmLdUH2feP0T1SmSid06g8sOZCGan36AqcbAgVQXAKtgc3eurVoq8w7YC3PmqHzMFpjNoSHW5agThS80ege3MsLQSxCRWHwXqNgCuJ98u776vyoHaywawESk7FH'
        }

        fetchToSocialLogin(sendData, 'facebook')
    }

    return (
        <div className="auth__social-networks">
            <div className="auth__social-networks__inner">
                <button onClick={() => loginGoogle()}
                        className="smm-link auth__social-networks-link">
                    <div className="auth__social-networks-item">
                        <svg width="20" height="20" viewBox="0 0 20 20"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_29_2157)">
                                <mask id="mask0_29_2157"
                                      style={{maskType: "luminance"}}
                                      maskUnits="userSpaceOnUse" x="0"
                                      y="0" width="20" height="20">
                                    <path
                                        d="M0 1.90735e-06H20V20H0V1.90735e-06Z"
                                        fill="white"/>
                                </mask>
                                <g mask="url(#mask0_29_2157)">
                                    <path fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M10 18.8281C5.12435 18.8281 1.17187 14.8757 1.17187 10C1.17187 5.12439 5.12435 1.17188 10 1.17188C12.2272 1.17188 14.261 1.99597 15.8144 3.35695L14.1522 5.01921C13.0276 4.08093 11.5794 3.51563 10 3.51563C6.41878 3.51563 3.51562 6.41878 3.51562 10C3.51562 13.5812 6.41878 16.4844 10 16.4844C12.8882 16.4844 15.3333 14.5964 16.173 11.9891L16.4195 11.2236H10.5859V8.87985H18.7578C18.8042 9.24647 18.8281 9.62031 18.8281 10C18.8281 14.8757 14.8757 18.8281 10 18.8281ZM0 10C0 15.5229 4.47714 20 10 20C15.5229 20 20 15.5229 20 10C20 9.38189 19.9439 8.77638 19.8362 8.1884L19.7483 7.70797H9.41406V12.3955H14.7428C13.8668 14.1265 12.0714 15.3125 10 15.3125C7.06599 15.3125 4.6875 12.934 4.6875 10C4.6875 7.06599 7.06599 4.6875 10 4.6875C11.4671 4.6875 12.7945 5.2815 13.7565 6.24351L14.1708 6.65781L17.4854 3.34329L17.0711 2.92897C15.2621 1.11994 12.7613 1.90735e-06 10 1.90735e-06C4.47714 1.90735e-06 0 4.47718 0 10Z"
                                          fill="#08D565"/>
                                    <path fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M16.0804 17.1049L13.5552 14.5798L12.7266 15.4084L15.2518 17.9336L16.0804 17.1049Z"
                                          fill="#08D565"/>
                                    <path fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M5.42223 6.4448L2.897 3.91956L2.06836 4.7482L4.59359 7.27344L5.42223 6.4448Z"
                                          fill="#08D565"/>
                                    <path fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M4.59262 12.7282L2.06738 15.2534L2.89602 16.082L5.42126 13.5568L4.59262 12.7282Z"
                                          fill="#08D565"/>
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_29_2157">
                                    <rect width="20" height="20"
                                          fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <p className="auth__social-networks-item-text">
                            Google</p>
                    </div>
                </button>
                <FacebookLogin appId="730495552272731"
                               callback={onSuccessFacebookLogin}
                               cssClass="smm-link auth__social-networks-link"
                               render={renderProps => (
                                   <button onClick={renderProps.onClick}
                                           className={"smm-link auth__social-networks-link"}>
                                       <div
                                           className="auth__social-networks-item">
                                           <svg width="20" height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                               <g clipPath="url(#clip0_29_2175)">
                                                   <path
                                                       d="M11.9624 20H6.88416V12.9298H4.72656V8.24219H6.88416V5.0975C6.88416 2.28668 9.17084 0 11.9815 0H15.2921V5.07843H12.7792C12.3289 5.07843 11.9624 5.44479 11.9624 5.89508V8.24203H14.12V12.9298H11.9624V20ZM8.05603 18.8281H10.7906V11.758H12.9482V9.41406H10.7906V5.89523C10.7906 4.79858 11.6827 3.90656 12.7792 3.90656H14.1202V1.17188H11.9815C9.81705 1.17188 8.05603 2.93289 8.05603 5.09735V9.41391H5.89844V11.758H8.05603V18.8281Z"
                                                       fill="#08D565"/>
                                               </g>
                                               <defs>
                                                   <clipPath
                                                       id="clip0_29_2175">
                                                       <rect width="20"
                                                             height="20"
                                                             fill="white"/>
                                                   </clipPath>
                                               </defs>
                                           </svg>
                                           <p className="auth__social-networks-item-text">
                                               Facebook</p>
                                       </div>
                                   </button>
                               )}
                />
            </div>
        </div>)
}

export default AuthSocialNetworks;