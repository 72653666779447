import {useNavigate} from "react-router-dom";
import userSmall from "../../../assets/media/user-small.svg";
import selectArrow from "../../../assets/media/inputs/select-arrow.svg";
import {useRef} from "react";
import {useDispatch} from "react-redux";
import {logout} from "../../pages/authPages/AuthSlice";
import {setNotification} from "../notification/NotificationSlice";


const PanelHeader = ({isActiveMenu, onClickBurger}) => {

    const dispatch = useDispatch();
    const arrowRef = useRef(null);
    const navigate = useNavigate();

    const onClickArrow = () => {
        arrowRef.current.classList.toggle("smm-panel-content__header-user-arrow-active");
        arrowRef.current.nextElementSibling.classList.toggle("smm-panel-content__header-sign-out-active");
    }

    const onLogout = () => {
        dispatch(setNotification({'type': 'success', 'text': 'Successfully logged out'}));
        dispatch(logout());
        navigate('/');
    }

    const getHeaderClassName = () => {
        const def = "smm-panel-content__header-burger"
        return isActiveMenu ? `${def} header__burger-active` : def;
    }

    return (
        <div className="smm-panel-content__header">
            <div className="smm-panel-content__wrapper smm-panel-content__header-wrapper">
                <div className={getHeaderClassName()} onClick={onClickBurger}>
                    <span className="header__burger-bar"></span>
                </div>
                <div className="smm-panel-content__header-user">
                        <div className="smm-panel-content__header-user-profile smm-link" onClick={onClickArrow}>
                            <img src={userSmall}
                                 alt="profile"/>
                        </div>
                    <div className="smm-panel-content__header-user-arrow"
                         ref={arrowRef}
                        onClick={onClickArrow}>
                        <img src={selectArrow}
                             alt="arrow"/>
                    </div>
                    <div className="smm-panel-content__header-sign-out">
                        <div onClick={onLogout} className="smm-link sign-out-link">
                            <span>Sign out</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PanelHeader;