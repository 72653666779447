import PanelMenu from "../panelMenu/PanelMenu";
import PanelHeader from "../panelHeader/PanelHeader";
import PanelFooter from "../panelFooter/PanelFooter";
import checkViewport from "../../../utils/checkViewport";
import {useState} from "react";


const PanelWrapper = (props) => {

    const [isActiveMenu, setIsActiveMenu] = useState(false);

    const onClickBurger = () => {
        document.body.scrollTo({
                    top: 0,
                    behavior: "smooth"
        });
        checkViewport(800, openMobileMenu);
    }

    const openMobileMenu = () => {
        setIsActiveMenu(state => !state);
    }



    if (isActiveMenu){
        document.body.classList.add('scroll-hide');
    } else {
        document.body.classList.remove('scroll-hide');
    }

    return (
        <div className="panel-wrapper">
            <PanelMenu isActiveMenu={isActiveMenu} onClickBurger={onClickBurger}/>
            <main className="main smm-panel-content history-wrapper">
                <PanelHeader isActiveMenu={isActiveMenu}  onClickBurger={onClickBurger}/>
                {props.children}
                <PanelFooter/>
            </main>
        </div>
    )
}

export default PanelWrapper;