import AuthWrapper from "../../../parts/authWrapper/AuthWrapper";
import ForgotPasswordForm from "./forgotPasswordForm/ForgotPasswordForm";


const ForgotPasswordPage = () => {

    return (
        <AuthWrapper>
            <h2 className="auth-form__title auth-form__title-reset">Password
                reset</h2>
            <p className="auth-form__text">Forgotten your password?
                Enter your email address below, and we'll email instructions
                for setting a new one.</p>
            <ForgotPasswordForm/>
        </AuthWrapper>
    )
}

export default ForgotPasswordPage;