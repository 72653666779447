import greenArrow
    from "../../../../assets/media/additional/green-arrow-20.svg";
import facebook
    from "../../../../assets/media/available_services/facebook.svg";
import instagram
    from "../../../../assets/media/available_services/instagram.svg";
import telegram
    from "../../../../assets/media/available_services/telegram.svg";
import tiktok from "../../../../assets/media/available_services/tiktok.svg";
import youtube
    from "../../../../assets/media/available_services/youtube.svg";
import twitter
    from "../../../../assets/media/available_services/twitter.svg";
import Tab from "../../../parts/tab/Tab";

const SmmMainServices = () => {

    const items = [
        {
            id: "facebook",
            headerContent: () => (
                <>
                    <div className="smm-main-services__content-header__wrap">
                        <div
                            className="smm-main-services__content-header__icon-wrap">
                            <img
                                className="smm-main-services__content-header__icon"
                                src={facebook}
                                alt="facebook"/>
                        </div>
                        <h4 className="smm-main-services__content-header__title">
                            Facebook SMM Panel</h4>
                    </div>
                    <div
                        className="smm-main-services__content-header__arrow-wrap">
                        <img
                            src={greenArrow}
                            alt="arrow"
                            className="smm-main-services__content-header__arrow"/>
                    </div>
                </>
            ),
            bodyContent: () => (
                <>
                    <p className="smm-main-services__content-tab__text">
                        More than just a social network, the giant
                        Facebook has become a common space for all
                        Internet users. Not only does it connect
                        individuals to each other, but it also
                        connects
                        different groups and different companies to
                        each other. It is therefore undoubtedly one
                        of
                        the biggest opportunities to position and
                        advertise. Facebook is a kind of big market
                        in
                        which you can expose your products and
                        reach a
                        maximum of potential customers.However, it
                        is
                        not enough to launch a publication on
                        Facebook
                        to attract all the people you want. On the
                        contrary, you have to work on the
                        publications
                        so that they meet the right people likely
                        to be
                        interested in your product or service. And,
                        to
                        succeed in this titanic work of content and
                        marketing strategy, it is important to use
                        an
                        SMM panel for Facebook or a special social
                        network marketing service. It is a set of
                        marketing practices that adapt to the
                        company
                        in order to allow it to have more views,
                        more
                        comments or even more subscribers when
                        dealing
                        with a Facebook page.If you want to have
                        quality services on Facebook at low cost,
                        epromosmm.com is the SMM provider you
                        need.
                        Our teams will help you set up a Facebook
                        page
                        that will not go unnoticed by Internet
                        users.
                        We help you with all the actions and we
                        allow
                        you to reach the target populations in a
                        short
                        period of time.</p>
                    <h5 className="smm-main-services__content-tab__title">
                        Facebook business strategy</h5>
                    <p className="smm-main-services__content-tab__text">
                        To
                        help you succeed in all your marketing
                        campaigns on Facebook, we offer you updated
                        tools, and we help you prepare content
                        adapted
                        to all the age groups you target on
                        Facebook.
                        We also advise you in the creation of
                        groups,
                        polls and in all the actions you will take
                        to
                        get known quickly. You will succeed in
                        transforming likes into shares, followers
                        and
                        customers.</p>
                </>
            )
        },
        {
            id: "instagram",
            headerContent: () => (
                <>
                    <div className="smm-main-services__content-header__wrap">
                        <div
                            className="smm-main-services__content-header__icon-wrap">
                            <img
                                className="smm-main-services__content-header__icon"
                                src={instagram}
                                alt="instagram"/>
                        </div>
                        <h4 className="smm-main-services__content-header__title">
                            Instagram SMM Panel</h4>
                    </div>
                    <div
                        className="smm-main-services__content-header__arrow-wrap">
                        <img
                            src={greenArrow}
                            alt="arrow"
                            className="smm-main-services__content-header__arrow"/>
                    </div>
                </>
            ),
            bodyContent: () => (
                <>
                    <p className="smm-main-services__content-tab__text">
                        Since its founding, the social network
                        Instagram has captured the hearts of more
                        than 30 million users and the number is
                        still rising. More and more people are
                        enjoying sharing their photos on Instagram,
                        and especially looking at the photos of
                        other users. Instagram has thus aroused the
                        curiosity of several companies that saw in
                        the number of users a potential target, and
                        why not potential customers.

                        Very quickly, many marketing actions were
                        designed to promote companies on Instagram.
                        However, the observation is that not all
                        companies manage to position themselves at
                        the top of the list on the social network.
                        The competition is tough indeed, and it
                        takes well-arranged marketing actions to
                        manage to capture the attention of users
                        and turn them into followers, then into
                        customers. And that's what the SMM panel
                        for Instagram is all about. You still need
                        to choose the right SMM panel that will be
                        able to adapt to your needs by allowing you
                        to have greater visibility on
                        Instagram.</p>
                    <h5 className="smm-main-services__content-tab__title">
                        Instagram business strategy</h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        With its experience in digital marketing,
                        the epromosmm.com platform has provided
                        services tailored to all types of
                        businesses to help them position themselves
                        on Instagram.
                    </p>
                    <p className="smm-main-services__content-tab__text">
                        Once on the platform, you have the freedom
                        to choose the package that suits you
                        depending on the goals you have set. The
                        platform offers services to get more views
                        on Instagram as well as likes and
                        followers.</p>
                </>
            )
        },
        {
            id: "tiktok",
            headerContent: () => (
                <>
                    <div className="smm-main-services__content-header__wrap">
                        <div
                            className="smm-main-services__content-header__icon-wrap">
                            <img
                                className="smm-main-services__content-header__icon"
                                src={tiktok}
                                alt="tiktok"/>
                        </div>
                        <h4 className="smm-main-services__content-header__title">
                            TikTok SMM Panel</h4>
                    </div>
                    <div className="smm-main-services__content-header__arrow-wrap">
                        <img
                            src={greenArrow}
                            alt="arrow"
                            className="smm-main-services__content-header__arrow"/>
                    </div>
                </>
            ),
            bodyContent: () => (
                <>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        TikTok is one of the newest social networks
                        that are successful all over the world,
                        especially among young people. The network
                        now has more than 800 million active users,
                        a figure that does not leave brand owners
                        unmoved.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        Indeed, the presence of these 800 million
                        users on the network is a godsend for
                        companies that want to make themselves
                        known. For this, ways and means have been
                        used by companies to attract the attention
                        of Internet users, to push them to be
                        interested in the contents, and especially
                        to convert them into definitive customers
                        and followers.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        To achieve these goals, companies rely on
                        all kinds of marketing actions with SMM
                        services. These services are adapted to the
                        customers that the company is targeting and
                        the publications are adjusted to create the
                        maximum number of likes and views.
                    </p>
                    <p className="smm-main-services__content-tab__text">
                        If you are looking for an SMM panel for
                        TikTok, the provider epromosmm.com can
                        meet all your needs.
                    </p>
                    <h5 className="smm-main-services__content-tab__title">
                        Tiktok business strategy</h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        Beyond the marketing contribution that will
                        allow you to attract the maximum number of
                        video players and members, we offer you one
                        of the cheapest SMM panels for TikTok in
                        the world.
                    </p>
                    <p className="smm-main-services__content-tab__text">
                        We also help you design your videos from 15
                        seconds to 180 seconds maximum.</p>
                </>
            )
        },
        {
            id: "youtube",
            headerContent: () => (
                <>
                    <div className="smm-main-services__content-header__wrap">
                        <div
                            className="smm-main-services__content-header__icon-wrap">
                            <img
                                className="smm-main-services__content-header__icon"
                                src={youtube}
                                alt="youtube"/>
                        </div>
                        <h4 className="smm-main-services__content-header__title">
                            YouTube SMM Panel</h4>
                    </div>
                    <div className="smm-main-services__content-header__arrow-wrap">
                        <img
                            src={greenArrow}
                            alt="arrow"
                            className="smm-main-services__content-header__arrow"/>
                    </div>
                </>
            ),
            bodyContent: () => (
                <>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                                    SMM services for YouTube are offered at
                                    unbeatable prices in the market. We are
                                    committed to helping companies increase
                                    their marketing efforts by helping you
                                    increase your online presence. The SMM
                                    YouTube services offered by epromosmm.com
                                    will allow you to buy subscribers for your
                                    YouTube channel as well as likes and views.
                                    The fact is that the more views or
                                    subscribers your YouTube channel has, the
                                    more trust it inspires. That's why it's
                                    important to invest in buying extra likes
                                    and views from epromosmm.com.
                                </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                                    We offer solutions to get your subscribers
                                    to spend as much time as possible on your
                                    channel and to like or comment on the
                                    videos. You can then use this data to
                                    modify your product or service if
                                    necessary. We also have several easy to use
                                    payment methods. So no matter what country
                                    you are in, you can easily make your
                                    purchase of SMM services suitable for
                                    YouTube. Our SMM panel is regularly updated
                                    and we guarantee fast delivery of your
                                    orders.
                                </p>
                    <p className="smm-main-services__content-tab__text">
                                    We also provide customer service that is
                                    available Monday through Friday.</p>
                    <h5 className="smm-main-services__content-tab__title">
                                    Youtube business strategy</h5>
                    <p className="smm-main-services__content-tab__text">
                                    The epromosmm.com platform is experienced
                                    in social media marketing services. It
                                    offers all its clients an SMM panel adapted
                                    to YouTube in order to gain maximum views,
                                    maximum likes and comments at a very
                                    reasonable price. In addition, the platform
                                    also offers services to get more
                                    subscribers on the YouTube channel.</p>
                </>
            )
        },
        {
            id: "telegram",
            headerContent: () => (
                <>
                    <div className="smm-main-services__content-header__wrap">
                        <div
                            className="smm-main-services__content-header__icon-wrap">
                            <img
                                className="smm-main-services__content-header__icon"
                                src={telegram}
                                alt="telegram"/>
                        </div>
                        <h4 className="smm-main-services__content-header__title">
                            Telegram SMM Panel</h4>
                    </div>
                    <div className="smm-main-services__content-header__arrow-wrap">
                        <img
                            src={greenArrow}
                            alt="arrow"
                            className="smm-main-services__content-header__arrow"/>
                    </div>
                </>
            ),
            bodyContent: () => (
                <>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                                    The Telegram application has been used a
                                    lot by internet users for conversations and
                                    information sharing for a few years now.
                                    The application has the same operating
                                    process as whatsapp and allows
                                    communication via the internet without
                                    having to pay anything. Companies have
                                    started to be interested in this network
                                    because of the number of users that is
                                    constantly increasing. There are no less
                                    than 100 million users of the application,
                                    which is a good commercial potential.
                                </p>
                    <p className="smm-main-services__content-tab__text">
                                    Indeed, the communication on the
                                    application is fluid and easy, and the
                                    information found on it is of various
                                    kinds. Companies can even get likes and
                                    views of their publications, which will
                                    increase their notoriety. And, to achieve
                                    this, you definitely need a good SMM panel
                                    for Telegram.</p>
                    <h5 className="smm-main-services__content-tab__title">
                                    Telegram business strategy</h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                                    We have several services that can increase
                                    your reputation on social networks,
                                    especially on Telegram. epromosmm.com
                                    takes into account the budget you allocate
                                    to marketing and we offer Telegram services
                                    at prices that defy competition. We are
                                    among the best providers of SMM Telegram
                                    services on the market by the quality of
                                    our services, but also by our close
                                    support.
                                </p>
                    <p className="smm-main-services__content-tab__text">
                                    Our teams accompany you throughout the
                                    marketing process and help you personalize
                                    all your publications and marketing
                                    actions. You can buy views or likes on our
                                    platform and you have the possibility to
                                    increase your notoriety and your potential
                                    customers.
                                </p>
                </>
            )
        },
        {
            id: "twitter",
            headerContent: () => (
                <>
                    <div className="smm-main-services__content-header__wrap">
                        <div
                            className="smm-main-services__content-header__icon-wrap">
                            <img
                                className="smm-main-services__content-header__icon"
                                src={twitter}
                                alt="twitter"/>
                        </div>
                        <h4 className="smm-main-services__content-header__title">
                            Twitter SMM Panel</h4>
                    </div>
                    <div className="smm-main-services__content-header__arrow-wrap">
                        <img
                            src={greenArrow}
                            alt="arrow"
                            className="smm-main-services__content-header__arrow"/>
                    </div>
                </>
            ),
            bodyContent: () => (
                <>
                    <p className="smm-main-services__content-tab__text">
                        More than just a social network, the giant
                                    Facebook has become a common space for all
                                    Internet users. Not only does it connect
                                    individuals to each other, but it also
                                    connects
                                    different groups and different companies to
                                    each other. It is therefore undoubtedly one
                                    of
                                    the biggest opportunities to position and
                                    advertise. Facebook is a kind of big market
                                    in
                                    which you can expose your products and
                                    reach a
                                    maximum of potential customers.However, it
                                    is
                                    not enough to launch a publication on
                                    Facebook
                                    to attract all the people you want. On the
                                    contrary, you have to work on the
                                    publications
                                    so that they meet the right people likely
                                    to be
                                    interested in your product or service. And,
                                    to
                                    succeed in this titanic work of content and
                                    marketing strategy, it is important to use
                                    an
                                    SMM panel for Facebook or a special social
                                    network marketing service. It is a set of
                                    marketing practices that adapt to the
                                    company
                                    in order to allow it to have more views,
                                    more
                                    comments or even more subscribers when
                                    dealing
                                    with a Facebook page.If you want to have
                                    quality services on Facebook at low cost,
                                    epromosmm.com is the SMM provider you
                                    need.
                                    Our teams will help you set up a Facebook
                                    page
                                    that will not go unnoticed by Internet
                                    users.
                                    We help you with all the actions and we
                                    allow
                                    you to reach the target populations in a
                                    short
                                    period of time.</p>
                    <h5 className="smm-main-services__content-tab__title">
                                    Twitter business strategy</h5>
                    <p className="smm-main-services__content-tab__text">
                                    To
                                    help you succeed in all your marketing
                                    campaigns on Facebook, we offer you updated
                                    tools, and we help you prepare content
                                    adapted
                                    to all the age groups you target on
                                    Facebook.
                                    We also advise you in the creation of
                                    groups,
                                    polls and in all the actions you will take
                                    to
                                    get known quickly. You will succeed in
                                    transforming likes into shares, followers
                                    and
                                    customers.</p>
                </>
            )
        },
    ]

    return (
        <section className="smm-main-services">
            <div className="wrapper-content">
                <div className="smm-main-services__header">
                    <h2 className="smm-main-services__header-title">SMM panel
                        services</h2>
                    <p className="smm-main-services__header-text">We have
                        bundled
                        most of the social media SMM panel services to allow
                        users to grow their accounts. Use the cheapest SMM
                        panel for Instagram and other social media.</p>
                </div>
                <Tab items={items}
                     activeId={items[0].id}
                     customClassName="smm-main-services__content"/>
            </div>
        </section>
    )
}

export default SmmMainServices;