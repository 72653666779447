import {useState} from "react";
import HistoryItem from "../historyItem/HistoryItem";
import Spinner from "../../../parts/spinner/Spinner";
import PanelTablePagination
    from "../../../parts/panelTablePagination/PanelTablePagination";
import {useSelector} from "react-redux";
import {ordersSelect} from "../HistorySlice";


const HistoryTable = () => {

    const ordersLoading = useSelector(state => state.history.ordersLoading);

    const orders = useSelector(ordersSelect)
    const [chunks, setChunks] = useState([]);

    if (ordersLoading !== "idle") return <Spinner/>;

    const renderItems = chunks.map((chunk, id) => <HistoryItem
        key={id} {...chunk} />);

    return (
        <>
            <div className="history-content">
                <div className="smm-panel-table__wrap">
                    <table
                        className="smm-panel-content__table history-content__table">
                        <thead>
                        <tr>
                            <th className="orders-table-head-id">ID</th>
                            <th className="orders-table-head-link">Link</th>
                            <th className="orders-table-head-service">Service</th>
                            <th className="orders-table-head-quantity">Quantity</th>
                            <th className="orders-table-head-spent">Spent</th>
                            <th className="orders-table-head-date">Date</th>
                            <th className="orders-table-head-status">Status</th>
                        </tr>
                        </thead>
                        <tbody className="history-content__table-body">
                        {renderItems}
                        </tbody>
                    </table>
                </div>
            </div>
            <PanelTablePagination items={orders}
                                  setCurrentChunk={(value) => setChunks(value)}
                                  perPage={10}
            />
        </>
    )
}

export default HistoryTable;