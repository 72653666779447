import {useEffect, useRef, useState} from "react";


const Select = ({
                    className,
                    name,
                    defaultValue,
                    options,
                    onChange,
                    isSearch = false
                }) => {

    // refs of select elements
    const selectRef = useRef(null);
    const bodyRef = useRef(null);

    // state for select
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const [searchValue, setSearchValue] = useState(defaultValue.label);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        // close select on click outside
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                close();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, []);

    useEffect(() => {
        // change selected value on change default value
        setSelectedValue(defaultValue);
        if (isSearch){
            setSearchValue(defaultValue.label);
        }
    }, [defaultValue]);

    // open select and close on click it
    const open = () => {
        // changeValue(selectedValue.label);
        setIsOpen(state => !state);
    }

    // close select
    const close = () => {
        // changeValue(selectedValue.label);
        if (isSearch) {
            setIsSearching(false);
        }
        return !bodyRef.current.classList.contains("is-hidden") ? setIsOpen(false) : null;
    }

    // method for validating and setting new value
    const onSelectItem = (item) => {
        setSelectedValue(item);
        onChange(item);
        if (isSearch) {
            setIsSearching(false);
            setSearchValue(item.label);
        }
        close();
    }

    // method for set searching state
    const onSearch = (e) => {
        setIsSearching(true);
        setSearchValue(e.target.value);
    }

    // render options of select
    const renderOptions = () => {
        if (isSearching) {
            // filtering options by searching
            const filteringOptions = options.filter((item) => item.label.includes(searchValue));
            return filteringOptions.map((item, id) => (
                <div key={id}
                     className={`custom-select__body-item ${item.value === selectedValue.value ? "active" : ""}`}
                     onClick={() => onSelectItem(item)}
                >
                    {item.label}
                </div>))
        } else {
            // render all options
            return options.map((item, id) => (
                <div key={id}
                     className={`custom-select__body-item ${item.value === selectedValue.value ? "active" : ""}`}
                     onClick={() => onSelectItem(item)}
                >
                    {item.label}
                </div>))
        }
    };

    return (
        <div className={`custom-select ${className}`} ref={selectRef}>
            <input type="hidden" name={name}
                   value={selectedValue.value}
                   onChange={onChange}
            />
            <div className={`custom-select__header ${isOpen ? "is-open" : ""}`}
                 onClick={open}>

                {isSearch ?
                    <input type={"text"} onChange={onSearch}
                           value={isSearching ? searchValue : selectedValue.label}/> :
                    <span>{selectedValue.label}</span>}

                <svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_36_918)">
                        <path
                            d="M4.21953 6.06925L7.90953 2.36901C8.03036 2.24778 8.03016 2.0515 7.90891 1.93046C7.78767 1.80953 7.59128 1.80984 7.47036 1.93109L3.99999 5.41108L0.529626 1.93096C0.408689 1.80973 0.212424 1.80942 0.0911741 1.93034C0.0303931 1.99101 2.3741e-06 2.0705 2.37063e-06 2.14998C2.36716e-06 2.22926 0.03019 2.30843 0.090549 2.36899L3.78046 6.06925C3.83855 6.12764 3.91761 6.1604 3.99999 6.1604C4.08236 6.1604 4.16133 6.12754 4.21953 6.06925Z"
                            fill="#5D5E75"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_36_918">
                            <rect width="8" height="8" fill="white"
                                  transform="translate(8) rotate(90)"/>
                        </clipPath>
                    </defs>
                </svg>

            </div>
            <div
                className={`custom-select__body ${!isOpen ? "is-hidden" : ""}`}
                ref={bodyRef}
            >
                {renderOptions()}
            </div>
        </div>
    )
}

export default Select