import TicketCreate from "./ticketCreate/TicketCreate";
import TicketHistory from "./ticketHistory/TicketHistory";


const TicketsPage = () => (
    <div className="smm-panel-content__blocks">
        <div className="smm-panel-content__wrapper">
            <div className="smm-panel-content__blocks-inner">
                <TicketCreate/>
                <TicketHistory/>
            </div>
        </div>
    </div>
)

export default TicketsPage;