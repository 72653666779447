import AddFundsForm from "./addFundsForm/AddFundsForm";
import PaymentsTable from "./paymentsTable/PaymentsTable";


const PaymentsPage = () => (
    <div className="smm-panel-content__blocks">
        <div className="smm-panel-content__wrapper">
            <div className="smm-panel-content__blocks-inner">
                <div className="smm-panel-content__block-group">
                    <section className="smm-panel-content__block add-funds">
                        <div
                            className="smm-panel-content-block__wrapper add-funds__wrapper">
                            <AddFundsForm/>
                        </div>
                    </section>
                    <section className="smm-panel-content__block payments">
                        <div className="smm-panel-content-block__wrapper">
                            <PaymentsTable/>
                        </div>
                    </section>
                </div>
                <section
                    className="smm-panel-content__block payment-methods">
                    <div className="smm-panel-content-block__wrapper">
                        <h4 className="smm-panel-content-block__title">
                            Description</h4>
                        <div className="payment-methods__inner">
                            <div className="payment-methods__item">
                                <h5 className="payment-methods__item-title">
                                    Credit card</h5>
                                <p className="payment-methods__item-text">
                                    Credit Card payment is enabled for
                                    Everyone.</p>
                                <div
                                    className="payment-methods__item-bonuses">
                                    <p className="payment-methods__item-bonus">
                                        10$ Minimum Payment!</p>
                                </div>
                            </div>
                            <div className="payment-methods__item">
                                <h5 className="payment-methods__item-title">
                                    Webmoney</h5>
                                <p className="payment-methods__item-text">
                                    WebMoney payment auto enabled in
                                    the
                                    dropdown above for everyone!</p>
                                <div
                                    className="payment-methods__item-bonuses">
                                    <p className="payment-methods__item-bonus">
                                        5$ Minimum Payment!</p>
                                </div>
                            </div>
                            <div className="payment-methods__item">
                                <h5 className="payment-methods__item-title">
                                    Perfect Money</h5>
                                <p className="payment-methods__item-text">
                                    Perfect Money payment auto enabled
                                    in
                                    the dropdown above for
                                    everyone!</p>
                                <div
                                    className="payment-methods__item-bonuses">
                                    <p className="payment-methods__item-bonus">
                                        1$ Minimum Payment!</p>
                                    <p className="payment-methods__item-bonus">
                                        5% Bonus on Perfect Money
                                        Payments!</p>
                                </div>
                            </div>
                            <div className="payment-methods__item">
                                <h5 className="payment-methods__item-title">
                                    Crypto Currency</h5>
                                <p className="payment-methods__item-text">
                                    Crypto Currency payment auto
                                    enabled in
                                    the dropdown above for everyone!We
                                    are
                                    now Accepting Litecoin -
                                    BitcoinCash -
                                    Etherium - Dash and Ripples as a
                                    payment!</p>
                                <div
                                    className="payment-methods__item-bonuses">
                                    <p className="payment-methods__item-bonus">
                                        1$ Minimum Payment!</p>
                                    <p className="payment-methods__item-bonus">
                                        5% Bonus on Crypto Currency
                                        Payments!</p>
                                </div>
                            </div>
                            <div className="payment-methods__item">
                                <h5 className="payment-methods__item-title">
                                    Credit card</h5>
                                <p className="payment-methods__item-text">
                                    <a
                                        href="#" className="smm-link">Please
                                        open a ticket</a> for other
                                    Payment
                                    Methods!</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
)

export default PaymentsPage;
