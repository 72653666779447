import axios from "axios";
import Cookie from "../utils/cookie";


// create base function for HTTP Request
const request = axios.create({
    baseURL: `${process.env.REACT_APP_DOMAIN}/api/v1/`,
});

// add defaults headers to requests
request.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
request.defaults.headers.common["Content-Type"] = "application/json";

const cookie = new Cookie();

// function for fetch to refresh access token
const refreshAccessToken = async (refresh, isSocial) => {
    let url, data;

    // create info for request
    if (isSocial) {
        url = "auth/social/token/";
        data = {
            grant_type: "refresh_token",
            refresh_token: refresh,
            client_id: "dvQiB8nv7HfaBacLxgm60WQJYlXi4yMShH6Gj5Gt",
            client_secret: "gn6KZfMp92YMEEo7qj7rsjeHZZ2eE9TxxfdH0o81fOAoV4khcmJY44cRrAlPMzzT5BhedQSQ8G27Vw85MQ0CTtbYSEBPore6zjLyFnpfxf7Fv7nbIpDhqYSlZyG06rnA",
        };
    } else {
        url = "auth/login/refresh/";
        data = {refresh};
    }

    // request to refresh token
    try {
        const resp = await request.post(url, data);
        return resp.data;
    } catch (e) {
        cookie.removeCookie("access");
        cookie.removeCookie("refresh");
        cookie.removeCookie("social");
    }
};

// function for adding new action token to cookies
const retryRequest = async (refreshToken) => {

    const isSocial = cookie.getCookie("social");
    let accessToken, accessExp;

    const response = await refreshAccessToken(refreshToken, isSocial);
    if (isSocial) {
        accessToken = response.access_token;
        accessExp = response.expires_in;
        cookie.setCookie("refresh", response.refresh_token, 86400,true);
        request.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    } else {
        accessToken = response.auth.token;
        accessExp = response.auth.exp;
        request.defaults.headers.common["Authorization"] = `JWT ${accessToken}`;
    }

    cookie.setCookie("access", accessToken, accessExp, true);
}

// middleware of request to adding authorization header
request.interceptors.request.use(
    async (config) => {
        const access = cookie.getCookie("access");
        const refresh = cookie.getCookie("refresh");
        const social = cookie.getCookie("social");

        if (access && refresh && social) {
            config.headers["Authorization"] = `Bearer ${access}`;
        } else if (access && refresh) {
            config.headers["Authorization"] = `JWT ${access}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

// middleware of response to refresh access token and retry
request.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            const refreshToken = cookie.getCookie("refresh");
            if (refreshToken) {
                originalRequest._retry = true;
                await retryRequest(refreshToken);
                return request(originalRequest);
            }
        }
        return Promise.reject(error);
    }
)

export default request;