import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import useContactServices from "../../../../services/ContactServices";
import {useState} from "react";
import setErrorInput from "../../../../utils/setErrorInput";
import {useDispatch} from "react-redux";
import {setNotification} from "../../../parts/notification/NotificationSlice";


const ContactForm = () => {
    const dispatch = useDispatch();

    const {contactUs} = useContactServices();

    const [formSubmit, setFormSubmit] = useState(false);

    const initialState = {
        name: "",
        email: "",
        phone: "",
        message: ""
    }

    const ContactSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .min(2, "Name must be greater than 2 symbols")
            .max(200, "Name must be less than 200 symbols!")
            .required("Name is required"),
        email: Yup.string()
            .trim()
            .email("Please, write valid email address")
            .required("Email is required"),
        phone: Yup.string()
            .trim()
            .min(5, "Phone length must be greater than 5 symbols")
            .max(16, "Phone length must be less than 15 symbols")
            .required("Phone is required"),
        message: Yup.string()
            .trim()
            .min(5, "Message must be greater than 5 symbols")
            .required("Message is required")
    });

    const validateForm = (values) => {
        const errors = {};

        const regex = new RegExp("[^0-9+()-]");

        if (regex.test(values["phone"])) {
            errors["phone"] = "Phone haven't to be letters or special symbols";
        }

        return errors;
    }

    const onSubmitForm = (values, {resetForm}) => {
        setFormSubmit(true);
        contactUs(values)
            .then(response => {
                setFormSubmit(false);
                dispatch(setNotification(response.data.notification));
                resetForm();
            })
            .catch(error => {
                const notification = error?.error?.data?.notification;
                if (notification) {
                    dispatch(setNotification(notification));
                } else {
                    dispatch(setNotification({
                        "type": "error",
                        "text": "Server error"
                    }))
                }
            })
    }

    return (
        <Formik initialValues={initialState}
                validationSchema={ContactSchema}
                validate={validateForm}
                onSubmit={onSubmitForm}>
            {props => (<Form className="auth-form auth-form__sign-in"
                             method="post">
                    <div className="auth-form__input-inner auth-form__sign-in-input-inner">
                        <div className="auth-form__input-item">
                            <div className={
                                props.errors.name ?
                                    "auth-form__input-wrap auth-form__input-name error-input" :
                                    "auth-form__input-wrap auth-form__input-name"
                            }>
                                <Field type="text" required
                                       className={setErrorInput(props.errors.name)}
                                       placeholder="Enter your name"
                                       id="name"
                                       name="name"/>
                            </div>
                            <ErrorMessage name="name" className="error-label"
                                          component="p"/>
                        </div>
                        <div className="auth-form__input-item">
                            <div className={
                                props.errors.email ?
                                    "auth-form__input-wrap auth-form__input-email error-input" :
                                    "auth-form__input-wrap auth-form__input-email"
                            }>
                                <Field type="email"
                                       required=""
                                       className={setErrorInput(props.errors.email)}
                                       placeholder="Enter your email"
                                       id="email"
                                       name="email"/>
                            </div>
                            <ErrorMessage name="email" className="error-label"
                                          component="p"/>
                        </div>
                        <div className="auth-form__input-item">
                            <div className={
                                props.errors.phone ?
                                    "auth-form__input-wrap auth-form__input-phone error-input" :
                                    "auth-form__input-wrap auth-form__input-phone"
                            }>
                                <Field type="text"
                                       className={setErrorInput(props.errors.phone)}
                                       placeholder="Enter your phone"
                                       id="phone"
                                       name="phone"/>
                            </div>
                            <ErrorMessage name="phone" className="error-label"
                                          component="p"/>
                        </div>
                        <div className="auth-form__input-item">
                            <Field as={"textarea"}
                                   className={
                                       props.errors.message ?
                                           "smm-input auth-form__input auth-form__input-message error-input" :
                                           "smm-input auth-form__input auth-form__input-message"
                                   }
                                   placeholder="Enter your message"
                                   id="message"
                                   name="message"/>
                            <ErrorMessage name="message"
                                          className="error-label"
                                          component="p"/>
                        </div>
                    </div>
                    <button type="submit"
                            className={
                                formSubmit ?
                                    "smm-btn__green auth-form__btn btn-disabled" :
                                    "smm-btn__green auth-form__btn"
                            }
                            disabled={formSubmit}>
                        <span>Send</span>
                        <svg width="29" height="30"
                             viewBox="0 0 29 30"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.0806 20.3033L18.8971 11.4868L18.7367 18.0783C18.7283 18.4235 19.0012 18.7101 19.3463 18.7185C19.6911 18.7269 19.9779 18.4542 19.9865 18.1088L20.1695 10.5808C20.1692 10.2469 20.0395 9.9329 19.8033 9.69669C19.5673 9.46069 19.2531 9.33076 18.9042 9.33098L11.3912 9.51394C11.2241 9.51792 11.0741 9.58708 10.9645 9.69668C10.8478 9.81336 10.7771 9.97599 10.7815 10.1541C10.7899 10.4993 11.0763 10.7719 11.4214 10.7635L18.0272 10.589L9.19671 19.4194C8.95276 19.6634 8.95276 20.0593 9.19671 20.3033C9.44066 20.5472 9.83664 20.5472 10.0806 20.3033Z"
                                fill="white"></path>
                        </svg>
                    </button>
                </Form>
            )}
        </Formik>
    )
}

export default ContactForm;
