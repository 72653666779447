/**
 * Checks if the current viewport width is less than or equal to the specified viewport width.
 * If the condition is true, it will execute the provided function.
 *
 * @param {number} viewport - The maximum viewport width to compare with the current viewport width.
 * @param {Function} func - The function to execute if the condition is true.
 * @return {null} - Returns null if the condition is false or the provided function is not executed.
 */
export default function checkViewport(viewport, func) {
        return (window.innerWidth <= viewport) ? func() : null;
}
