import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import useTicketsServices from "../../../services/TicketsServices";


const initialState = {
    tickets: [],
    ticketsLoading: "idle",
    ticketsCreating: "idle",
    ticketsSearch: "",
}

export const getTickets = createAsyncThunk(
    "tickets/getTickets", async (data, {rejectWithValue}) => {
        const {getTickets} = useTicketsServices()
        try {
            return await getTickets();
        } catch (e) {
            return rejectWithValue(e.response);
        }
    }
)

export const postNewTicket = createAsyncThunk(
    "tickets/postNewTicket", async (data, {rejectWithValue}) => {
        const {postNewTicket} = useTicketsServices();
        try {
            return await postNewTicket(data);
        }catch (e){
            return rejectWithValue(e.response);
        }
    }
)

const ticketSlice = createSlice({
    name: "tickets",
    initialState,
    reducers: {
        onTicketsSearch: (state, action) => {
            state.ticketsSearch = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(getTickets.pending, state => {
            state.ticketsLoading = "loading";
        })
        builder.addCase(getTickets.fulfilled, (state, action) => {
            state.ticketsLoading = "idle";
            state.tickets = action.payload.data;
        })
        builder.addCase(getTickets.rejected, (state, action) => {
            state.ticketsLoading = "error";
        })
        builder.addCase(postNewTicket.pending, state => {
            state.ticketsLoading = "loading";
        })
        builder.addCase(postNewTicket.fulfilled, (state, action) => {
            state.ticketsLoading = "idle";
            state.tickets.push(action.payload.data.ticket);
        })
        builder.addCase(postNewTicket.rejected, (state, action) => {
            state.ticketsLoading = "idle";
        })
    }
})

const {reducer, actions} = ticketSlice;

export const {
    onTicketsSearch
} = actions;

// selector for tickets with searching
export const ticketsSelector = state => {
    const {tickets, ticketsSearch} = state.tickets;
    return ticketsSearch ? tickets.filter(item => item.type.toLowerCase().includes(
        ticketsSearch.toLowerCase())) : tickets;
}

export default reducer