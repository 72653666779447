/**
 * Returns the class name for the submit button based on the provided default class and flag.
 *
 * @param {string} defaultClass - The default class name for the button.
 * @param {boolean} flag - The flag indicating whether the button should be disabled.
 * @return {string} - The class name for the submit button.
 */
export default function getButtonSubmitClass(defaultClass, flag){
    if (flag){
        return defaultClass + " btn-disabled";
    } else {
        return defaultClass;
    }
}