/**
 * Parses the given date and returns it in the specified format.
 *
 * @param {string|number|Date} date - The date to be parsed.
 * @param {boolean} [isDatetime=false] - Indicates whether to include time in the output.
 * @return {string} - The parsed date in the specified format.
 */
const useParseDate = (date, isDatetime = false) => {

    function listDate(date) {
        const parseDate = new Date(date);
        return ('0' + parseDate.getDate()).slice(-2) + '.' + ('0' + (parseDate.getMonth() + 1)).slice(-2) + '.' + parseDate.getFullYear();
    }

    function listTime(date) {
        const parseDate = new Date(date);
        return ('0' + parseDate.getHours()).slice(-2) + ':' + ('0' + parseDate.getMinutes()).slice(-2) + ':' + ('0' + parseDate.getSeconds()).slice(-2);
    }

    const finallyDate = listDate(date);
    const finallyTime = listTime(date);

    return isDatetime ? `${finallyDate} ${finallyTime}` : finallyDate;
}

export default useParseDate;