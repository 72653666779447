import {createPortal} from 'react-dom';
import NotificationItem from "./notificationItem/NotificationItem";
import {useSelector} from "react-redux";
import {selectNotifications} from "./NotificationSlice";

const Notification = () => {

    const notificationsWrapper = document.getElementById('notifications');

    const {notifications} = useSelector(selectNotifications);

    const renderNotifications = () => {
        // Renders the notifications.
        return notifications.map((item) => <NotificationItem key={item.id}
                                                             id={item.id}
                                                             type={item.type}
                                                             text={item.text}/>);
    }

    return createPortal(renderNotifications(), notificationsWrapper);
}

export default Notification;