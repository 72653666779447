/**
 * Function to check if an object is empty.
 *
 * @param {Object} objectName - The object to be checked.
 * @returns {boolean} - Returns true if the object is empty, otherwise returns false.
 */
const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

export default isObjectEmpty;