import {Navigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import useUserServices from "../../../../services/UserServices";
import {useDispatch} from "react-redux";
import {setNewEmail} from "../UserSlice";
import Preloader from "../../../parts/preloader/Preloader";
import {setNotification} from "../../../parts/notification/NotificationSlice";


const SettingsChangeEmailPage = () => {

    const dispatch = useDispatch();
    const [isFetching, setIsFetching] = useState(true);
    const {uidb64, token, email} = useParams();
    const {userChangeEmailConfirm} = useUserServices();

    useEffect(
        /*Function that confirms the change of email for a user*/
        () => {
            userChangeEmailConfirm(uidb64, token, email)
                .then(response => {
                    setIsFetching(false);
                    dispatch(setNewEmail(response.data.email));
                    dispatch(setNotification(response.data.notification));
                })
                .catch(error => {
                    setIsFetching(false);
                    dispatch(setNotification(error.response.data.notification));
                });
        }, []);

    if (isFetching) {
        return <Preloader/>
    }

    return <Navigate to="/settings/"/>;
}

export default SettingsChangeEmailPage;