import {NavLink} from "react-router-dom";
import parse from 'html-react-parser';
import useParseDate from "../../../../../hooks/dateParse.hook";


const PostItem = ({slug, title, image, content, created_at}) => {

    const createdAt = useParseDate(created_at, true);

    return (
        <div className="blog-list__block">
            <div className="blog-list__block-wrap">
                <NavLink to={`/blog/${slug}/`} className="smm-link">
                    <div className="blog-list__block-img-wrap">
                        <img src={image}
                             alt="news-image"/>
                    </div>
                </NavLink>
                <div className="blog-list__block-content">
                    <p className="blog-list__block-content-date">{createdAt}</p>
                    <h4 className="blog-list__block-content-title">{title}</h4>
                    <div className="blog-list__block-content-text-wrap">
                        <div className="blog-list__block-content-text">{parse(content)}</div>
                    </div>
                    <NavLink to={`/blog/${slug}/`} className="smm-link blog-list__block-link">
                        <span>Read more</span>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default PostItem;