import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import setErrorInput from "../../../../../utils/setErrorInput";
import useAuthServices from "../../../../../services/AuthServices";
import {useState} from "react";
import getButtonSubmitClass from "../../../../../utils/getButtonSubmitClass";
import {NavLink, useNavigate} from "react-router-dom";
import PasswordInput from "../../../../parts/passwordInput/PasswordInput";
import {useDispatch} from "react-redux";
import {
    setNotification
} from "../../../../parts/notification/NotificationSlice";


const SignUpForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialValues = {
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        password_confirmation: "",
        checkbox: false
    };

    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$");

    const [formSubmit, setFormSubmit] = useState(false);
    const {register} = useAuthServices();

    const registerSchema = Yup.object().shape({
        email: Yup.string()
            .email("Email is not valid")
            .trim()
            .required("Email is required"),
        first_name: Yup.string()
            .trim()
            .min(2, "First name must be at least 2 characters")
            .max(25, "First name must be at most 25 characters")
            .required("First name is required"),
        last_name: Yup.string()
            .trim()
            .min(2, "Last name must be at least 2 characters")
            .max(50, "Last name must be at most 50 characters")
            .required("Last name is required"),
        password: Yup.string()
            .trim()
            .matches(
                passwordRegex,
                "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number")
            .required("Password is required"),
        password_confirmation: Yup.string()
            .trim()
            .oneOf([Yup.ref('password')], "Passwords don't match, please try again")
            .required("Password Confirmation is required"),
        checkbox: Yup.boolean()
            .required("You should accept this point")
            .isTrue("You should accept this point")
    })

    const onSubmitForm = (values) => {
        delete values["checkbox"];
        setFormSubmit(true);
        register(values)
            .then(onSuccessForm)
            .catch(onErrorForm);
    }

    const onSuccessForm = (response) => {
        setFormSubmit(false);
        dispatch(setNotification(response.data.notification));
        navigate("/");
    }

    const onErrorForm = (error) => {
        setFormSubmit(false);
        const notification = error?.response?.data?.notification;
        if (notification) {
            dispatch(setNotification(notification));
        } else {
            dispatch(setNotification({
                "type": "error",
                "text": "Something went wrong"
            }))
        }
    }

    return (
        <Formik initialValues={initialValues}
                validationSchema={registerSchema}
                onSubmit={onSubmitForm}>
            {props => (
                <Form className="auth-form  auth-form__sign-in" method="post">
                    <div
                        className="auth-form__input-inner auth-form__sign-in-input-inner">
                        <div className="auth-form__input-item">
                            <label className="auth-form__label"
                                   htmlFor="email">Email</label>
                            <div className={
                                props.errors.email ?
                                    "auth-form__input-wrap auth-form__input-email error-input" :
                                    "auth-form__input-wrap auth-form__input-email"
                            }>
                                <Field type="email" required
                                       className={setErrorInput(props.errors.email)}
                                       placeholder="Email" id="email"
                                       name="email"/>
                            </div>
                            <ErrorMessage name="email" className="error-label"
                                          component="p"/>
                        </div>
                        <div className="auth-form__input-item">
                            <label className="auth-form__label"
                                   htmlFor="first_name">First name</label>
                            <div className={
                                props.errors.first_name ?
                                    "auth-form__input-wrap auth-form__input-name error-input" :
                                    "auth-form__input-wrap auth-form__input-name"
                            }>
                                <Field type="text" required
                                       className={setErrorInput(props.errors.first_name)}
                                       placeholder="First name" id="first_name"
                                       name="first_name"/>
                            </div>
                            <ErrorMessage name="first_name"
                                          className="error-label"
                                          component="p"/>
                        </div>
                        <div className="auth-form__input-item">
                            <label className="auth-form__label"
                                   htmlFor="last_name">Last name</label>
                            <div className={
                                props.errors.last_name ?
                                    "auth-form__input-wrap auth-form__input-name error-input" :
                                    "auth-form__input-wrap auth-form__input-name"
                            }>
                                <Field type="text" required
                                       className={setErrorInput(props.errors.last_name)}
                                       placeholder="Last name" id="last_name"
                                       name="last_name"/>
                            </div>
                            <ErrorMessage name="last_name"
                                          className="error-label"
                                          component="p"/>
                        </div>
                        <PasswordInput name="password" errors={props.errors}/>
                        <PasswordInput name="password_confirmation"
                                       errors={props.errors}/>
                    </div>
                    <div className="auth-form__link-wrap">
                        <NavLink to="/sign-in/"
                                 className="smm-link auth-form__link">I've
                            account</NavLink>
                    </div>
                    <button type="submit"
                            className={getButtonSubmitClass("smm-btn__green auth-form__btn", formSubmit)}
                            disabled={formSubmit}>
                        <span>Sign up</span>
                        <svg width="29" height="30" viewBox="0 0 29 30"

                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.0806 20.3033L18.8971 11.4868L18.7367 18.0783C18.7283 18.4235 19.0012 18.7101 19.3463 18.7185C19.6911 18.7269 19.9779 18.4542 19.9865 18.1088L20.1695 10.5808C20.1692 10.2469 20.0395 9.9329 19.8033 9.69669C19.5673 9.46069 19.2531 9.33076 18.9042 9.33098L11.3912 9.51394C11.2241 9.51792 11.0741 9.58708 10.9645 9.69668C10.8478 9.81336 10.7771 9.97599 10.7815 10.1541C10.7899 10.4993 11.0763 10.7719 11.4214 10.7635L18.0272 10.589L9.19671 19.4194C8.95276 19.6634 8.95276 20.0593 9.19671 20.3033C9.44066 20.5472 9.83664 20.5472 10.0806 20.3033Z"
                                fill="white"/>
                        </svg>
                    </button>
                    <div
                        className="auth-form__checkbox smm-panel__auth-first-group-checkbox">
                        <label
                            className="smm-panel__auth-first-group-checkbox-container">
                            <Field type="checkbox"
                                   className="smm-panel__auth-first-group-checkbox-input"
                                   id="checkbox"
                                   name="checkbox"/>
                            <span
                                className="auth-form__checkbox-checkmark smm-panel__auth-first-group-checkbox-checkmark"></span>
                        </label>
                        <div
                            className="smm-panel__auth-first-group-checkbox-info">
                            <p className="smm-panel__auth-first-group-checkbox-text">I
                                consent to my submitted data being collected
                                and
                                stored.</p>
                            <a href="#"
                               className="smm-link auth-form__label-link">
                                Learn More
                            </a>
                        </div>
                    </div>
                    <ErrorMessage name="checkbox" className="error-label"
                                  component="p"/>
                </Form>
            )}
        </Formik>
    )
}


export default SignUpForm;