import {useEffect, useState} from "react";
import PaymentItem from "../paymentItem/PaymentItem";
import PanelTablePagination
    from "../../../parts/panelTablePagination/PanelTablePagination";
import Spinner from "../../../parts/spinner/Spinner";
import {fetchPayments} from "../PaymentsSlice";
import {useDispatch, useSelector} from "react-redux";
import {setNotification} from "../../../parts/notification/NotificationSlice";


const PaymentsTable = () => {

    // some utils hooks
    const dispatch = useDispatch();
    const {payments, paymentsLoading} = useSelector(state => state.payments);
    const [chunk, setChunk] = useState([]);

    // get array of payments
    useEffect(() => {
        dispatch(fetchPayments()).then(error => {
            const status = error?.payload?.status;
            if (!status || status >= 400){
                dispatch((setNotification({
                    "type": "error",
                    "text": "Server error"
                })))
            }
        });
    }, []);

    // render array of payments
    const renderItems = chunk.map(item => <PaymentItem key={item.id}
                                                          {...item} /> )

    // render Spinner while fetching
    if (paymentsLoading !== "idle"){
        return <Spinner/>;
    }

    return (
        <>
            <div className="payments">
                <div className="payments__table-wrap smm-panel-table__wrap">
                    <table
                        className="payments__table smm-panel-content__table">
                        <thead>
                        <tr>
                            <th className="payments-table-head-id">
                                ID
                            </th>
                            <th className="payments-table-head-quantity">
                                Method
                            </th>
                            <th className="payments-table-head-spent">
                                Amount
                            </th>
                            <th className="payments-table-head-date">
                                Date
                            </th>
                            <th className="payments-table-head-status">
                                Status
                            </th>
                        </tr>
                        </thead>
                        <tbody className="history-content__table-body">
                        {renderItems}
                        </tbody>
                    </table>
                </div>
            </div>
            <PanelTablePagination items={payments}
                                  setCurrentChunk={(value) => setChunk(value)}
                                  perPage={6}
            />
        </>
    )
}

export default PaymentsTable;