import request from "./index";


/*
 * Provides user services for retrieving and updating user information.
 */
const useUserServices = () => {

    const _baseUrl = "user";

    const userDetail = async () => {
        let url = `${_baseUrl}/`;
        return await request({url});
    }

    const userUpdate = async (data) => {
        let url = `${_baseUrl}/`;
        return await request({url, data, method: "PATCH"});
    }

    const userChangeEmail = async (data) => {
        let url = `${_baseUrl}/change-email/`;
        return await request({url, data, method: "POST"});
    }

    const userChangeEmailConfirm = async (uidb64, token, email) => {
        let url = `${_baseUrl}/change-email-confirm/${uidb64}/${token}/${email}/`
        return await request({url});
    }

    const userChangePassword = async (data) => {
        let url = `${_baseUrl}/change-password/`;
        return await request.post(url, data);
    }

    return {
        userDetail, userUpdate, userChangeEmail,
        userChangeEmailConfirm, userChangePassword
    }
}

export default useUserServices;