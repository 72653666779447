import AccordionItem from "./accordionItem/AccordionItem";
import {useState} from "react";


const Accordion = ({items, itemClass = "", isGroup = false}) => {

    const [activeId, setActiveId] = useState(items[0].id);

    const onClickItem = (id) => {
        setActiveId(id);
    }

    const renderItems = () => {
        return items.map((item) => <AccordionItem key={item.id}
                                                  isActive={activeId === item.id}
                                                  onClickItem={() => onClickItem(item.id)}
                                                  title={item["title"]}
                                                  text={item["text"]}/>
        );
    }

    const renderGroupItems = () => {

        const firstGroup = items.filter((item) => item.group === "first");
        const secondGroup = items.filter((item) => item.group === "second");

        return (
            <>
                <div className="accordion-group">
                    {
                        firstGroup.map(item => <AccordionItem key={item.id}
                                                           isActive={activeId === item.id}
                                                           onClickItem={() => onClickItem(item.id)}
                                                           title={item["title"]}
                                                           text={item["text"]}/>)
                    }
                </div>
                <div className="accordion-group">
                    {
                        secondGroup.map(item => <AccordionItem key={item.id}
                                                               isActive={activeId === item.id}
                                                               onClickItem={() => onClickItem(item.id)}
                                                               title={item["title"]}
                                                               text={item["text"]}/>)
                    }
                </div>
            </>
        )
    }

    return (
        <div className={`accordion ${itemClass}`}>
            {isGroup ? renderGroupItems() : renderItems()}
        </div>
    )
}

export default Accordion;