const Pagination = ({onPaginate, current, pagesCount, next, previous}) => {

    const createLinks = () => {

        let links = [];


        for (let count = 1; count <= pagesCount; count++) {

            const linkClass = "pagination-components__component-count";
            const activeLinkClass = "pagination-components__component-count-active";

            links.push(
                <div key={count}
                     onClick={() => onPaginate(count)}
                     className={current === count ? `${linkClass} ${activeLinkClass}` : linkClass}>
                    <p>{count}</p>
                </div>
            )
        }

        return links;
    }

    const renderPrevious = () => (
        <div className="pagination-components__component-prev"
             onClick={() => onPaginate(previous)}>
            <svg width="18" height="18" viewBox="0 0 18 18"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_18_485)">
                    <path
                        d="M4.87519 13.1522C5.14912 12.877 5.14807 12.4318 4.87283 12.1579L2.4062 9.70312L17.2969 9.70312C17.6852 9.70312 18 9.38833 18 9C18 8.61166 17.6852 8.29687 17.2969 8.29687L2.40616 8.29687L4.87287 5.84212C5.1481 5.56818 5.14916 5.123 4.87522 4.84776C4.60125 4.57249 4.15607 4.57147 3.88083 4.84537L0.206859 8.50162C0.206613 8.50183 0.206438 8.50208 0.206226 8.50229C-0.0683081 8.77623 -0.0691874 9.22285 0.206192 9.4977C0.206438 9.49791 0.206613 9.49816 0.206823 9.49837L3.88079 13.1546C4.156 13.4285 4.60121 13.4275 4.87519 13.1522Z"
                        fill="#08D565"></path>
                </g>
                <defs>
                    <clipPath id="clip0_18_485">
                        <rect width="18" height="18" fill="white"
                              transform="translate(18) rotate(90)"></rect>
                    </clipPath>
                </defs>
            </svg>
        </div>
    )

    const renderNext = () => (
        <div className="pagination-components__component-next"
             onClick={() => onPaginate(next)}>
            <svg width="18" height="18" viewBox="0 0 18 18"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_18_485)">
                    <path
                        d="M4.87519 13.1522C5.14912 12.877 5.14807 12.4318 4.87283 12.1579L2.4062 9.70312L17.2969 9.70312C17.6852 9.70312 18 9.38833 18 9C18 8.61166 17.6852 8.29687 17.2969 8.29687L2.40616 8.29687L4.87287 5.84212C5.1481 5.56818 5.14916 5.123 4.87522 4.84776C4.60125 4.57249 4.15607 4.57147 3.88083 4.84537L0.206859 8.50162C0.206613 8.50183 0.206438 8.50208 0.206226 8.50229C-0.0683081 8.77623 -0.0691874 9.22285 0.206192 9.4977C0.206438 9.49791 0.206613 9.49816 0.206823 9.49837L3.88079 13.1546C4.156 13.4285 4.60121 13.4275 4.87519 13.1522Z"
                        fill="#08D565"></path>
                </g>
                <defs>
                    <clipPath id="clip0_18_485">
                        <rect width="18" height="18" fill="white"
                              transform="translate(18) rotate(90)"></rect>
                    </clipPath>
                </defs>
            </svg>

        </div>
    )

    const renderLinks = () => {
        // get array of all number links
        let links = createLinks();

        // check if current page is 1
        if (current === 1) {
            return links.map((item, index) => {
                // if current is 1 or link is 2 or link is last
                if (index + 1 === current || index + 1 === 2 || index + 1 === pagesCount) {
                    // return link
                    return item;
                }
                // if current page is pre last and not equal second and third page
                else if (index + 1 === pagesCount - 1 && pagesCount !== 2 && pagesCount !== 3) {
                    return <p key={item.key}
                              className="pagination-components__component-dots">...</p>;
                }
            });
        }
        // check if current page is last
        else if (current === pagesCount) {
            // check if number of page greater than 3
            if (pagesCount > 3) {
                return links.map((item, index) => {
                    // if current is last or item is 1 or item is pre last
                    if (index+1 === 1 || index+1 === pagesCount-1 || index+1 === pagesCount){
                        // return link
                        return item;
                    }
                    // if item is pre pre last
                    else if (index+1 === pagesCount-2){
                        // return dots
                        return <p key={item.key}
                              className="pagination-components__component-dots">...</p>;
                    }
                });
            }
            // return all links
            else {
                return links;
            }
        }
        // if not first and last page
        else{
            return links.map((item, index) =>{
                // if current or current-1 or current+1
                if (index+1 === current || index+1 === current-1 || index+1 === current+1){
                    // return link
                    return item;
                }
            });
        }
    }

    const renderContent = () => (
        <section className="pagination">
            <div className="pagination__wrap">
                <div className="pagination-components">

                    {previous && renderPrevious()}

                    {renderLinks()}

                    {next && renderNext()}

                </div>
            </div>
        </section>
    )

    return pagesCount > 1 ? renderContent() : null;
}

export default Pagination;