import Pages from "../pages";
import Notification from "../parts/notification/Notification";
import {Suspense, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Cookie from "../../utils/cookie";
import {userDetail} from "../pages/settingsPage/UserSlice";
import Preloader from "../parts/preloader/Preloader";
import {logout} from "../pages/authPages/AuthSlice";
import {setNotification} from "../parts/notification/NotificationSlice";


const App = () => {
    /*
    * App component is initial React component
    */
    const cookie = new Cookie();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const userLoading = useSelector(state => state.user.userLoading);

    useEffect(() => {
        /*
        * Hook for change loading state
        */

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    useEffect(() => {
        /*
        * Hook for checking that user is auth and get user data
        */
        const refresh = cookie.getCookie("refresh");
        if (isAuthenticated && refresh) {
            dispatch(userDetail())
                .then(response => {
                    const notification = response?.payload?.data?.notification;

                    if (notification) {
                        dispatch(setNotification(notification));
                    }

                    if (response?.error) {
                        dispatch(setNotification({
                            "type": "error",
                            "text": "Unauthorized"
                        }))
                        dispatch(logout());
                    }
                });
        }
    }, [isAuthenticated]);

    /*
    * if loading state or user data loading render Preloader component
    */
    if (loading || userLoading === "loading") {
        return <Preloader/>;
    }

    return (
        <Suspense fallback={<Preloader/>}>
            <Notification/>
            <Pages/>
        </Suspense>
    )
};

export default App;
