import request from "./index";


/*
 * Retrieves services, orders and creates an order using the provided API URL.
 */
const useOrdersServices = () => {

    const _apiUrl = "orders"

    const getServices = async () => {
        const url = `services/`;
        return await request.get(url);
    }

    const getOrders = async () => {
        const url = `${_apiUrl}/`
        return await request.get(url)
    }

    const createOrder = async (data) => {
        const url = `${_apiUrl}/`
        return await request.post(url, data)
    }

    return {getServices, getOrders, createOrder}
}


export default useOrdersServices;
