import {ErrorMessage, Field, Form, Formik} from "formik";
import useEmailForm from "../../../../hooks/emailForm.hook";
import useUserServices from "../../../../services/UserServices";
import {useDispatch} from "react-redux";
import {setNotification} from "../../../parts/notification/NotificationSlice";
import {useState} from "react";


const ChangeEmailForm = ({email}) => {

    const [formSave, setFormSave] = useState(false);
    const {userChangeEmail} = useUserServices();
    const dispatch = useDispatch();

    const {initialValues, EmailValidationSchema} = useEmailForm(email);

    const getClassNameOfInput = (error) => {
        const errorClass = error ? "error-input" : ""
        return `smm-input order-input change-email__input ${errorClass}`;
    }

    const onSubmitForm = (values) => {
        setFormSave(true);
        userChangeEmail(values)
            .then(response => {
                setFormSave(false);
                dispatch(setNotification(response.data.notification));
            })
            .catch(error => {
               setFormSave(false);
               const notification = error?.response?.data?.notification;
               if (notification){
                   dispatch(setNotification(notification));
               } else {
                   dispatch(setNotification({
                        "type": "error",
                        "text": "Server error"
                    }))
               }
            });
    }

    return (
        <section className="smm-panel-content__block change-email">
            <div className="smm-panel-content-block__wrapper change-email__wrapper">
                <h4 className="smm-panel-content-block__title">
                    Change email</h4>
                <Formik initialValues={initialValues}
                        validationSchema={EmailValidationSchema}
                        onSubmit={onSubmitForm}>
                    {props => (
                        <Form className="change-email__form"
                              method="post">
                            <div className="smm-panel-content__form-wrap">
                                <div className="smm-panel-content__input-wrap">
                                    <p className="new-order__label">Email</p>
                                    <div className={
                                        props.errors.email ?
                                            "change-email__input-wrap error-input" :
                                            "change-email__input-wrap"
                                    }>
                                        <Field type="email"
                                               className={getClassNameOfInput(props.errors.email)}
                                               id="id_email"
                                               name="email"
                                               placeholder={email}/>
                                    </div>
                                    <ErrorMessage className="error-label"
                                                  name="email"
                                                  component="p"/>
                                </div>
                                <button type="submit"
                                        className="smm-btn__green change-settings__btn">
                                    Change email
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </section>
    )
}

export default ChangeEmailForm;