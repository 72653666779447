import {NavLink} from "react-router-dom";
import chatSupport from "../../../../assets/media/home/why_choose/chat_support.svg";
import api from "../../../../assets/media/home/why_choose/api.svg";
import multipleServices from "../../../../assets/media/home/why_choose/multiple_services.svg";
import placeSystem from "../../../../assets/media/home/why_choose/place_system.svg";


const WhyChoose = () => {

    return (
        <section className="why-choose">
            <div className="wrapper-content">
                <div className="why-choose__header">
                    <h2 className="why-choose__header-title">Why You Choose EpromoSMM</h2>
                    <p className="why-choose__header-text">
                        Here at EpromoSMM, we strive
                        for customer service as well as top quality
                        performance. You are 100% protected by using our
                        cheapest and fastest SMM panel online.
                    </p>
                    <NavLink to="sign-up/"
                       className="smm-link why-choose__header-link">
                        <button type="button" className=" why-choose__header-btn">
                            <span>Sign up now</span>
                            <svg width="29" height="30" viewBox="0 0 29 30"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.0806 20.3033L18.8971 11.4868L18.7367 18.0783C18.7283 18.4235 19.0012 18.7101 19.3463 18.7185C19.6911 18.7269 19.9779 18.4542 19.9865 18.1088L20.1695 10.5808C20.1692 10.2469 20.0395 9.9329 19.8033 9.69669C19.5673 9.46069 19.2531 9.33076 18.9042 9.33098L11.3912 9.51394C11.2241 9.51792 11.0741 9.58708 10.9645 9.69668C10.8478 9.81336 10.7771 9.97599 10.7815 10.1541C10.7899 10.4993 11.0763 10.7719 11.4214 10.7635L18.0272 10.589L9.19671 19.4194C8.95276 19.6634 8.95276 20.0593 9.19671 20.3033C9.44066 20.5472 9.83664 20.5472 10.0806 20.3033Z"
                                    fill="white"/>
                            </svg>
                        </button>
                    </NavLink>
                </div>
                <div className="why-choose__inner">
                    <div className="why-choose__block">
                        <div className="why-choose__block-img-wrap">
                            <img src={chatSupport} alt="chat_support"/>
                        </div>
                        <h4 className="why-choose__block-title">Live Chat
                            Support
                            24/7</h4>
                    </div>
                    <div className="why-choose__block">
                        <div className="why-choose__block-img-wrap">
                            <img src={placeSystem} alt="place_system"/>
                        </div>
                        <h4 className="why-choose__block-title">Easy To Place
                            System For Mass Order</h4>
                    </div>
                    <div className="why-choose__block">
                        <div className="why-choose__block-img-wrap">
                            <img
                                src={multipleServices}
                                alt="multiple_services"/>
                        </div>
                        <h4 className="why-choose__block-title">Easy To Use
                            Multiple Services All In One</h4>
                    </div>
                    <div className="why-choose__block">
                        <div className="why-choose__block-img-wrap">
                            <img src={api}
                                 alt="api"/>
                        </div>
                        <h4 className="why-choose__block-title">Full API
                            Integration</h4>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChoose;