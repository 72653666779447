import stars from "../../../../assets/media/home/reviews-slider/stars.svg";
import eva
    from "../../../../assets/media/home/reviews-slider/users/eva.png";
import jan
    from "../../../../assets/media/home/reviews-slider/users/jan.png";
import austin
    from "../../../../assets/media/home/reviews-slider/users/austin.png";
import {Splide, SplideSlide, SplideTrack} from '@splidejs/react-splide';


const SmmReviews = () => {

    const options = {
        type: 'loop',
        perPage: 3,
        perMove: 1,
        gap: 31,
        pagination: false,
        classes: {
            arrows: 'splide__arrows splide__components-arrows',
            arrow: 'splide__arrow splide__components-arrow',
            prev: 'splide__arrow--prev splide__components-arrows-prev',
            next: 'splide__arrow--next splide__components-arrows-next',
        },
        breakpoints: {
            1100: {
                perPage: 2,
                gap: 20,
            },
            700: {
                perPage: 1,
            },
        },
    }

    return (
        <section className="smm-reviews">
            <div className="wrapper-content">
                <div className="smm-reviews__header">
                    <h2 className="smm-reviews__header-title">What our clients
                        says</h2>
                    <p className="smm-reviews__header-text">We strive for more,
                        and
                        we are grateful for choosing us!</p>
                </div>
                <div className="smm-reviews__content">
                    <Splide hasTrack={false} options={options}
                            aria-selected="false">
                        <SplideTrack>
                            <SplideSlide className="splide__slide reviews-slide">
                                <div className="reviews-slide__stars-wrap">
                                    <img
                                        src={stars}
                                        alt="stars"/>
                                </div>
                                <div className="reviews-slide__content">
                                    <p className="reviews-slide__content-text">
                                        We strive for more and we’re
                                        grateful to you for choosing
                                        us!</p>
                                    <p className="reviews-slide__content-text">
                                        They did the website design, as
                                        well as promotion strategy for our
                                        small business. In 6 months of
                                        cooperation, we’ve managed to
                                        increase our sales. And on top of
                                        that, we got new clients. We’re
                                        thrilled!</p>
                                </div>
                                <div className="reviews-slide__info">
                                    <div
                                        className="reviews-slide__info-wrap">
                                        <div
                                            className="reviews-slide__info-img-wrap">
                                            <img
                                                src={eva}
                                                alt="Eva Williams"/>
                                        </div>
                                        <div
                                            className="reviews-slide__info-main">
                                            <h5 className="reviews-slide__info-main-title">
                                                Eva Williams</h5>
                                            <p className="reviews-slide__info-main-place">
                                                CEO. San Bernardino,
                                                USA</p>
                                        </div>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide className="splide__slide reviews-slide">
                                <div className="reviews-slide__stars-wrap">
                                    <img
                                        src={stars}
                                        alt="stars"/>
                                </div>
                                <div className="reviews-slide__content">
                                    <p className="reviews-slide__content-text">
                                        We were lucky to meet qualified
                                        programmers with great knowledge in
                                        their field here. The manager and
                                        the whole team are true
                                        professionals, which is reflected
                                        in the website they made for us.
                                        Thank you, guys!</p>
                                </div>
                                <div className="reviews-slide__info">
                                    <div
                                        className="reviews-slide__info-wrap">
                                        <div
                                            className="reviews-slide__info-img-wrap">
                                            <img
                                                src={jan}
                                                alt="Jan Gaetz"/>
                                        </div>
                                        <div
                                            className="reviews-slide__info-main">
                                            <h5 className="reviews-slide__info-main-title">
                                                Jan Gaetz</h5>
                                            <p className="reviews-slide__info-main-place">
                                                Director. Cheb, Czech
                                                Republic</p>
                                        </div>
                                    </div>
                                </div>
                            </SplideSlide>
                            <SplideSlide className="splide__slide reviews-slide">
                                <div className="reviews-slide__stars-wrap">
                                    <img
                                        src={stars}
                                        alt="stars"/>
                                </div>
                                <div className="reviews-slide__content">
                                    <p className="reviews-slide__content-text">
                                        It took us a long time to choose a
                                        contractor among hundreds of
                                        studios and freelancers, but we
                                        made the right choice! The team of
                                        specialists fulfilled our order
                                        promptly and accurately. I
                                        recommend them to everyone who
                                        wants the best for their
                                        business!</p>
                                </div>
                                <div className="reviews-slide__info">
                                    <div
                                        className="reviews-slide__info-wrap">
                                        <div
                                            className="reviews-slide__info-img-wrap">
                                            <img
                                                src={austin}
                                                alt="Austin Johnson"/>
                                        </div>
                                        <div
                                            className="reviews-slide__info-main">
                                            <h5 className="reviews-slide__info-main-title">
                                                Austin Johnson</h5>
                                            <p className="reviews-slide__info-main-place">
                                                Marketer. Montreal,
                                                Canada</p>
                                        </div>
                                    </div>
                                </div>
                            </SplideSlide>
                        </SplideTrack>
                        <div className="splide__arrows splide__components-arrows"></div>
                    </Splide>
                </div>
            </div>
        </section>
    )
}

export default SmmReviews;