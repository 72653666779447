import {Form, Formik} from "formik";
import * as Yup from "yup";
import {useState} from "react";
import getButtonSubmitClass from "../../../../../utils/getButtonSubmitClass";
import PasswordInput from "../../../../parts/passwordInput/PasswordInput";
import {
    setNotification
} from "../../../../parts/notification/NotificationSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";


const ResetPasswordForm = ({sendData, uidb64, token}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialValues = {
        password: "",
        password_confirmation: "",
    }

    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$");

    const [formSubmit, setFormSubmit] = useState(false);

    const ResetPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .trim()
            .matches(
                passwordRegex,
                "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number")
            .required("Password is required"),
        password_confirmation: Yup.string()
            .trim()
            .oneOf([Yup.ref('password')], "Passwords don't match, please try again")
            .required("Password Confirmation is required"),
    });

    const onSubmitForm = (values) =>{

        setFormSubmit(true)
        sendData(uidb64, token, values)
            .then(onSuccessSubmit)
            .catch(onErrorSubmit)
    }

    const onSuccessSubmit = (response) => {
        // Called when a form submission is successful and send notification and redirect to main page
        dispatch(setNotification(response.data.notification));
        navigate("/");
    }

    const onErrorSubmit = (error) => {
        // Called when a form submission is error and send notification
        dispatch(setNotification(error.response.data.notification));
    }

    return (<Formik initialValues={initialValues}
                    validationSchema={ResetPasswordSchema}
                    onSubmit={onSubmitForm}
        >
            {(props) => (
                <Form className="auth-form  auth-form__sign-in" method="post">
                    <div
                        className="auth-form__input-inner auth-form__sign-in-input-inner">
                        <PasswordInput name="password" errors={props.errors}/>
                        <PasswordInput name="password_confirmation" errors={props.errors}/>
                    </div>
                    <button type="submit"
                            className={getButtonSubmitClass("smm-btn__green auth-form__btn auth-form__reset-btn", formSubmit)}
                            disabled={formSubmit}>
                        <span>Change password</span>
                        <svg width="29" height="30" viewBox="0 0 29 30"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.0806 20.3033L18.8971 11.4868L18.7367 18.0783C18.7283 18.4235 19.0012 18.7101 19.3463 18.7185C19.6911 18.7269 19.9779 18.4542 19.9865 18.1088L20.1695 10.5808C20.1692 10.2469 20.0395 9.9329 19.8033 9.69669C19.5673 9.46069 19.2531 9.33076 18.9042 9.33098L11.3912 9.51394C11.2241 9.51792 11.0741 9.58708 10.9645 9.69668C10.8478 9.81336 10.7771 9.97599 10.7815 10.1541C10.7899 10.4993 11.0763 10.7719 11.4214 10.7635L18.0272 10.589L9.19671 19.4194C8.95276 19.6634 8.95276 20.0593 9.19671 20.3033C9.44066 20.5472 9.83664 20.5472 10.0806 20.3033Z"
                                  fill="white"/>
                        </svg>
                    </button>
                </Form>
            )}
        </Formik>
    )
}

export default ResetPasswordForm;