import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import useUserServices from "../../../../services/UserServices";
import {useDispatch} from "react-redux";
import {setNotification} from "../../../parts/notification/NotificationSlice";
import {useState} from "react";
import Spinner from "../../../parts/spinner/Spinner";
import {setUserPersonalInfo} from "../UserSlice";


const ChangePersonalInfoForm = ({firstName, lastName}) => {

    const [formUpdate, setFormUpdate] = useState(false);
    const {userUpdate} = useUserServices();
    const dispatch = useDispatch();

    const initialValues = {
        first_name: firstName,
        last_name: lastName,
    }

    const PersonalInfoSchema = Yup.object().shape({
        first_name: Yup.string()
            .trim()
            .min(2, "First name must be at least 2 characters")
            .max(25, "First name must be at most 25 characters")
            .required("First name is required"),
        last_name: Yup.string()
            .trim()
            .min(2, "Last name must be at least 2 characters")
            .max(50, "Last name must be at most 50 characters")
            .required("Last name is required"),
    })

    const getClassNameOfInput = (error) => {
        const errorClass = error ? "error-input" : ""
        return `smm-input order-input change-first-name__input ${errorClass}`;
    }

    const onSubmitForm = (values) => {
        setFormUpdate(true);
        userUpdate(values)
            .then(response => {
                setFormUpdate(false);
                dispatch(setUserPersonalInfo(values));
                dispatch(setNotification(response.data.notification))
            })
            .catch(error => {
                setFormUpdate(false);
                const notification = error?.response?.data?.notification;
               if (notification){
                   dispatch(setNotification(notification));
               } else {
                   dispatch(setNotification({
                        "type": "error",
                        "text": "Server error"
                    }))
               }
            });
    }

    if (formUpdate) {
        return <Spinner/>;
    }

    return (
        <>
            <h4 className="smm-panel-content-block__title">
                Personal</h4>
            <Formik initialValues={initialValues}
                    validationSchema={PersonalInfoSchema}
                    onSubmit={onSubmitForm}>
                {props => (
                    <Form className="change-personal-info__form"
                          method="post">
                        <div className="smm-panel-content__form-wrap">
                            <div className="smm-panel-content__input-wrap">
                                <p className="new-order__label">
                                    First name</p>
                                <Field type="text"
                                       className={getClassNameOfInput(props.errors.first_name)}
                                       id="id_first_name"
                                       name="first_name"
                                       placeholder={firstName}/>
                                <ErrorMessage name={"first_name"}
                                              className={"error-label"}
                                              component={"p"}/>
                            </div>
                            <div className="smm-panel-content__input-wrap">
                                <p className="new-order__label">
                                    Last name</p>
                                <Field type="text"
                                       className={getClassNameOfInput(props.errors.last_name)}
                                       id="id_last_name"
                                       name="last_name"
                                       placeholder={lastName}/>
                                <ErrorMessage name={"last_name"}
                                              className={"error-label"}
                                              component={"p"}/>
                            </div>
                            <button type="submit"
                                    className="smm-btn__green change-settings__btn">
                                Change info
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ChangePersonalInfoForm;