import {Fragment, useEffect, useMemo, useState} from "react";
import MediaQuery from "react-responsive";


const Tab = ({items, activeId, customClassName = ""}) => {

    const [activeItem, setActiveItem] = useState(activeId);

    useEffect(() => {
        setActiveItem(activeId);
    }, [activeId]);

    const body = useMemo(() => items.filter(
        item => item.id === activeItem
    )[0].bodyContent(), [activeItem]);

    function getClassName(id, defaultClassName, activeClassName) {
        return activeItem === id ? `${defaultClassName} ${activeClassName}` : defaultClassName;
    }

    function renderHeaders() {

        return items.map((item) => {

            const headerClassName = getClassName(
                item.id,
                "tabs-header",
                "tabs-header-active"
            );

            return (
                <div key={item.id}
                     className={headerClassName}
                     onClick={() => setActiveItem(item.id)}
                >
                    {item.headerContent()}
                </div>
            )
        });
    }

    function renderMobileVersion(){

        return items.map((item) => {

            const headerClassName = getClassName(
                item.id,
                "tabs-header",
                "tabs-header-active"
            );
            const bodyClassName = getClassName(
                item.id,
                "tabs-body",
                "tabs-body-active"
            );


            return (
                <Fragment key={item.id}>
                    <div className={headerClassName}
                         onClick={() => setActiveItem(item.id)}
                    >
                        {item.headerContent()}
                    </div>
                    <div className={bodyClassName}>
                        <div className="tabs-body-wrap">
                            {item.bodyContent()}
                        </div>
                    </div>
                </Fragment>
            )
        });
    }

    return (
        <div className={`tabs-wrapper ${customClassName}`}>
            <MediaQuery minWidth={1150}>
                <div className="tabs-headers">
                    {renderHeaders()}
                </div>
                <div className="tabs-bodies">
                    <div className="tabs-body tabs-body-active">
                        <div className="tabs-body-wrap">
                            {body}
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1150}>
                <div className="tabs-headers">
                    {renderMobileVersion()}
                </div>
            </MediaQuery>
        </div>
    )
}

export default Tab;