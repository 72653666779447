import {
    BrowserRouter as Router
} from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@splidejs/react-splide/css';
import './index.css';
import App from './components/app/App';
import {Provider} from 'react-redux';
import store from "./store";
import {GoogleOAuthProvider} from '@react-oauth/google';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <GoogleOAuthProvider
            clientId="610047515175-6g1l0h1rps98icua89ll2fsd692of1jt.apps.googleusercontent.com">
            <Router>
                <App/>
            </Router>
        </GoogleOAuthProvider>
    </Provider>
);
