import InputError from "../inputError/InputError";


const PanelInput = ({
                        classInput,
                        label,
                        name,
                        type,
                        onChange,
                        value,
                        error,
    readonly = false,
                    }) => {

    const getClassName = () => {
        let def = `smm-input order-input ${classInput}`;
        return error ? `${def} error-input` : def;
    }

    return (
        <div className="smm-panel-content__input-wrap">
            <p className="new-order__label">{label}</p>
            <input type={type}
                   className={getClassName()}
                   onChange={(e) => onChange(e)}
                   value={value}
                   readOnly={readonly}
                   name={name}
            />
            {error && <InputError message={error}/>}
        </div>
    )
}

export default PanelInput;