import smmPanelSection
    from "../../../../assets/media/home/smm_panel_section.png"
import SmmPanelAuth from "../smmPanelAuth/SmmPanelAuth";


const SmmPanel = () => {

    return (
        <section className="smm-panel">
            <div className="smm-panel__content">
                <div className="wrapper-content">
                    <div className="smm-panel__content-inner">
                        <div className="smm-panel__info">
                            <h3 className="smm-panel__info-subtitle">World's
                                Best Cheap & Easy</h3>
                            <h1 className="smm-panel__info-title">SMM
                                Panel</h1>
                            <p className="smm-panel__info-text">
                                Our SMM Panel
                                offers you the possibility to promote your
                                business on social networks with a high
                                quality and a cheap price. EpromoSMM is the
                                fastest
                                and cheapest SMM Panel for Instagram,
                                YouTube, TikTok, Snapchat, Telegram and
                                Facebook.
                                You can also earn money by joining our
                                affiliate program or start your own Smm panel
                                through
                                our SMM reseller panel.
                            </p>
                        </div>
                        <div className="smm-panel__content-img-wrap">
                            <img src={smmPanelSection}
                                 alt="smm-panel-section"
                                 className="smm-panel__content-img"/>
                        </div>
                    </div>
                </div>
            </div>
            <SmmPanelAuth/>
        </section>
    )
}

export default SmmPanel;