import request from "./index";

/*
 * Function to use contact services.
 */
const useContactServices = () => {

    const _baseUrl = "contact-us";

    const contactUs = async (data) => {
        let url = `${_baseUrl}/`;
        return await request({
            url,
            data,
            method: "post"
        });
    }

    return {contactUs};
}

export default useContactServices;