import firstImage from "../../../../assets/media/home/faq/first_image.png";
import Accordion from "../../../parts/accordion/Accordion";


const FaqSmmFirst = () => {

    const items = [
        {
            id: "register-smm",
            title: "Register in our Smm Panel?",
            text: "Most of our smmpanel Users are agencies" +
                  " companies and individual resellers or" +
                  " other smm panels owners your can" +
                  " register using your details and email" +
                  " address"
        },
        {
            id: "add-fund",
            title: "Add fund Via paypal or Debit/Credit Card?",
            text: "We accept payment with Paypal Bank Card or Paytm in india ," +
                  " cashmaal in Pakistan or payop in europ and south america and Usa"
        },
        {
            id: "place-order",
            title: "Place Your order",
            text: "After registration and adding fund you can place your order" +
                  " and start using our instagram tiktok or youtube services since" +
                  " we are the Fastest SMM Reseller Panel your order will start in few second ."
        },
        {
            id: "make-money",
            title: "How we can help you make money?",
            text: "We paid thousands of dollars to Our users who share our smm panel " +
                "with there friends and you can also make 5% with our affiliate service since" +
                " it’s accessible for everyone and everyone can use our smmpanel " +
                "And get benefit with our service ."
        },
        {
            id: "targeted-smm",
            title: "Targeted SMM panel services",
            text: "Since we are doing social media marketing and the best smm panel we have " +
                "clients all over the globe we mostly work with international companies " +
                "require targeted service like targeted smm panel india or tageted tiktok brazil" +
                " ...this why our smm panel offer multiple payment methods such as Paypal , " +
                "paytm or bank transfer to all our client choose there convinient way to do business with us ."
        },
        {
            id: "orders-fast",
            title: "Orders are getting completed fast",
            text: "After you placed your order then just sit back and let us take care of the rest" +
                " we follow your campaign till the end and till you get satisfied with our smm resutls."
        }
    ]

    return (
        <section className="faq-smm">
            <div className="wrapper-content">
                <h2 className="faq-smm__title">FAQ EpromoSMM</h2>
                <div className="faq-smm__inner">
                    <div className="faq-smm__img-wrap">
                        <img src={firstImage} alt="faq-image"/>
                    </div>
                    <Accordion items={items} itemClass="faq-smm__accordion"/>
                </div>
            </div>
        </section>
    )
}

export default FaqSmmFirst;