import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getTickets, onTicketsSearch, ticketsSelector} from "../TicketsSlice";
import Spinner from "../../../parts/spinner/Spinner";
import TicketItem from "../ticketItem/TicketItem";
import PanelTablePagination
    from "../../../parts/panelTablePagination/PanelTablePagination";
import {setNotification} from "../../../parts/notification/NotificationSlice";

const TicketHistory = () => {

    const dispatch = useDispatch();
    const {ticketsLoading, ticketsSearch} = useSelector(state => state.tickets);
    const tickets = useSelector(ticketsSelector);
    const [chunk, setChunk] = useState([])


    useEffect(() => {
        dispatch(getTickets()).then(response => {
            const notification = response?.payload?.data?.notification;
            if (notification){
                dispatch(setNotification(notification))
            }
        });
    }, []);

    const onSearch = (value) => {
        dispatch(onTicketsSearch(value));
    }

    const createTicketItems = chunk.map(item => <TicketItem  key={item.id} {...item} />);

    const renderTickets = () => {
        if (ticketsLoading !== "idle") {
            return <Spinner/>;
        } else if (tickets.length > 0) {
            return (
                <ul className="ticket-history__list">
                    {createTicketItems}
                </ul>);
        } else {
            return (<div className="ticket-history__list-zero">
                    <p className="ticket-history__list-zero-text">You
                        don't have a tickets</p>
                </div>
            );
        }
    }

    return (
        <section
            className="smm-panel-content__block ticket-history-block">
            <div className="smm-panel-content-block__wrapper">
                <h4 className="smm-panel-content-block__title">Tickets
                    History</h4>
                <form method="get">
                    <div
                        className="history-filters__search-inner ticket-search">
                        <div
                            className="history-filters__search-input-wrap">
                            <input name="search"
                                   className="smm-input order-input history-filters__search-input ticket-search__input"
                                   placeholder="Search"
                                   onChange={e => onSearch(e.target.value)}
                                   value={ticketsSearch}/>
                        </div>
                        <button type="submit"
                                className="smm-btn__green history-filters__search-btn ticket-search__btn">
                            <svg width="12" height="12"
                                 viewBox="0 0 12 12"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_42_1190)">
                                    <path
                                        d="M11.8866 11.3471L8.78842 8.29845C9.59974 7.41698 10.0982 6.25121 10.0982 4.96841C10.0979 2.22426 7.83758 0 5.04917 0C2.26077 0 0.000488281 2.22426 0.000488281 4.96841C0.000488281 7.71256 2.26077 9.93682 5.04917 9.93682C6.25395 9.93682 7.35896 9.5201 8.22692 8.82731L11.3371 11.888C11.4887 12.0373 11.7347 12.0373 11.8863 11.888C12.0382 11.7387 12.0382 11.4964 11.8866 11.3471ZM5.04917 9.1724C2.68988 9.1724 0.777299 7.29021 0.777299 4.96841C0.777299 2.6466 2.68988 0.764419 5.04917 0.764419C7.40849 0.764419 9.32104 2.6466 9.32104 4.96841C9.32104 7.29021 7.40849 9.1724 5.04917 9.1724Z"
                                        fill="white"/>
                                </g>
                                <defs>
                                    <clipPath
                                        id="clip0_42_1190">
                                        <rect width="12"
                                              height="12"
                                              fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span>Search</span>
                        </button>

                    </div>
                </form>
                <div className="ticket-history">
                    {renderTickets()}
                    <PanelTablePagination items={tickets} perPage={7} setCurrentChunk={setChunk}/>
                </div>
            </div>
        </section>
    )
}

export default TicketHistory;