import AuthWrapper from "../../../parts/authWrapper/AuthWrapper";
import SignInForm from "./signInForm/SignInForm";
import AuthSocialNetworks
    from "../../../parts/authSocialNetworks/AuthSocialNetworks";


const SignInPage = () => {

    return (
        <AuthWrapper>
            <h2 className="auth-form__title">Sign in</h2>
            <SignInForm/>
            <AuthSocialNetworks/>
        </AuthWrapper>
    )
}

export default SignInPage;