import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    notifications: [],
}


const NotificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotification: (state, action) => {
            const id = Math.floor(Math.random() * 1000000);
            state.notifications.push({id, ...action.payload});
        },
        removeNotification: (state, action) => {
            state.notifications = state.notifications.filter((item) => item.id !== action.payload)
        }
    }
})


const {actions, reducer} = NotificationSlice;

export default reducer;

export const {setNotification, removeNotification} = actions;

export const selectNotifications = (state) => state.notification;
