import {NavLink} from "react-router-dom";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {authSelector, requestLogin} from "../../authPages/AuthSlice";
import {
    setNotification
} from "../../../parts/notification/NotificationSlice";
import getButtonSubmitClass from "../../../../utils/getButtonSubmitClass";


const SmmPanelAuth = () => {

    const {requestLoginLoading} = useSelector(authSelector);
    const dispatch = useDispatch(); // get function dispatch

    // initial values of form
    const initialValues = {
        "email": "",
        "password": "",
    };

    // create schema for validate fields
    const AuthSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .email("Please, write valid email address")
            .required("Email is required"),
        password: Yup.string()
            .trim()
            .required('Password is required')
    });

    // custom validate of password
    const validatePassword = (values) => {
        const errors = {}
        const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$");

        if (!regex.test(values["password"])) {
            errors["password"] = "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number";
        }

        return errors;
    };

    // function for handle submit form
    const onSubmitForm = (values) => {
        dispatch(requestLogin(values)).then(onResponse);
    };

    const onResponse = (response) => {
        // create notification when a response is received.
        const notification = response?.payload?.data?.notification;
        if (notification) {
            dispatch(setNotification(notification));
        } else if (response?.error) {
            dispatch(setNotification({
                "type": "error",
                "text": "Server error"
            }))
        }
    }


    return (
        <div className="smm-panel__auth">
            <div className="wrapper-content">
                <div className="smm-panel__bg">
                    <Formik initialValues={initialValues}
                            validationSchema={AuthSchema}
                            validate={validatePassword}
                            onSubmit={onSubmitForm}
                    >
                        {(props) => (
                            <Form method="post"
                                  className="smm-panel__auth-form">
                                <div className="smm-panel__auth-inner">
                                    <div
                                        className="smm-panel__auth-first-group">
                                        <div
                                            className="smm-panel__auth-first-group-wrap">
                                            <div
                                                className="smm-panel__auth-first-group-inputs">
                                                <div
                                                    className={"smm-panel__auth-wrap-item"}>
                                                    <div
                                                        className={props.errors.email ?
                                                            "smm-panel__auth-input-wrap smm-panel__auth-input-email error-input" :
                                                            "smm-panel__auth-input-wrap smm-panel__auth-input-email"}>
                                                        <Field type="email"
                                                               required
                                                               className={props.errors.email ? "smm-input smm-panel__auth-input error-input" : "smm-input smm-panel__auth-input"}
                                                               placeholder="Email"
                                                               name="email"
                                                        />
                                                    </div>
                                                    <ErrorMessage name="email"
                                                                  className="error-label"
                                                                  component="p"/>
                                                </div>
                                                <div
                                                    className={"smm-panel__auth-wrap-item"}>
                                                    <div className={
                                                        props.errors.password ?
                                                            "smm-panel__auth-input-wrap smm-panel__auth-input-password error-input" :
                                                            "smm-panel__auth-input-wrap smm-panel__auth-input-password"
                                                    }>
                                                        <Field type="password"
                                                               required
                                                               className={props.errors.password ? "smm-input smm-panel__auth-input error-input" : "smm-input smm-panel__auth-input"}
                                                               placeholder="Password"
                                                               id="password"
                                                               name="password"/>
                                                    </div>
                                                    <ErrorMessage
                                                        name="password"
                                                        className="error-label"
                                                        component="p"/>
                                                </div>
                                            </div>
                                            <div
                                                className="smm-panel__auth-first-group-checkbox">
                                                <label
                                                    className="smm-panel__auth-first-group-checkbox-container">
                                                    <input type="checkbox"
                                                           className="smm-panel__auth-first-group-checkbox-input"/>
                                                    <span
                                                        className="smm-panel__auth-first-group-checkbox-checkmark"></span>
                                                </label>
                                                <p className="smm-panel__auth-first-group-checkbox-text">
                                                    Remember me</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="smm-panel__auth-second-group">
                                        <div
                                            className="smm-panel__auth-second-group-wrap">
                                            <button type="submit"
                                                    disabled={requestLoginLoading}
                                                    className={getButtonSubmitClass("smm-btn__green smm-panel__auth-btn", requestLoginLoading)}>
                                                <span>Sign in</span>
                                            </button>
                                            <div
                                                className="smm-panel__auth-second-group-links">
                                                <NavLink to="/forgot-password/"
                                                         className="smm-panel__auth-second-group-link">
                                                    Forgot password?
                                                </NavLink>
                                                <NavLink to="/sign-up/"
                                                         className="smm-panel__auth-second-group-link">
                                                    Sign up
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default SmmPanelAuth;