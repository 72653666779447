import TicketTypes from "../ticketTypes/TicketTypes";
import {useEffect, useState} from "react";
import InputError from "../../../parts/inputError/InputError";
import isObjectEmpty from "../../../../utils/checkObjectEmpty";
import getButtonSubmitClass from "../../../../utils/getButtonSubmitClass";
import {useDispatch} from "react-redux";
import {postNewTicket} from "../TicketsSlice";
import {setNotification} from "../../../parts/notification/NotificationSlice";


const TicketCreate = () => {

    const typesWithID = ["Orders", "Payments"]
    const dispatch = useDispatch()

    const [typeSelected, setTypeSelected] = useState("Orders")
    const [values, setValues] = useState({
        type_id: "",
        message: "",
        source: "",
        file: ""
    })
    const [errors, setErrors] = useState({});
    const [isError, setIsError] = useState();

    useEffect(() => {
        // check that errors state is empty
        setIsError(!isObjectEmpty(errors));
    }, [errors])

    const getClassName = (def, error) => {
        // get error input
        return error ? `${def} error-input` : def;
    }

    const validateTypeID = (value, errorsObj) => {
        // function for validation id on positive integer
        if (value && value <= 0) {
            errorsObj.typeID = "Id must be greater than zero";
        } else {
            delete errorsObj.typeID;
        }

        return errorsObj;
    }

    const validateMessage = (value, errorsObj) => {
        // function for validation message
        if (value.trim().length <= 0) {
            errorsObj.message = "Message is required";
        } else {
            delete errorsObj.message;
        }
        return errorsObj;
    }

    const validateFile = (file, errorsObj) => {
        // function for validation size of file

        if (file && file.size > 3000000) {
            errorsObj.file = "File must be less than 3MB";
        } else {
            delete errorsObj.file;
        }
        return errorsObj;
    }

    const onChangeId = (e) => {
        // event on change id input(only for type: Orders, Payments)
        const {value} = e.target;
        setValues(prevState => ({...prevState, type_id: value}));
        setErrors(state => {
            const newErrors = {...state};
            return validateTypeID(value, newErrors);
        })
    }

    const onChangeMessage = (e) => {
        // event on change message input
        const {value} = e.target;
        setValues(prevState => ({...prevState, message: value}));
        setErrors(state => {
            const newErrors = {...state};
            return validateMessage(value, newErrors);
        });
    }

    const onChangeSource = (e) => {
        // event on change source input
        const {value} = e.target;
        setValues(prevState => ({...prevState, source: value}));
    }

    const onChangeFile = (e) => {
        // event on change input file
        try {
            const file = e.target.files[0];
            setValues(prevState => ({...prevState, file}));
            setErrors(state => {
                const newErrors = {...state};
                return validateFile(file, newErrors);
            });
        } catch {

        }
    }

    const validateOnSubmit = ({type_id, message, file}) => {
        // method for validating before fetch to api
        const errorsObj = {};
        validateTypeID(type_id, errorsObj);
        validateMessage(message, errorsObj);
        validateFile(file, errorsObj);
        if (isObjectEmpty(errorsObj)) {
            return true;
        } else {
            setErrors(errorsObj);
            return false;
        }
    }

    const onSubmitForm = (e) => {
        // event on submit form
        e.preventDefault();
        // if values is valid - create form data and send it
        if (validateOnSubmit(values)) {
            const {type_id, message, source, file} = values;
            const formData = new FormData();
            if (type_id && typesWithID.includes(typeSelected)) {
                formData.append('type_id', type_id);
            }
            formData.append('type', typeSelected);
            formData.append('message', message);
            formData.append('source', source);
            if (file) {
                formData.append('file', file, file.name);
            }
            // fetch to api
            dispatch(postNewTicket(formData)).then(response => {
                // create notification
                const notification = response?.payload?.data?.notification;
                if (notification) {
                    dispatch(setNotification(notification));
                } else {
                    dispatch(setNotification({
                        "type": "error",
                        "text": "Server error"
                    }))
                }
            });
        }
    }

    return (
        <section className="smm-panel-content__block ticket-create-create">
            <div className="smm-panel-content-block__wrapper">
                <h4 className="smm-panel-content-block__title">
                    Tickets</h4>
                <form className="ticket__form" method="post"
                      onSubmit={onSubmitForm}
                      encType="multipart/form-data">
                    <div className="ticket__types">
                        <TicketTypes typeSelected={typeSelected}
                                     setTypeSelected={setTypeSelected}/>
                    </div>
                    <div
                        className="smm-panel-content__form-wrap new-order__form-wrap">
                        <div className="ticket__form-add-inputs">

                            {typeSelected === "Orders" && (<div
                                className="smm-panel-content__input-wrap ticket__input-wrap ticket__input-wrap-add">
                                <div className="smm-panel__label-wrap">
                                    <p className="new-order__label">
                                        Order ID</p>
                                    <p className="smm-panel__label-add">
                                        (To receive a faster
                                        response, ensure that
                                        you
                                        assign the correct
                                        order
                                        IDs.)</p>
                                </div>
                                <input type="number"
                                       className={getClassName("smm-input order-input ticket-input__order-id", errors.typeID)}
                                       id="id_ticket_type"
                                       placeholder="ex. 27417914,27417915,27417916,27417917,27417918"
                                       name="ticket_type_id"
                                       onChange={onChangeId}
                                       value={values.type_id}
                                />
                                {errors.typeID &&
                                    <InputError message={errors.typeID}/>}
                            </div>)
                            }
                            {typeSelected === "Payments" && (<div
                                className="smm-panel-content__input-wrap ticket__input-wrap ticket__input-wrap-add">
                                <div className="smm-panel__label-wrap">
                                    <p className="new-order__label">
                                        Payment ID</p>
                                    <p className="smm-panel__label-add">
                                        (To receive a faster
                                        response, ensure that
                                        you
                                        assign the correct
                                        payment
                                        IDs.)</p>
                                </div>
                                <input type="number"
                                       className={getClassName("smm-input order-input ticket-input__order-id", errors.typeID)}
                                       id="id_ticket_type"
                                       placeholder="ex. 27417914,27417915,27417916,27417917,27417918"
                                       name="ticket_type_id"
                                       onChange={onChangeId}
                                       value={values.type_id}
                                />
                                {errors.typeID &&
                                    <InputError message={errors.typeID}/>}
                            </div>)
                            }
                        </div>
                        <div
                            className="smm-panel-content__input-wrap ticket__input-wrap">
                            <p className="new-order__label">Message</p>
                            <textarea
                                className={getClassName("smm-input order-input ticket-input__message ", errors.message)}
                                id="id_message"
                                placeholder="ex. Hi there, I would like to check my recent order status."
                                onChange={onChangeMessage}
                                value={values.message}
                                name="message"></textarea>
                            {errors.message &&
                                <InputError message={errors.message}/>}
                        </div>
                        <div
                            className="smm-panel-content__input-wrap ticket__input-wrap">
                            <p className="new-order__label">How
                                did you
                                hear about our website?</p>
                            <p className="smm-panel__label-add">(Please
                                mention how did hear about us
                                [Search engines, Social media,
                                Referral, Forum, Other])</p>
                            <input type="text"
                                   className="smm-input order-input ticket-input__hw-u-hear"
                                   id="id_source"
                                   onChange={onChangeSource}
                                   value={values.source}
                                   name="source"/>
                        </div>
                        <div
                            className="smm-panel-content__input-wrap ticket__input-wrap">
                            <div
                                className="smm-panel__label-wrap ticket-input-file">
                                <svg width="14" height="14"
                                     viewBox="0 0 14 14"
                                     fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.947 4.28117L4.21082 9.01325C3.78368 9.44039 3.78368 10.1329 4.21082 10.5601C4.63796 10.9872 5.33049 10.9872 5.75763 10.5601L12.0406 4.28117C12.8949 3.4269 12.8949 2.04185 12.0406 1.18758C11.1863 0.333307 9.80128 0.333307 8.947 1.18758L2.27734 7.85314"
                                        stroke="#08D565"
                                        strokeWidth="1.09375"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M2.27794 7.85352C0.998499 9.13296 0.998499 11.2145 2.27794 12.4939C3.55738 13.7734 5.6389 13.7734 6.91834 12.4939L11.172 8.24021"
                                        stroke="#08D565"
                                        strokeWidth="1.09375"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                </svg>
                                <div
                                    className="ticket__input-file-wrap">
                                    {/*{#                                                    <label for="file-upload" class="custom-file-upload">#}*/}
                                    <input type="file"
                                           name="file"
                                           onChange={onChangeFile}
                                           value={values.file?.filename}
                                           placeholder="Attach files"/>
                                    {errors.file &&
                                        <InputError message={errors.file}/>}
                                    {/*{#                                                        Custom Upload</label>#}*/}
                                </div>
                            </div>
                        </div>
                        <button type="submit"
                                disabled={isError}
                                className={getButtonSubmitClass("smm-btn__green add-funds__submit", isError)}>
                            Submit ticket
                        </button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default TicketCreate;