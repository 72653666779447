import Breadcrumbs from "../../parts/breadcrumbs/Breadcrumbs";
import greenArrow from "../../../assets/media/additional/green-arrow-20.svg";
import Tab from "../../parts/tab/Tab";
import {useSearchParams} from "react-router-dom";


const TermsPage = () => {

    const paths = [
        {
            name: "Home",
            path: "/"
        },
        {
            name: "Terms",
            path: "/terms/"
        }
    ]

    const items = [
        {
            id: "general",
            headerContent: () => (
                <>
                    <div
                        className="smm-main-services__content-header__wrap">
                        <h4 className="smm-main-services__content-header__title">
                            General</h4>
                    </div>
                    <div className="terms__content-header__arrow-wrap">
                        <img
                            src={greenArrow}
                            alt="arrow"
                            className="terms__content-header__arrow"/>
                    </div>
                </>
            ),
            bodyContent: () => (
                <div className="smm-main-services__content-tab-wrap">
                    <h5 className="smm-main-services__content-tab__title">
                        General</h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        By placing an order with Epromosmm,
                        you automatically accept all the
                        below-listed term of services whether
                        you read them or not.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        By creating an account on our platform,
                        you agree to receive our monthly news
                        letters dedicated only for promotions
                        and service updates information.
                        We reserve the right to change these
                        terms of service without notice. You
                        are expected to read all terms of
                        service before placing every order to
                        ensure you are up to date with any
                        changes or any future changes.
                        You will only use the Epromosmm
                        website in a manner which follows all
                        agreements made with
                        Soundcloud/Vine/Pinterest/Youtube/other
                        social media site on their individual
                        Terms of Service page.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        Epromosmm rates are subject to change
                        at any time without notice. The terms
                        stay in effect in the case of rate
                        changes.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        Epromosmm does not guarantee a
                        delivery time for any services. We
                        offer our best estimation for when the
                        order will be delivered. This is only
                        an estimation and Epromosmm will not
                        refund orders that are processing if
                        you feel they are taking too long.
                        Epromosmm tries hard to deliver
                        exactly what is expected of us by our
                        resellers. In this case, we reserve the
                        right to change a service type if we
                        deem it necessary to complete an order.
                    </p>
                    <h5 className="smm-main-services__content-tab__title terms-title">
                        Disclaimer:
                    </h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        Epromosmm will not be responsible for
                        any damages you or your business may
                        suffer.
                    </p>
                    <h5 className="smm-main-services__content-tab__title terms-title">
                        Liabilities:</h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        Epromosmm is in no way liable for any
                        account suspension or picture deletion
                        done by YouTube or Soundcloud or Vine
                        or Pinterest or Other Social Media.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        You can pay for your order by bank
                        cards Visa, Master Card, Mir or through
                        payment systems Apple Pay,
                        Yandex.Money, QIWI or PayPal (the
                        latter is for customers from all
                        countries, except Russia). To pay for
                        your purchase, you will be redirected
                        to the Unitpay payment system server.
                        When paying with a bank card, the
                        security of payments is guaranteed by
                        the Unitpay processing center.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        The Unitpay payment system has a
                        confirmed certificate of compliance
                        with the PCI DSS standard in terms of
                        storing, processing and transferring
                        cardholder data. PCI DSS Banking
                        Payment Systems Security Standard,
                        including MasterCard and Visa, Inc.
                        Unitpay is also a member of the
                        Continuous Compliance Program PCI DSS
                        Compliance Process (P.D.C.P.). Your
                        confidential data required for payment
                        (card details, registration data, etc.)
                        do not go to the online store - they
                        are processed on the side of the
                        Unitpay processing center and is fully
                        protected.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        Payment cards accepted for payment:
                        VISA, MasterCard, Maestro, MIR. To pay
                        for the goods by credit card when
                        placing an order in the online store,
                        select the payment method: credit card.
                        When paying for an order with a bank
                        card, the payment is processed on the
                        bank's authorization page, where you
                        need to enter your bank card details:
                    </p>
                    <ul className="terms-list">
                        <li>1. Card type</li>
                        <li>2. Card number,</li>
                        <li>3. Card validity period</li>
                        <li>4. Name of the cardholder (in Latin
                            letters, exactly as indicated on
                            the
                            card)
                        </li>
                        <li>5.CVC2 / CVV2 code</li>
                    </ul>
                    <p className="smm-main-services__content-tab__text">
                        If your card is connected to the
                        3D-Secure service, you will be
                        automatically redirected to the page of
                        the bank that issued the card to go
                        through the authentication procedure.
                        For information on the rules and
                        methods of additional identification,
                        check with the Bank that issued your
                        bank card.
                    </p>
                </div>
            )
        },
        {
            id: "services",
            headerContent: () => (
                <>
                    <div className="smm-main-services__content-header__wrap">
                        <h4 className="smm-main-services__content-header__title">
                            Services</h4>
                    </div>
                    <div className="terms__content-header__arrow-wrap">
                        <img
                            src={greenArrow}
                            alt="arrow"
                            className="terms__content-header__arrow"/>
                    </div>
                </>
            ),
            bodyContent: () => (
                <div className="smm-main-services__content-tab-wrap">
                    <h5 className="smm-main-services__content-tab__title">
                        Services</h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        Epromosmm will only be used to promote
                        your YouTube or Soundcloud or Vine or
                        Pinterest or Social account and help
                        boost your "Appearance" only.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        We DO NOT guarantee your new followers
                        will interact with you, we simply
                        guarantee you to get the followers you
                        pay for.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        We DO NOT guarantee 100% of our
                        accounts
                        will have a profile picture, full bio
                        and
                        uploaded pictures, although we strive
                        to
                        make this the reality for all accounts.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        You will not upload anything into the
                        Epromosmm site including nudity or any
                        material that is not accepted or
                        suitable for the YouTube or Soundcloud
                        or Vine or Pinterest or Social Media
                        community.
                    </p>
                    <p className="smm-main-services__content-tab__text">
                        If your page had 100k+
                        followers/likes/views already before
                        you order us, it will not cover refill
                        protection, even if you order for
                        refill server from us, as its not
                        possible to understand from where you
                        lost the followers/likes/views.
                    </p>
                </div>
            )
        },
        {
            id: "refund",
            headerContent: () => (
                <>
                    <div className="smm-main-services__content-header__wrap">
                        <h4 className="smm-main-services__content-header__title">
                            Refund Policy</h4>
                    </div>
                    <div className="terms__content-header__arrow-wrap">
                        <img
                            src={greenArrow}
                            alt="arrow"
                            className="terms__content-header__arrow"/>
                    </div>
                </>
            ),
            bodyContent: () => (
                <div className="smm-main-services__content-tab-wrap">
                    <h5 className="smm-main-services__content-tab__title">
                        Refund Policy</h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        After deposit, if you would like to get
                        refund the remaining balance, you can
                        ask for refund to your payment gateway
                        any time you want. We will refund to
                        your payment gateway within 3-4 Weeks.
                        However, getting money to your card or
                        bank depend on your local bank or card
                        company.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        If you file a dispute or charge-back
                        against us after a deposit without any
                        valid reason, we reserve the right to
                        terminate all future orders, ban you
                        from our site. We also reserve the
                        right to take away any followers or
                        likes we delivered to your or your
                        client's Instagram/Facebook/Twitter or
                        other social media account.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        Orders placed in Epromosmm, will be
                        refunded or canceled if a customer
                        requests for it.Y ou will receive a
                        refund credit to your Epromosmm
                        account if the order is
                        non-deliverable.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        Misplaced or Private account orders
                        will not qualify for a refund. Be sure
                        to confirm each and every order before
                        placing it.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        Fraudulent activity such as using
                        unauthorized or stolen credit cards
                        will lead to termination of your
                        account. There are no exceptions.
                    </p>
                    <p className="smm-main-services__content-tab__text">
                        Please do not use more than one server
                        at the same time for the same page. We
                        cannot give you the correct
                        followers/likes number in that case. We
                        will not refund these orders.
                    </p>
                </div>
            )
        },
        {
            id: "privacy",
            headerContent: () => (
                <>
                    <div className="smm-main-services__content-header__wrap">
                        <h4 className="smm-main-services__content-header__title">
                            Privacy Policy</h4>
                    </div>
                    <div className="terms__content-header__arrow-wrap">
                        <img
                            src={greenArrow}
                            alt="arrow"
                            className="terms__content-header__arrow"/>
                    </div>
                </>
            ),
            bodyContent: () => (
                <div className="smm-main-services__content-tab-wrap">
                    <h5 className="smm-main-services__content-tab__title">
                        Privacy Policy</h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        HI THERE - WE RESPECT YOUR PRIVACY!
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        WE HATE PRIVACY BREACH AS MUCH AS YOU
                        DO. BUT DURING THE TOTAL BUSINESS
                        PROCESS WITH YOU, WE MIGHT NEED TO
                        SHARE SOME OF YOUR DATA WITH THIRD
                        PARTY. DETAILS OF OUR PRIVACY POLICY IS
                        GIVEN BELOW. PLEASE NOTE THAT, BY
                        PLACING ORDER WITH EpromoSMM, YOU
                        AGREE WITH OUR TERMS AND CONDITIONS AND
                        PRIVACY POLICY. WE MIGHT CHANGE THE
                        PRIVACY POLICY TIME TO TIME.
                    </p>
                    <h5 className="smm-main-services__content-tab__title terms-title">
                        PLEASE READ THE FOLLOWING CAREFULLY BEFORE
                        PROCEEDING TO USE EpromoSMM:
                    </h5>
                    <h5 className="smm-main-services__content-tab__title terms-title">
                        Data Collection:
                    </h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        This document describes the official
                        privacy policy of EpromoSMM. The user
                        is advised to read the privacy policy
                        and ensure that he/she has no ambiguity
                        with reference to any point of the
                        Privacy Policy.
                    </p>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        The user is informed that by visiting,
                        using and continuing to use EpromoSMM
                        or subscribing to any package of the
                        Services, it shall be presumed that the
                        user has accepted all the points of the
                        Privacy Policy. If you do not agree to
                        the Privacy Policy, please do not use
                        epromosmm.com and the Services.
                    </p>
                    <h5 className="smm-main-services__content-tab__title terms-title">
                        What information we collect:
                    </h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        We might collect the following
                        information from our user and use for
                        the purpose of completing the
                        transaction, job, and maintenance of
                        our financial or strategic information:
                    </p>
                    <ul className="terms-list">
                        <li> Name</li>
                        <li>Business Name</li>
                        <li>User name of the client’s social
                            media account
                        </li>
                        <li>Email</li>
                        <li>Phone number</li>
                        <li>User preferences and history of
                            internet use for optimization of
                            the services by employing the
                            cookies.
                        </li>
                    </ul>
                    <h5 className="smm-main-services__content-tab__title terms-title">
                        What are Cookies?</h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        The cookies are a small piece of data
                        consisting of a text-only string of
                        information introduced by a particular
                        website and are stored in the hard disk
                        of the machine. They collect data
                        relating to the browsing preferences
                        and history, and such other information
                        the user has provided to the website.
                    </p>
                    <h5 className="smm-main-services__content-tab__title terms-title">
                        Our Cookies</h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        Our cookies aim to give the user an
                        optimal use of epromosmm.com. The
                        collected information is also used for
                        general marketing and statistical
                        analysis. Our cookies do not collect
                        personal data of user. Some cookies are
                        communicated to the user’s hard disk by
                        the third party. EpromoSMM has no
                        affiliation with such party. EpromoSMM
                        disclaims any liability arising from
                        such cookies. The client is advised to
                        read the respective policy of the third
                        party or block their entrance.
                    </p>
                    <h5 className="smm-main-services__content-tab__title terms-title">
                        How to Stop Cookies</h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        By default, the browsers are set to
                        allow cookies. The user may opt to
                        disable cookies if he/she does not want
                        them to process in user’s machine by
                        changing browser settings. It is also
                        possible to configure browser setting
                        allowing some and denying the others to
                        have access to the user’s computer or
                        set the browser to notify when a cookie
                        knock. The ‘Help’ section of the
                        browser may assist the user to manage
                        the cookies preference.
                    </p>
                    <h5 className="smm-main-services__content-tab__title terms-title">
                        Security and Confidentiality of the
                        Information</h5>
                    <p className="smm-main-services__content-tab__text terms-paragraph">
                        EpromoSMM respect the privacy of the
                        user. EpromoSMM employs a
                        sophisticated security system to make
                        it sure that such information is within
                        the trusted staff of EpromoSMM and
                        uses it on the ‘need-to-know basis’.
                        The payment-related information is
                        encrypted with the help of the highly
                        reliable technology SSL. EpromoSMM has
                        taken all the possible steps to
                        safeguard the information relating to
                        the user is safe. EpromoSMM disclaims
                        the liability in case of EpromoSMM
                        fails to maintain the security due to
                        the cause beyond the reasonable
                        control.
                    </p>
                    <h5 className="smm-main-services__content-tab__title terms-title">
                        Modifications</h5>
                    <p className="smm-main-services__content-tab__text">
                        EpromoSMM may change, modify or amend
                        the Privacy Policy at any time without
                        prior notice. The amended version of
                        Privacy Policy shall immediately be
                        effective after it is posted on
                        epromosmm.com
                    </p>
                </div>
            )
        },
    ];

    const [searchParams] = useSearchParams();

    function getActiveId() {
        const getParam = searchParams.get("tab");

        const isActive = items.filter(item => item.id === getParam).length;

        return isActive ? getParam : items[0].id;
    }

    return (
        <div className="main-bg">
            <div className="wrapper-content">
                <Breadcrumbs paths={paths}/>
                <section className="terms-header">
                    <div className="terms-header__wrap">
                        <h2 className="smm-title terms-header__title">Terms
                            of Service & Privacy Policy</h2>
                        <p className="terms-header__text">The use of
                            services provided by Epromodigital.</p>
                    </div>
                </section>
                <section className="terms">
                    <Tab items={items}
                         activeId={getActiveId()}
                         customClassName="terms__content"
                    />
                </section>
            </div>
        </div>
    )
}

export default TermsPage;
