import {NavLink} from "react-router-dom";
import logo from "../../../assets/media/smm-panel-logo-main.svg";
import user from "../../../assets/media/user.png";
import orders from "../../../assets/media/navigation_elements/checklist.svg";
import history from "../../../assets/media/navigation_elements/orders.svg";
import payments from "../../../assets/media/navigation_elements/funds.svg";
import settings from "../../../assets/media/navigation_elements/settings.svg";
import faq from "../../../assets/media/navigation_elements/faq.svg";
import tickets from "../../../assets/media/icons/call-center.svg";
import {useNavActive} from "../../../hooks/navActive.hook";
import {useSelector} from "react-redux";
import {userSelector} from "../../pages/settingsPage/UserSlice";


const PanelMenu = ({isActiveMenu, onClickBurger}) => {

    const defaultClassLink = "header-panel__list-item";
    const activeClassLink = "header-panel__list-item-active";

    const {userData, userBalance} = useSelector(userSelector);

    const {setNavClass} = useNavActive(defaultClassLink, activeClassLink);

    const getActiveMobileMenu = () => {
        const def = "header-panel";
        return isActiveMenu ? `${def} smm-panel-content__header-burger-mobile-active` : def;
    }

    return (
        <header className={getActiveMobileMenu()}>
            <div className="header-panel__logo-wrapper">
                <NavLink to="/" className="smm-link" onClick={onClickBurger}>
                    <div className="header-panel__logo">
                        <img src={logo} alt="logo"/>
                    </div>
                </NavLink>
            </div>
            <div className="header-panel__profile">
                <div className="header-panel__profile-wrapper">
                    <NavLink to="/" className="smm-link" onClick={onClickBurger}>
                        <div className="header-panel__profile-wrap">
                            <img src={user} alt="user"/>
                        </div>
                    </NavLink>
                    <div className="header-panel__profile-info">
                        <div className="smm-link">
                            <h6 className="header-panel__profile-info-name">{userData.first_name}</h6>
                        </div>
                        <div className="header-panel__profile-info-wrap">
                            <p className="header-panel__profile-info-balance">Balance:</p>
                            <p className="header-panel__profile-info-amount">${userBalance}</p>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="header-panel__navigation">
                <div className="header-panel__list">
                    <NavLink to={"/"}
                             onClick={onClickBurger}
                             className={({isActive}) => setNavClass(isActive)}>
                        <div className="header-panel__list-item-wrap">
                            <div className="header-panel__list-item-img-wrap">
                                <img src={orders}
                                     alt="new-order"/>
                            </div>
                            <p className="header-panel__list-item-text">New
                                order</p>
                        </div>
                    </NavLink>
                    <NavLink to={"/history/"}
                             onClick={onClickBurger}
                             className={({isActive}) => setNavClass(isActive)}>
                        <div className="header-panel__list-item-wrap">
                            <div
                                className="header-panel__list-item-img-wrap">
                                <img src={history}
                                     alt="history"/>
                            </div>
                            <p className="header-panel__list-item-text">History</p>
                        </div>
                    </NavLink>
                    <NavLink to={"/payments/"}
                             onClick={onClickBurger}
                             className={({isActive}) => setNavClass(isActive)}>
                        <div className="header-panel__list-item-wrap">
                            <div
                                className="header-panel__list-item-img-wrap">
                                <img src={payments}
                                     alt="funds"/>
                            </div>
                            <p className="header-panel__list-item-text">Payments</p>
                        </div>
                    </NavLink>
                    <NavLink to={"/faq/"}
                             onClick={onClickBurger}
                            className={({isActive}) => setNavClass(isActive)}>
                        <div className="header-panel__list-item-wrap">
                            <div
                                className="header-panel__list-item-img-wrap">
                                <img src={faq}
                                     alt="faq"/>
                            </div>
                            <p className="header-panel__list-item-text">FAQ</p>
                        </div>
                    </NavLink>
                    <NavLink to={"/tickets/"}
                             onClick={onClickBurger}
                             className={({isActive}) => setNavClass(isActive)}>
                        <div className="header-panel__list-item-wrap">
                            <div
                                className="header-panel__list-item-img-wrap">
                                <img src={tickets}
                                     alt="support-tickets"/>
                            </div>
                            <p className="header-panel__list-item-text">Tickets</p>
                        </div>
                    </NavLink>
                    <NavLink to={"/settings/"}
                             onClick={onClickBurger}
                             className={({isActive}) => setNavClass(isActive)}>
                        <div className="header-panel__list-item-wrap">
                            <div
                                className="header-panel__list-item-img-wrap">
                                <img src={settings}
                                     alt="settings"/>
                            </div>
                            <p className="header-panel__list-item-text">Settings</p>
                        </div>
                    </NavLink>
                </div>
            </nav>
        </header>
    )
}

export default PanelMenu;