import Wrapper from "../../../parts/wrapper/Wrapper";
import TermsPage from "../../termsPage/TermsPage";
import {Navigate, Route, Routes} from "react-router-dom";
import HomePage from "../../homePage/HomePage";
import PostsListPage from "../../blogPages/postsListPage/PostsListPage";
import PostDetailPage from "../../blogPages/postDetailPage/PostDetailPage";
import UnAuthFaq from "../../faqPage/unAuthFaq/UnAuthFaq";
import ContactPage from "../../contactPage/ContactPage";


const UnAuthPages = () => (
    <Wrapper>
        <Routes>
            <Route path="" element={<HomePage/>}/>
            <Route path={"blog/:slug/"} element={<PostDetailPage/>}/>
            <Route path={"blog/"} element={<PostsListPage/>}/>
            <Route path="terms/" element={<TermsPage/>}/>
            <Route path={"faq/"} element={<UnAuthFaq/>}/>
            <Route path={"contact-us/"} element={<ContactPage/>}/>
            <Route path={"*"} element={<Navigate to={"not-found/"}/>} />
        </Routes>
    </Wrapper>
)

export default UnAuthPages;