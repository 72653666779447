import useParseDate from "../../../../hooks/dateParse.hook";

const PaymentItem = ({id, method, amount, created_at, status}) => {

    const formattingCreatedAt = useParseDate(created_at, true)

    let statusColor = {
        'Canceled': '#ff0101',
        'Processing': '#00acee',
        'Partial': '#1967be',
        'Completed': '#3fb618',
        'In progress': '#9954bb',
        'Pending': '#ff7518'
    }

    const setColor = () => {
        switch (status) {
            case "Completed":
                return "#3fb618";
            case "Canceled":
                return "#ff0101";
            case "Pending":
                return "#ff7518";
            default:
                return "#00acee";
        }
    }

    return (
        <tr>
            <td>
                <span className="table-body-id">{id}</span>
            </td>
            <td>
                <span className="table-body-method">{method}</span>
            </td>
            <td>
                <span className="table-body-amount">+${amount}</span>
            </td>
            <td>
                <span
                    className="table-body-created-at">{formattingCreatedAt}</span>
            </td>
            <td>
                <span className="element-status" style={{backgroundColor: setColor()}}>{status}</span>
            </td>
        </tr>
    )
}

export default PaymentItem;