import greenArrow
    from "../../../../assets/media/additional/green-arrow-20.svg";
import {useEffect, useRef, useState} from "react";

const AccordionItem = ({isActive, onClickItem, title, text}) => {
    const [height, setHeight] = useState("0px");

    const defaultClass = "accordion-item";
    const activeClass = " accordion-item-active";

    const paddingTop = 20,
        paddingBottom = 32;

    const ref = useRef(null);

    useEffect(() => {
        if (isActive) {
            const height = (paddingTop + ref.current.offsetHeight + paddingBottom) + 'px';
            setHeight(height);
        } else {
            setHeight("0px");
        }
    }, [isActive]);

    const getItemClassName = () => {

        return isActive ? defaultClass + activeClass : defaultClass;
    }

    return (
        <div className={getItemClassName()} onClick={onClickItem}>
            <div className="accordion-item-header">
                <h4 className="accordion-item-header__title">
                    {title}
                </h4>
                <div className="accordion-item-header__arrow-wrap">
                    <img className="accordion-item-header__arrow"
                         src={greenArrow}
                         alt="arrow"/>
                </div>
            </div>
            <div className="accordion-item-body" style={{height}}>
                <div ref={ref} className="accordion-item-body__content">
                    <p className="accordion-item-body__content-text">
                        {text}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AccordionItem;