/**
 * Capitalizes the first letter of a given string.
 *
 * @param {string} string - The input string to capitalize.
 *
 * @return {string} The input string with the first letter capitalized.
 */
export default function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.substring(1);
}

