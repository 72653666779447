import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import usePaymentsServices from "../../../services/PaymentsServices";;


const initialState = {
    payments: [],
    paymentsLoading: "idle",
    paymentSubmitting: "idle"
}

export const fetchPayments = createAsyncThunk(
    "payments/fetchPayments", async (data, {rejectWithValue}) => {
        const {getPayments} = usePaymentsServices();
        try {
            return await getPayments();
        } catch (e) {
            return rejectWithValue(e.response);
        }
    });

export const createPayment = createAsyncThunk(
    "payments/createPayment", async (data, {rejectWithValue}) => {
        const {createPayment} = usePaymentsServices();
        try {
            return await createPayment(data);
        } catch (e) {
            return rejectWithValue(e.response);
        }
    });


const paymentsSlice = createSlice({
    name: "payments",
    initialState,
    reducer: {},
    extraReducers: builder => {
        builder.addCase(fetchPayments.pending, state => {
            state.paymentsLoading = "loading";
        })
        builder.addCase(fetchPayments.fulfilled, (state, action) => {
            state.paymentsLoading = "idle"
            state.payments = action.payload.data.payments;
        })
        builder.addCase(fetchPayments.rejected, (state, action) => {
            state.paymentsLoading = "error";
        })
        builder.addCase(createPayment.pending, state => {
            state.paymentsLoading = "loading";
            state.paymentSubmitting = "loading";
        });
        builder.addCase(createPayment.fulfilled, (state, action) => {
            const {payment} = action.payload.data;
            state.paymentsLoading = "idle";
            state.paymentSubmitting = "idle";
            state.payments = [payment, ...state.payments]
        })
        builder.addCase(createPayment.rejected, (state, action) => {
            state.paymentsLoading = "idle";
            state.paymentSubmitting = "idle";
        })
    }
});

const {reducer, actions} = paymentsSlice;

export default reducer;

// export const {createPayment} = actions;