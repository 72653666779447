import {Component} from "react";
import Preloader from "../preloader/Preloader";


class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {error: true};
    }

    render() {
        const {error} = this.state;

        return error ? <Preloader/> : this.props.children;
    }
}

export default ErrorBoundary;