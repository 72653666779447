import {NavLink} from "react-router-dom";
import useParseDate from "../../../../hooks/dateParse.hook";


const HistoryItem = ({order_id, link, service, quantity, spent, created_at, status}) => {

    const formattingCreatedAt = useParseDate(created_at, true);

    // let statusColor = {
    //     'Canceled': '#ff0101',
    //     'Processing': '#00acee',
    //     'Partial': '#1967be',
    //     'Completed': '#3fb618',
    //     'In progress': '#9954bb',
    //     'Pending': '#ff7518'
    // }

    const setColor = () => {
        switch (status) {
            case "Completed":
                return "#3fb618";
            case "Canceled":
                return "#ff0101";
            case "Pending":
                return "#ff7518";
            default:
                return "#00acee";
        }
    }

    return (
        <tr>
            <td>
                <span className="table-body-id">{order_id}</span>
            </td>
            <td>
                <NavLink target="_blank"
                   to={link}
                   className="table-body-link">{link}</NavLink>
            </td>
            <td><span
                className="table-body-service">{service.name}</span>
            </td>
            <td><span
                className="table-body-quantity">{quantity}</span>
            </td>
            <td><span
                className="table-body-spent">-${spent}</span>
            </td>
            <td>
                <span className="table-body-created-at">{formattingCreatedAt}</span>
            </td>
            <td>
                <span className="element-status"
                      style={{backgroundColor: setColor()}}>{status}</span>
            </td>
        </tr>
    )
}

export default HistoryItem;