import {Form, Formik} from "formik";
import * as Yup from "yup";
import PanelPasswordInput
    from "../../../parts/panelPasswordInput/PanelPasswordInput";
import useUserServices from "../../../../services/UserServices";
import {useDispatch} from "react-redux";
import {setNotification} from "../../../parts/notification/NotificationSlice";
import {useState} from "react";
import Spinner from "../../../parts/spinner/Spinner";


const ChangePasswordsForm = () => {

    const dispatch = useDispatch()
    const [isFetching, setIsFetching] = useState(false);
    const {userChangePassword} = useUserServices();

    const initialValues = {
        current_password: "",
        password: "",
        password_confirmation: ""
    }

    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$");

    const PasswordsSchema = Yup.object().shape({
        current_password: Yup.string()
            .trim()
            .required("Current password is required"),
        password: Yup.string()
            .trim()
            .matches(
                passwordRegex,
                "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number")
            .required("Password is required"),
        password_confirmation: Yup.string()
            .trim()
            .oneOf([Yup.ref('password')], "Passwords don't match, please try again")
            .required("Password Confirmation is required"),
    });


    const onSubmitForm = (values) => {
        setIsFetching(true);
        userChangePassword(values)
            .then(response => {
                setIsFetching(false);
                dispatch(setNotification(response.data.notification));
            })
            .catch(error => {
                setIsFetching(false);
                const notification = error?.response?.data?.notification;
               if (notification){
                   dispatch(setNotification(notification));
               } else {
                   dispatch(setNotification({
                        "type": "error",
                        "text": "Server error"
                    }))
               }
            });
    }

    if (isFetching) {
        return <Spinner/>
    }

    return (
        <>
            <h4 className="smm-panel-content-block__title">
                Change password</h4>
            <Formik initialValues={initialValues}
                    validationSchema={PasswordsSchema}
                    onSubmit={onSubmitForm}>
                {props => (
                    <Form className="change-password__form" method="post">
                        <div className="smm-panel-content__form-wrap">
                            <PanelPasswordInput name="current_password"
                                                labelText={"Current Password"}
                                                errors={props.errors}/>
                            <PanelPasswordInput name="password"
                                                labelText={"New Password"}
                                                errors={props.errors}/>
                            <PanelPasswordInput
                                name="password_confirmation"
                                labelText={"Confirm Password"}
                                errors={props.errors}/>
                            <button type="submit"
                                    className="smm-btn__green change-settings__btn">
                                Change password
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ChangePasswordsForm;