import second from "../../../../assets/media/home/faq/second_image.png";
import Accordion from "../../../parts/accordion/Accordion";

const FaqSmmSecond = () => {

    const items = [
        {
            id: "what-is-panel",
            title: "What is an SMM Panel ?",
            text: "The principle of the SMM panel is that it is a tool that helps users" +
                " by providing them with an interface to get in touch with followers."
        },
        {
            id: "what-is-smm",
            title: "What is an Social Media Marketing Service ?",
            text: "Social media marketing (SMM) is a form of marketing that will mainly" +
                " use social media applications to carry out all campaigns." +
                " The main objective of social network marketing is to help companies build their" +
                " brands and increase their sales."
        },
        {
            id: "who-offer",
            title: "Who offer the Best SMM Service ?",
            text: "It is a set of services with which your company can have a" +
                " greater notoriety by having more likes, more views and more followers. "
        },
        {
            id: "what-is-worldwide",
            title: "What is an Worldwide SMM Panel ?",
            text: "The platform was designed to help you gain access to Internet" +
                " users and to allow companies to have a larger audience." +
                " Thus, epromosmm.com has designed its services to be usable in all" +
                " countries and on all continents. "
        },
        {
            id: "what-is-cheapest",
            title: "Which is the Cheapest SMM Panel ?",
            text: "The services offered by the epromosmm.com platform are cheap," +
                " but rich enough to allow you to strengthen" +
                " your presence in the minds of Internet users. "
        },
        {
            id: "what-is-payment",
            title: "What is an SMM Panel Payment Methods ?",
            text: "You can buy a services with Credit card / Debit card, USDT / USDC / BUSD, PayTm SMM Panel, Cardlink USD, PayOp, Advcash, Gcash, Perfect Money, CoinPayments,Coinbase, Payeer, WebMoney, CashMaal / EasyPaisa / JazzCash, Paypal SMM Panel, Enot. "
        },
        {
            id: "what-is-wholesale",
            title: "What is an Wholesale SMM Panel ?",
            text: "The SMM panel is a set of tools available to companies to buy likes," +
                " views, comments or followers online." +
                " And, to ensure effective online services, it is necessary to" +
                " turn to a good provider such as epromosmm.com. "
        },
        {
            id: "what-is-countries",
            title: "Which is the Countries using SMM Panel ?",
            text: "The concept of social media marketing or SMM panels was born and spread" +
                " very quickly all over the world."
        },
    ]

    return (
        <section className="faq-smm faq-smm-second">
            <div className="wrapper-content">
                <h2 className="faq-smm__title faq-smm-second__title">FAQ SMM
                    Panel</h2>
                <div className="faq-smm__inner faq-smm-second__inner">
                    <div className="faq-smm__wrap faq-smm-second__wrap">
                        <p className="faq-smm__text">In the field of digital
                            marketing, you can get help from the SMM panel. It
                            is simply an online service with which you can buy
                            followers, likes and views on social media. The SMM
                            panel can be a good alternative to increase your
                            online presence and boost the productivity of your
                            business. This type of service is also a good way
                            to reach a larger audience on the internet.</p>
                        <div className="faq-smm__img-wrap">
                            <img
                                src={second}
                                alt="faq-image"/>
                        </div>
                    </div>
                    <Accordion items={items} itemClass={"faq-smm__accordion faq-smm-second__content"}/>
                    <div className="faq-smm__img-wrap-mobile ">
                        <img
                            src={second}
                            alt="faq-image"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FaqSmmSecond;