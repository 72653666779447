import Breadcrumbs from "../../parts/breadcrumbs/Breadcrumbs";
import ContactForm from "./contactForm/ContactForm";


const ContactPage = () => {

    const paths = [
        {
            name: "Home",
            path: "/"
        },
        {
            name: "Contact us",
            path: "/contact-us/"
        },
    ];

    return (
        <div className="main-bg">
            <div className="wrapper-content">
                <Breadcrumbs paths={paths}/>
                <section className="contact-header">
                    <h2 className="smm-title contact-header__title">Contact
                        us</h2>
                </section>
                <section className="contact-info">
                    <div className="contact-social-items">
                        <div className="contact-social-item">
                            <div
                                className="contact-social-title">phone
                            </div>
                            <div
                                className="contact-social-content contact-social-content-number">
                                <a href="tel:+18888888888">+1 (888)
                                    888-8888</a>
                            </div>
                        </div>
                        <div className="contact-social-item">
                            <div
                                className="contact-social-title">email
                            </div>
                            <div
                                className="contact-social-content contact-social-content-email">
                                <a href="mailto:info@epromosmm.com">info@epromosmm.com</a>
                                <a href="mailto:accounting@epromosmm.com">accounting@epromosmm.com</a>
                                <a href="mailto:support@epromosmm.com">support@epromosmm.com</a>
                            </div>
                        </div>
                        <div className="contact-social-item">
                            <div
                                className="contact-social-title">messengers
                            </div>
                            <div
                                className="contact-social-content contact-social-content-messengers">
                                <div
                                    className="contact-social-messengers">
                                    <div
                                        className="contact-social-messenger">
                                        <a href="#">
                                            <svg width="35"
                                                 height="35"
                                                 viewBox="0 0 35 35"
                                                 fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect width="35"
                                                      height="35"
                                                      rx="17.5"
                                                      fill="#08D565"></rect>
                                                <g clipPath="url(#clip0_1714_14091)">
                                                    <path
                                                        d="M25.0282 9.90436C23.1494 8.03256 20.65 7.00107 17.9899 7C15.3358 7 12.8325 8.03058 10.9413 9.90192C9.04675 11.7765 8.00244 14.2676 8 16.9074V16.9104V16.9123C8.00031 18.51 8.42007 20.1218 9.21689 21.593L8.02731 27L13.4965 25.756C14.8817 26.454 16.4273 26.8221 17.9861 26.8227H17.9901C20.6437 26.8227 23.1471 25.792 25.0386 23.9205C26.9348 22.0444 27.9796 19.5565 27.9808 16.915C27.9815 14.2922 26.9331 11.8024 25.0282 9.90436ZM17.9899 25.2617H17.9864C16.5867 25.2611 15.1999 24.9097 13.976 24.2452L13.7173 24.1048L10.0805 24.932L10.8705 21.3419L10.7182 21.0793C9.96106 19.7736 9.56097 18.3322 9.56097 16.9106C9.56387 12.309 13.3447 8.56097 17.9896 8.56097C20.2336 8.56189 22.342 9.43179 23.9265 11.0102C25.5349 12.6129 26.4204 14.7098 26.4196 16.9146C26.4178 21.5172 22.6362 25.2617 17.9899 25.2617Z"
                                                        fill="white"></path>
                                                    <path
                                                        d="M15.2729 12.542H14.8349C14.6825 12.542 14.435 12.5991 14.2256 12.8269C14.0161 13.0548 13.4258 13.6058 13.4258 14.7264C13.4258 15.847 14.2447 16.9298 14.3589 17.0819C14.4731 17.2339 15.9397 19.607 18.2624 20.5199C20.1927 21.2786 20.5857 21.1277 21.0045 21.0897C21.4235 21.0518 22.3566 20.5388 22.547 20.0071C22.7375 19.4753 22.7375 19.0193 22.6804 18.9241C22.6232 18.8292 22.4707 18.7723 22.2423 18.6585C22.0137 18.5445 20.8937 17.9842 20.6842 17.9081C20.4747 17.8322 20.3224 17.7942 20.17 18.0223C20.0176 18.25 19.5688 18.7767 19.4355 18.9287C19.3022 19.0808 19.1689 19.0999 18.9403 18.9859C18.7117 18.8716 17.9833 18.6269 17.1103 17.8511C16.4308 17.2473 15.9594 16.4774 15.826 16.2494C15.6928 16.0216 15.8118 15.8983 15.9264 15.7846C16.0291 15.6827 16.1678 15.5432 16.2821 15.4103C16.3962 15.2773 16.4287 15.1824 16.505 15.0304C16.5811 14.8784 16.543 14.7454 16.4859 14.6315C16.4287 14.5175 15.9902 13.3913 15.7869 12.9409H15.7871C15.6159 12.5615 15.4357 12.5487 15.2729 12.542Z"
                                                        fill="white"></path>
                                                </g>
                                                <defs>
                                                    <clipPath
                                                        id="clip0_1714_14091">
                                                        <rect
                                                            width="20"
                                                            height="20"
                                                            fill="white"
                                                            transform="translate(8 7)"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                    </div>
                                    <div
                                        className="contact-social-messenger">
                                        <a href="#">
                                            <svg width="35"
                                                 height="35"
                                                 viewBox="0 0 35 35"
                                                 fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect width="35"
                                                      height="35"
                                                      rx="17.5"
                                                      fill="#08D565"></rect>
                                                <g clipPath="url(#clip0_1714_14095)">
                                                    <path
                                                        d="M12.7131 27.0381C12.4299 27.0381 12.1489 26.9591 11.9021 26.8035C11.4752 26.5338 11.2106 26.0717 11.1944 25.5667L11.1451 23.9499C9.11755 22.1318 8 19.5792 8 16.7627C8 14.0746 9.03043 11.6025 10.9012 9.80182C12.7523 8.0202 15.2734 7.03906 18 7.03906C20.7266 7.03906 23.2477 8.0202 25.0988 9.80182C26.9696 11.6025 28 14.0746 28 16.7627C28 19.4507 26.9696 21.9228 25.0988 23.7235C23.2477 25.5051 20.7266 26.4864 18 26.4864C17.017 26.4864 16.0551 26.3593 15.1408 26.1087L13.3258 26.9095C13.1291 26.9957 12.9207 27.0381 12.7131 27.0381ZM18 8.60156C13.1898 8.60156 9.5625 12.11 9.5625 16.7627C9.5625 19.1326 10.4983 21.2749 12.1975 22.795C12.511 23.0773 12.6909 23.4597 12.706 23.8735L12.7061 23.8784L12.7543 25.4538L14.5239 24.673C14.8425 24.5328 15.2018 24.5058 15.5365 24.597C16.3229 24.814 17.151 24.9237 18 24.9237C22.8102 24.9237 26.4375 21.4153 26.4375 16.7627C26.4375 12.11 22.8102 8.60156 18 8.60156ZM13.0835 19.9543L16.0032 17.7031C16.2005 17.5551 16.4721 17.5531 16.6713 17.7013L18.8318 19.3807C19.4793 19.8639 20.4062 19.6935 20.8378 19.0122L23.5534 14.7058C23.8157 14.2948 23.3059 13.8301 22.917 14.1245L19.9986 16.3359C19.8013 16.4841 19.5297 16.4859 19.3306 16.3378L17.1701 14.6976C16.5225 14.2144 15.5957 14.3846 15.164 15.0659L12.447 19.3727C12.1847 19.7838 12.6945 20.2486 13.0835 19.9543Z"
                                                        fill="white"></path>
                                                </g>
                                                <defs>
                                                    <clipPath
                                                        id="clip0_1714_14095">
                                                        <rect
                                                            width="20"
                                                            height="20"
                                                            fill="white"
                                                            transform="translate(8 7)"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                    </div>
                                    <div
                                        className="contact-social-messenger">
                                        <a href="https://t.me/epromodigital"
                                           target="_blank"
                                           title="Telegram">
                                            <svg width="35"
                                                 height="35"
                                                 viewBox="0 0 35 35"
                                                 fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.5"
                                                      y="0.5"
                                                      width="34"
                                                      height="34"
                                                      rx="17"
                                                      fill="#08D565"></rect>
                                                <g clipPath="url(#clip0_2436_228733)">
                                                    <path
                                                        d="M27.6084 8.63855C27.2866 8.34374 26.8293 8.2455 26.4148 8.3821L8.82934 14.179C8.35009 14.337 8.02509 14.7648 8.00138 15.2688C7.9777 15.7729 8.2611 16.2293 8.72337 16.4316L13.0724 18.3344C13.0936 18.4329 14.3421 24.2234 14.3845 24.4202C14.439 24.673 14.5674 24.8982 14.8392 24.9675C15.1142 25.0375 15.3084 24.8872 15.5136 24.7336C15.6277 24.6482 18.7409 22.317 18.7409 22.317L22.5139 25.4045C22.7337 25.5844 23.0023 25.6782 23.2761 25.6782C23.4065 25.6782 23.5382 25.6569 23.6662 25.6135C24.0633 25.4789 24.3577 25.1548 24.4537 24.7466L27.968 9.80511C28.0679 9.38031 27.9301 8.93335 27.6084 8.63855ZM15.7947 19.4248C15.7925 19.4299 15.7904 19.4356 15.7884 19.4425L15.0609 21.9842L14.2491 18.2188L19.8318 15.117L15.9286 19.1982C15.8671 19.2625 15.8219 19.3402 15.7947 19.4248ZM16.0256 22.8828L16.356 21.7283L16.6721 20.6241L17.8045 21.5508L16.0256 22.8828ZM26.8245 9.53613L23.3103 24.4776C23.3085 24.485 23.3062 24.4953 23.289 24.5011C23.272 24.5069 23.2637 24.5003 23.2578 24.4954L19.1293 21.117C19.1291 21.1169 19.1291 21.1168 19.129 21.1167L17.2161 19.5514L23.3615 13.1257C23.5622 12.9158 23.5794 12.5908 23.402 12.3609C23.2244 12.131 22.9056 12.0653 22.6518 12.2064L13.5522 17.2622L9.19423 15.3555C9.18095 15.3497 9.17364 15.3464 9.1747 15.324C9.17575 15.3016 9.18333 15.2991 9.19708 15.2945L26.7825 9.49765C26.7911 9.49488 26.8007 9.49167 26.8148 9.50456C26.829 9.51753 26.8266 9.52738 26.8245 9.53613Z"
                                                        fill="white"></path>
                                                </g>
                                                <rect x="0.5"
                                                      y="0.5"
                                                      width="34"
                                                      height="34"
                                                      rx="17"
                                                      stroke="#08D565"></rect>
                                                <defs>
                                                    <clipPath
                                                        id="clip0_2436_228733">
                                                        <rect
                                                            width="20"
                                                            height="20"
                                                            fill="white"
                                                            transform="translate(8 7)"></rect>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section className="ln-section ln-section-map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4289.898099216002!2d-119.8040489479654!3d39.524914698269434!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809940b3fe0f83e7%3A0x3a4cdfbe5c736d61!2s401%20Ryland%20St%20%23202%2C%20Reno%2C%20NV%2089502%2C%20USA!5e0!3m2!1sen!2sua!4v1689670570060!5m2!1sen!2sua"></iframe>
                <div className="wrapper-content">
                    <div className="contact-form__wrapper">
                        <h2 className="auth-form__title">Contact
                            us</h2>
                        <ContactForm/>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ContactPage;