import CheapestResellerBlock
    from "./cheapestResellerBlock/CheapestResellerBlock";

const CheapestReseller = () => {

    const blocks = [
        {
            header: "What is an SMM panel reseller ?",
            content: () => (
                <>
                    <p className="cheapest-reseller__content-block-body__text">
                        SMM panel resellers are providers who
                        hold many marketing services on social
                        networks and can resell them. In fact,
                        there are more SMM panel resellers than
                        so-called "original" providers. In
                        fact, all SMM providers can at some
                        point be called resellers. How do SMM
                        panel resellers work and how do I
                        become an SMM panel reseller?</p>
                    <h6 className="cheapest-reseller__content-block-body__title">
                        How to become an SMM panel
                        reseller?</h6>
                    <p className="cheapest-reseller__content-block-body__text">
                        SMM panel resellers are actually
                        private companies that allow social
                        network marketing service distributors
                        to use their brand by offering the same
                        services to other companies. We also
                        note that some distributors are also
                        able to position themselves as
                        resellers to offer SMM services.</p>
                </>
            )
        },
        {
            header: "What is an SMM panel provider ?",
            content: () => (
                <>
                    <h6 className="cheapest-reseller__content-block-body__title">
                        What SMM services do providers?</h6>
                    <p className="cheapest-reseller__content-block-body__text">
                        Most SMM providers offer many marketing
                        actions to customers. These services
                        include website traffic, subscribers,
                        views, comments, likes and comments, as
                        well as actions requested by companies
                        to increase their online awareness. The
                        SMM services can also be adapted to the
                        needs of the companies. Some services
                        are tailor-made to achieve the
                        company's goal on the internet.
                        However, this kind of customized
                        service is usually more expensive than
                        standard services.
                    </p>
                    <h6 className="cheapest-reseller__content-block-body__title">
                        Should you choose an original or a
                        reseller provider?</h6>
                    <p className="cheapest-reseller__content-block-body__text">
                        There is a tendency to believe that the
                        reseller provider doesn't have the best
                        social media marketing services when
                        compared to the original provider. But,
                        if you take the time to look for an
                        original provider, you may be wasting
                        time. This is because there is no such
                        thing as a better provider, but rather
                        a better service.
                    </p>
                </>
            )
        },
        {
            header: "What is an Child SMM panel ?",
            content: () => (
                <>
                    <h6 className="cheapest-reseller__content-block-body__title">
                        What are the advantages of having a
                        child panel?</h6>
                    <p className="cheapest-reseller__content-block-body__text">
                        Therefore, it is enough to turn to a
                        trusted regular panel owner in order to
                        have a quality service. It is also good
                        to know that with a child panel, one
                        can register several staff members, and
                        if one needs to increase the number of
                        staff, one just needs to turn to the
                        regular panel owner. However, the
                        number is quite small compared to the
                        number of people that can be inserted
                        with a regular panel.
                    </p>
                </>
            )
        },
    ];

    const renderBlocks = () =>{

        return blocks.map((block, ind) => {
            return <CheapestResellerBlock key={ind} header={block.header} content={block.content()}/>
        });
    }

    return (
        <section className="cheapest-reseller">
            <div className="wrapper-content">
                <div className="cheapest-reseller__header">
                    <h2 className="cheapest-reseller__header-title">Cheapest
                        SMM
                        Reseller Panel</h2>
                    <p className="cheapest-reseller__header-text">Do you want
                        to
                        start your own project? We offer you SMM Reseller
                        Panel, a new way to sell SMM services in white
                        label.</p>
                </div>
                <div className="cheapest-reseller__content">
                    {renderBlocks()}
                </div>
            </div>
        </section>
    )
}

export default CheapestReseller;