import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import useUserServices from "../../../services/UserServices";


const initialState = {
    userLoading: "idle",
    userData: {},
    userStats: {},
    userBalance: 0,
};

export const userDetail = createAsyncThunk("user/userDetail", async (data, {rejectWithValue}) => {
    const {userDetail} = useUserServices();
    try {
        return await userDetail();
    } catch(error){
        return rejectWithValue(error.response);
    }
});

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setNewEmail: (state, action) => {
          state.userData.email = action.payload;
        },
        setUserPersonalInfo: (state, action) => {
            state.userData.first_name = action.payload.first_name;
            state.userData.last_name = action.payload.last_name;
        },
        updateBalance: (state, action) => {
            state.userBalance = action.payload;
        }
        // writeOffBalance: (state, action) => {
        //     state.userBalance -= action.payload.toFixed(2);
        // },
        // topUpBalance: (state, action) => {
        //     state.userBalance += action.payload;
        // },
    },
    extraReducers: builder => {
        builder.addCase(userDetail.pending, state => {
            state.userLoading = "loading";
        })
        builder.addCase(userDetail.fulfilled, (state, action) => {
            const {balance, stats, ...user} = action.payload.data;
            state.userLoading = "idle";
            state.userData = user;
            state.userBalance = parseFloat(balance["total_balance"]);
            state.userStats = stats
        })
        builder.addCase(userDetail.rejected, (state, action) => {
            state.userLoading = "error";
        })
    },
})

const {reducer, actions} = userSlice;

export default reducer;

export const userSelector = state => state.user;
export const balanceSelector = state => state.user.userBalance;
export const statsSelector = state => state.user.userStats;

export const {
    setUserPersonalInfo,
    setNewEmail,
    updateBalance,
} = actions;