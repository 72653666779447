import {useRef} from "react";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {NavLink} from "react-router-dom";
import setErrorInput from "../../../../../utils/setErrorInput";
import {useDispatch, useSelector} from "react-redux";
import {authSelector, requestLogin} from "../../AuthSlice";
import getButtonSubmitClass from "../../../../../utils/getButtonSubmitClass";
import setVisiblePassword from "../../../../../utils/setVisiblePassword";
import {
    setNotification
} from "../../../../parts/notification/NotificationSlice";


const SignInForm = () => {

    const {requestLoginLoading} = useSelector(authSelector);
    const dispatch = useDispatch(); // get function dispatch

    const passwordRef = useRef(null);

    // initial values of form
    const initialValues = {
        "email": "",
        "password": "",
    };

    // create schema for validate fields
    const AuthSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .email("Please, write valid email address")
            .required("Email is required"),
        password: Yup.string()
            .trim()
            .required('Password is required')
    });

    // custom validate of password
    const validatePassword = (values) => {
        const errors = {}
        const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$");

        if (!regex.test(values["password"])) {
            errors["password"] = "Minimum eight characters, at least one uppercase letter, one lowercase letter and one number";
        }

        return errors;
    };

    // function for handle submit form
    const onSubmitForm = (values) => {
        dispatch(requestLogin(values)).then(onResponse);
    };

    const onResponse = (response) => {
        // create notification when a response is received.
        const notification = response?.payload?.data?.notification;
        if (notification) {
            dispatch(setNotification(notification));
        } else if (response?.error) {
            dispatch(setNotification({
                "type": "error",
                "text": "Server error"
            }))
        }
    }

    return (
        <Formik initialValues={initialValues}
                validationSchema={AuthSchema}
                validate={validatePassword}
                onSubmit={onSubmitForm}>
            {props => (
                <Form className="auth-form  auth-form__sign-in"
                      method="post">
                    <div
                        className="auth-form__input-inner auth-form__sign-in-input-inner">
                        <div className="auth-form__input-item">
                            <label className="auth-form__label"
                                   htmlFor="email">Email</label>
                            <div className={
                                props.errors.email ?
                                    "auth-form__input-wrap auth-form__input-email error-input" :
                                    "auth-form__input-wrap auth-form__input-email"
                            }>
                                <Field type="email" required
                                       className={setErrorInput(props.errors.email)}
                                       placeholder="Email" id="email"
                                       name="email"/>
                            </div>
                            <ErrorMessage name="email"
                                          className="error-label"
                                          component="p"/>
                        </div>
                        <div className="auth-form__input-item">
                            <label className="auth-form__label"
                                   htmlFor="password">Password</label>
                            <div className={
                                props.errors.password ?
                                    "auth-form__input-wrap auth-form__input-password error-input" :
                                    "auth-form__input-wrap auth-form__input-password"
                            }>
                                <Field
                                    type="password"
                                    required
                                    className={setErrorInput(props.errors.password)}
                                    placeholder="Password" id="password"
                                    name="password"/>
                                <svg
                                    ref={passwordRef}
                                    onClick={() => setVisiblePassword(passwordRef)}
                                    className="auth-form__input-view"
                                    width="20" height="20" viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_56_1131)">
                                        <path
                                            d="M19.8488 9.60712C19.8037 9.5572 18.7235 8.37036 16.9804 7.17325C14.6498 5.57262 12.236 4.72656 10 4.72656C7.76402 4.72656 5.35023 5.57262 3.0196 7.17325C1.27655 8.37036 0.196348 9.5572 0.151231 9.60712C-0.0504103 9.83024 -0.0504103 10.1698 0.151231 10.3929C0.196348 10.4428 1.27658 11.6296 3.01964 12.8267C5.35023 14.4274 7.76406 15.2735 10 15.2735C12.236 15.2735 14.6498 14.4274 16.9804 12.8268C18.7235 11.6297 19.8037 10.4428 19.8488 10.3929C20.0504 10.1698 20.0504 9.83024 19.8488 9.60712ZM5.683 13.025C4.10472 12.2675 2.59952 11.1574 1.40764 10.0002C2.09807 9.33106 3.66093 7.94829 5.68386 6.97375C5.08105 7.83094 4.72659 8.87481 4.72659 10C4.72655 11.1247 5.08065 12.1681 5.683 13.025ZM10 14.1016C7.74031 14.1016 5.89843 12.2633 5.89843 10C5.89843 7.7384 7.73839 5.89844 10 5.89844C12.2599 5.89844 14.1016 7.73696 14.1016 10C14.1016 12.2616 12.2616 14.1016 10 14.1016ZM14.3162 13.0263C14.919 12.1691 15.2735 11.1252 15.2735 10C15.2735 8.87536 14.9194 7.83196 14.3171 6.975C15.8954 7.73251 17.4006 8.84259 18.5924 9.99978C17.902 10.669 16.3392 12.0517 14.3162 13.0263Z"
                                            fill="#D8D8D8"/>
                                        <path
                                            d="M10 7.07031C8.38457 7.07031 7.07031 8.38457 7.07031 10C7.07031 11.6154 8.38457 12.9297 10 12.9297C11.6154 12.9297 12.9297 11.6154 12.9297 10C12.9297 8.38457 11.6155 7.07031 10 7.07031ZM10 11.7578C9.03075 11.7578 8.24219 10.9693 8.24219 10C8.24219 9.03075 9.03075 8.24219 10 8.24219C10.9693 8.24219 11.7578 9.03075 11.7578 10C11.7578 10.9693 10.9693 11.7578 10 11.7578Z"
                                            fill="#D8D8D8"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_56_1131">
                                            <rect width="20" height="20"
                                                  fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <ErrorMessage name="password"
                                          className="error-label"
                                          component="p"/>
                        </div>
                    </div>
                    <div className="auth-form__link-wrap auth-form__two-links">
                        <NavLink to="/sign-up/"
                                 className="smm-link auth-form__link">
                            Create account
                        </NavLink>
                        <NavLink to="/forgot-password/"
                                 className="smm-link auth-form__link">
                            Forgot password?
                        </NavLink>
                    </div>
                    <button type="submit"
                            className={getButtonSubmitClass("smm-btn__green auth-form__btn", requestLoginLoading)}
                            disabled={requestLoginLoading}>
                        <span>Sign in</span>
                        <svg width="29" height="30" viewBox="0 0 29 30"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.0806 20.3033L18.8971 11.4868L18.7367 18.0783C18.7283 18.4235 19.0012 18.7101 19.3463 18.7185C19.6911 18.7269 19.9779 18.4542 19.9865 18.1088L20.1695 10.5808C20.1692 10.2469 20.0395 9.9329 19.8033 9.69669C19.5673 9.46069 19.2531 9.33076 18.9042 9.33098L11.3912 9.51394C11.2241 9.51792 11.0741 9.58708 10.9645 9.69668C10.8478 9.81336 10.7771 9.97599 10.7815 10.1541C10.7899 10.4993 11.0763 10.7719 11.4214 10.7635L18.0272 10.589L9.19671 19.4194C8.95276 19.6634 8.95276 20.0593 9.19671 20.3033C9.44066 20.5472 9.83664 20.5472 10.0806 20.3033Z"
                                fill="white"/>
                        </svg>
                    </button>
                </Form>
            )}
        </Formik>
    )
}

export default SignInForm;