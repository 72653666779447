import {NavLink} from "react-router-dom";
import {Fragment} from "react";


const Breadcrumbs = ({paths}) => {

    function renderLinks() {

        return paths.map((item, index) => {

            if (index === (paths.length - 1)) {
                return (
                    <Fragment key={index}>
                        <NavLink to={item.path}
                                 className="breadcrumbs__link-active">
                            {item.name}
                        </NavLink>
                    </Fragment>
                )
            } else {
                return (
                    <Fragment key={index}>
                        <NavLink to={item.path}
                                 className="breadcrumbs__link">
                            {item.name}
                        </NavLink>
                        <span>/</span>
                    </Fragment>
                )
            }
        });
    }

    return (
        <div className="breadcrumbs">
            <div className="breadcrumbs__wrap">
                {renderLinks()}
            </div>
        </div>
    )
}

export default Breadcrumbs;