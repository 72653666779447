import SmmPanel from "./smmPanel/SmmPanel";
import SmmAvailableServices from "./smmAvailableServices/SmmAvailableServices";
import BenefitCheapServices from "./benefitCheapServices/BenefitCheapServices";
import SmmQuickResponse from "./smmQuickResponse/SmmQuickResponse";
import SmmStats from "./smmStats/SmmStats";
import FaqSmmFirst from "./faqSmmFirst/FaqSmmFirst";
import WhyChoose from "./whyChoose/WhyChoose";
import CheapestReseller from "./cheapestReseller/CheapestReseller";
import SmmReviews from "./smmReviews/SmmReviews";
import SmmMainServices from "./smmMainServices/SmmMainServices";
import FaqSmmSecond from "./faqSmmSecond/FaqSmmSecond";


const HomePage = () => (
        <>
            <SmmPanel/>
            <SmmAvailableServices/>
            <BenefitCheapServices/>
            <SmmQuickResponse/>
            <SmmStats/>
            <FaqSmmFirst/>
            <WhyChoose/>
            <CheapestReseller/>
            <SmmReviews/>
            <SmmMainServices/>
            <FaqSmmSecond/>
        </>
)

export default HomePage;