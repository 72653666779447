import AuthWrapper from "../../../parts/authWrapper/AuthWrapper";
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";
import {useEffect, useState} from "react";
import {Navigate, useParams} from "react-router-dom";
import Preloader from "../../../parts/preloader/Preloader";
import useAuthServices from "../../../../services/AuthServices";
import {useDispatch} from "react-redux";
import {
    setNotification
} from "../../../parts/notification/NotificationSlice";


const ResetPasswordPage = () => {

    const {uidb64, token} = useParams();
    const [loading, setLoading] = useState('loading');
    const {passwordResetCheckToken, passwordResetChange} = useAuthServices();
    const dispatch = useDispatch()

    useEffect(() => {
        passwordResetCheckToken(uidb64, token)
            .then(onSuccessChecked)
            .catch(onErrorChecked);
    }, [])

    const onSuccessChecked = (response) => {
        setLoading("loaded");
        dispatch(setNotification(response.data.notification))
    }

    const onErrorChecked = (error) => {
        setLoading("error");
        dispatch(setNotification(error.response.data.notification));
    }

    const renderLayout = () => {
        return (
            <AuthWrapper>
                <h2 className="auth-form__title">Change Password</h2>
                <ResetPasswordForm sendData={passwordResetChange}
                                   uidb64={uidb64} token={token}/>
            </AuthWrapper>
        )
    }

    const checkLoading = () => {
        switch (loading) {
            case "loading":
                return <Preloader/>;
            case "loaded":
                return renderLayout();
            default:
                return <Navigate to="/"/>
        }
    }

    return checkLoading();
}

export default ResetPasswordPage;